import { message } from "antd"
// import { ethers } from "ethers"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useNetwork, useSigner } from "wagmi"
import { Contract } from "zksync-web3"

import AddOwners from "../components/AddOwners"
import ApproveTransaction from "../components/ApproveTransaction"
import ConnectWallet from "../components/ConnectWallet"
import DaoInfo from "../components/DaoInfo"
import DiscordRegister from "../components/DiscordRegister"
import GnosisSafeList from "../components/GnosisSafe/GnosisSafeList"
import GnosisSuccess from "../components/GnosisSuccess"
import OnboardingError from "../components/OnboardingError"
import OnboardingOverview from "../components/OnboardingOverview"
import { web3 } from "../constant/web3"
// import { useSafeSdk } from "../hooks"
import managerContractJson from "../smartContract/zksync/Manager.json"
import {
    addDaoInfo,
    // addSafeAddress,
    // getAllSafeFromAddress,
    registerDao,
} from "../store/actions/dao-action"
import { setManagerContract } from "../utils/zkSyncContractCalls"
import Layout from "../views/Layout"

export default function Onboarding() {
    const [currentStep, setCurrentStep] = useState(1)
    const [hasMultiSignWallet, setHasMultiSignWallet] = useState(false)
    const [newSafeSetup, setNewSafeSetup] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(-1)
    // const [deploying, setDeploying] = useState(false)
    // const [signer, setSigner] = useState()
    const { data: signer } = useSigner()
    const { chain } = useNetwork()
    // const [safeAddress, setSafeAddress] = useState()
    const [rep3Setup, setrep3Setup] = useState(false)
    const [isPayout, setIsPayout] = useState(false)
    // const { safeFactory } = useSafeSdk(signer, safeAddress)
    // const daoSetupInfo = useSelector((x) => x.dao.newSafeSetup)
    // const threshold = useSelector((x) => x.dao.newSafeSetup.threshold)
    const [register, setRegister] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const address = useSelector((x) => x.auth.address)
    const jwt = useSelector((x) => x.auth.jwt)
    const owners = useSelector((x) => x.dao.newSafeSetup.owners)
    const accounts = useSelector((x) => x.dao.dao_list)
    const isAdmin = useSelector((x) => x.auth.isAdmin)
    // eslint-disable-next-line no-unused-vars
    const [searchParams, _setSearchParams] = useSearchParams()
    const guildId = searchParams.get("guild_id")
    const discordUserId = searchParams.get("discord_user_id")
    const steps = [
        "connectWallet",
        "onboardingSteps",
        "gnosisSafeList",
        "addOwners",
        "approveTransaction",
        "daoInfo",
        "pocpSignup",
    ]

    const preventGoingBack = useCallback(() => {
        window.history.pushState(null, document.title, window.location.href)
        window.addEventListener("popstate", () => {
            if (address && jwt) {
                if (accounts.length === 0) {
                    window.history.pushState(
                        null,
                        document.title,
                        window.location.href
                    )
                } else {
                    window.history.pushState(null, document.title, "/dashboard")
                    window.location.reload("true")
                }
            }
        })
    }, [address, jwt])

    useEffect(() => {
        preventGoingBack()
    }, [preventGoingBack])

    useEffect(() => {
        if (address) {
            setCurrentStep(1)
        } else {
            setCurrentStep(0)
        }
    }, [address])

    // const deploySafe = useCallback(
    //     async (owners) => {
    //         if (!safeFactory) return
    //         setDeploying(true)
    //         const safeAccountConfig = {
    //             owners,
    //             threshold: threshold === 0 ? threshold + 1 : threshold,
    //         }
    //         let safe
    //         try {
    //             safe = await safeFactory.deploySafe(safeAccountConfig)
    //             message.success("A safe is successfully created !")
    //             setDeploying(false)
    //             // callBackFnSuccess()
    //             const newSafeAddress = ethers.utils.getAddress(
    //                 safe.getAddress()
    //             )
    //             setSafeAddress(newSafeAddress)
    //             dispatch(addSafeAddress(newSafeAddress))
    //         } catch (error) {
    //             message.error(error.message)
    //             setDeploying(false)
    //             throw error
    //         }
    //     },
    //     [address, dispatch, navigate, safeFactory, threshold, signer]
    // )

    const onDaoDeploy = async () => {
        if (newSafeSetup) {
            setCurrentStep(4)
        } else {
            try {
                setRegister(true)
                await dispatch(
                    registerDao(() => {
                        navigate("/dashboard")
                        setRegister(false)
                    }, chain?.id)
                )
            } catch (error) {
                console.error("error", error)
                setRegister(false)
            }
        }
    }

    const increaseStep = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep((currentStep) => currentStep + 1)
        } else {
            setCurrentStep(steps.length - 1)
        }
    }

    // const increaseStepFromGnosisList = async () => {
    //     if (newSafeSetup) {
    //         await deployNewSafe(() => {})
    //         increaseStep()
    //     } else {
    //         increaseStep()
    //     }
    // }

    // const deployNewSafe = async () => {
    //     // try {
    //     try {
    //         const owner = []
    //         owners.forEach((item) => {
    //             owner.push(item.address)
    //         })
    //         await deploySafe(owner)
    //         setCurrentStep(6)
    //     } catch (error) {
    //         console.error("error.... on deploying", error)
    //     }
    // }

    // const fetchAllSafe = useCallback(async () => {
    //     try {
    //         dispatch(getAllSafeFromAddress())
    //         return 1
    //     } catch (error) {
    //         return 0
    //     }
    // }, [address, dispatch])

    const decreaseStep = () => {
        if (hasMultiSignWallet && steps[currentStep] === "daoInfo") {
            setCurrentStep(steps.indexOf("addOwners"))
        } else if (currentStep > 0) setCurrentStep(currentStep - 1)
    }

    const afterConnectWalletCallback = async (setAuth) => {
        setAuth(false)
        increaseStep()
    }

    const increaseFromOverview = async () => {
        try {
            const contract = new Contract(
                web3.zkSync.manager,
                managerContractJson.abi,
                signer
            )
            setManagerContract(contract)
            setCurrentStep(5)
        } catch (error) {
            console.error("error is", error)
            message.error("error on creating instance")
        }
    }

    const increaseFromGnosisSetup = () => {
        setNewSafeSetup(false)
        setHasMultiSignWallet(false)
        setrep3Setup(true)
        setCurrentStep(3)
    }

    const increaseFromDaoInfo = async (name, logoUrl) => {
        dispatch(addDaoInfo(name, logoUrl))
        if (isPayout) {
            // const res = await fetchAllSafe()
            // if (res) {
            //     setCurrentStep(2)
            // } else {
            //     message.error("Error Fetching Safe List !")
            // }
        } else {
            setrep3Setup(true)
            setCurrentStep(3)
        }
    }

    const backFromAddOwner = () => {
        console.log("back from add owner", rep3Setup, hasMultiSignWallet)
        if (rep3Setup && !hasMultiSignWallet) {
            setCurrentStep(5)
        }
    }

    const getComponentFromName = (name, hasMultiSignWallet = false) => {
        switch (name) {
            case "connectWallet": {
                return (
                    <ConnectWallet
                        increaseStep={increaseStep}
                        owners={owners}
                        afterConnectWalletCallback={afterConnectWalletCallback}
                        isAdmin={isAdmin}
                    />
                )
            }
            case "onboardingSteps": {
                return (
                    <OnboardingOverview
                        increaseStep={async () => await increaseFromOverview()}
                        isPayout={isPayout}
                        setPayout={() => setIsPayout(!isPayout)}
                    />
                )
            }
            case "gnosisSafeList": {
                return (
                    <GnosisSafeList
                        setStep={(x) => setCurrentStep(x)}
                        // increaseStep={increaseStepFromGnosisList}
                        setHasMultiSignWallet={setHasMultiSignWallet}
                        guildId={guildId}
                        discordUserId={discordUserId}
                        rep3Setup={rep3Setup}
                        setrep3Setup={(x) => setrep3Setup(x)}
                        onBack={() => setCurrentStep(5)}
                        setNewSafe={(x) => setNewSafeSetup(x)}
                    />
                )
            }
            case "registerDiscord": {
                return <DiscordRegister increaseStep={increaseStep} />
            }
            case "addOwners": {
                return (
                    <AddOwners
                        hasMultiSignWallet={hasMultiSignWallet}
                        increaseStep={async () => await onDaoDeploy()}
                        setStep={(x) => setCurrentStep(steps.indexOf(x))}
                        rep3Setup={rep3Setup}
                        safeOwners={owners}
                        onBack={backFromAddOwner}
                        registerLoader={register}
                    />
                )
            }
            case "approveTransaction":
                return (
                    <ApproveTransaction
                        // increaseStep={async () => await deployNewSafe()}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        hasMultiSignWallet={hasMultiSignWallet}
                        // deploying={deploying}
                        decreaseStep={decreaseStep}
                    />
                )
            case "daoInfo":
                return (
                    <DaoInfo
                        hasMultiSignWallet={hasMultiSignWallet}
                        increaseStep={(name, image) =>
                            increaseFromDaoInfo(name, image)
                        }
                        // deploying={deploying}
                        onBack={() => setCurrentStep(1)}
                        isPayout={isPayout}
                    />
                )
            case "pocpSignup":
                return (
                    <GnosisSuccess
                        hasMultiSignWallet={hasMultiSignWallet}
                        increaseStep={increaseFromGnosisSetup}
                        // deploying={deploying}
                        decreaseStep={decreaseStep}
                    />
                )
            case "identifierExpired":
                return <OnboardingError text="This page has expired" />
            default: {
                return (
                    <ConnectWallet
                        increaseStep={increaseStep}
                        owners={owners}
                        afterConnectWalletCallback={afterConnectWalletCallback}
                    />
                )
            }
        }
    }

    return (
        <div>
            <Layout
                signer={signer}
                decreaseStep={decreaseStep}
                currentStep={currentStep}
                steps={steps}
            >
                {getComponentFromName(steps[currentStep], hasMultiSignWallet)}
            </Layout>
        </div>
    )
}
