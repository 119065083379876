import { assets } from "../constant/assets"

const iconType = [
    assets.icons.add_photo_alternate,
    assets.icons.airport_shuttle,
    assets.icons.archive,
    assets.icons.av_timer,
    assets.icons.beenhere,
    assets.icons.book,
    assets.icons.brightness_1,
    assets.icons.calendar_month,
    assets.icons.center_focus_strong,
    assets.icons.chair,
    assets.icons.checklist,
    assets.icons.cottage,
    assets.icons.description,
    assets.icons.desk,
    assets.icons.door_sliding,
    assets.icons.electric_bolt,
    assets.icons.favorite,
    assets.icons.grade,
    assets.icons.history,
    assets.icons.hourglass_bottom,
    assets.icons.light_mode,
    assets.icons.light,
    assets.icons.lightbulb,
    assets.icons.link,
    assets.icons.list_alt,
    assets.icons.new_releases,
    assets.icons.text_snippet1,
    assets.icons.text_snippet,
    assets.icons.title1,
    assets.icons.title,
]

export default iconType
