import "./style.scss"

import { LoadingOutlined } from "@ant-design/icons"
import { message, Spin } from "antd"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useAccount } from "wagmi"

import {
    contributionBadgeClaim,
    customBadgeClaim,
    getContributionAsContributorApproved,
    getPastContributions,
    getPastContributionsPolled,
    rejectContributionVoucher,
    removeClaimedContributionVoucher,
    removeClaimedCustomBadge,
    setClaimLoading,
    setPastContributionsSyncing,
} from "../../../../store/actions/contibutor-action"
import {
    getAllMembershipBadges,
    getInitializedStatus,
} from "../../../../utils/POCPServiceSdk"
import ContributionCardV2 from "../ContributionCard"

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 24,
        }}
        spin
    />
)
export default function ApprovedVoucherClub({ voucher, isFirst }) {
    const [contributionsWithCheckbox, setContributionWithCheckbox] = useState(
        []
    )
    const { address } = useAccount()
    const proxyContract = useSelector((x) => x.dao.daoProxyAddress)
    const claimLoading = useSelector((x) => x.contributor.claimLoading)
    const dispatch = useDispatch()
    const [rejectLoader, setRejectLoader] = useState(false)
    const [customBadge, setCustomBadge] = useState([])

    useEffect(() => {
        let contributions = []
        let customBadge = []
        let voucher_uuid = null
        for (const key in voucher) {
            if (voucher?.[key]?.contributions?.length) {
                contributions = voucher[key]?.contributions
                voucher_uuid = key
            } else {
                customBadge = voucher[key]
                console.log("vouchers", customBadge)
                setCustomBadge([
                    { ...customBadge, isChecked: true, voucher_uuid: key },
                ])
            }
        }
        const mappedContributions = contributions.map((ele) => ({
            ...ele,
            isChecked: true,
            voucher_uuid,
        }))
        setContributionWithCheckbox([...mappedContributions])
    }, [voucher])

    const updateCheckbox = (e, index) => {
        const tempContributions = contributionsWithCheckbox.map((ele, i) => {
            if (i === index) {
                return {
                    ...ele,
                    isChecked: !ele.isChecked,
                }
            }
            return ele
        })
        setContributionWithCheckbox([...tempContributions])
    }

    let totalSelected = 0
    contributionsWithCheckbox.forEach((ele) => {
        if (ele.isChecked) {
            totalSelected = totalSelected + 1
        }
    })

    customBadge?.forEach((ele) => {
        if (ele.isChecked) {
            totalSelected = totalSelected + 1
        }
    })

    const claimBadge = async () => {
        if (totalSelected) {
            dispatch(setClaimLoading(true))
            try {
                const memberTokenId = await getAllMembershipBadges(
                    address,
                    proxyContract,
                    false
                )
                console.log("Membership", memberTokenId)
                const membership_token_id =
                    memberTokenId.data.membershipNFTs[0].tokenID
                if (customBadge.length > 0) {
                    await dispatch(
                        customBadgeClaim(
                            customBadge[0]?.voucher?.signed_voucher,
                            membership_token_id,
                            () => {
                                setCustomBadge([])
                                dispatch(removeClaimedCustomBadge())
                                dispatch(setClaimLoading(false))
                                message.success("Claimed Badge Successfully")
                                dispatch(setPastContributionsSyncing(true))
                                dispatch(getPastContributionsPolled())
                                console.log("done...")
                            },
                            customBadge
                        )
                    )
                } else {
                    await dispatch(
                        contributionBadgeClaim(
                            contributionsWithCheckbox[0]?.uuid,
                            membership_token_id,
                            () => {
                                dispatch(
                                    removeClaimedContributionVoucher(
                                        contributionsWithCheckbox
                                    )
                                )
                                dispatch(setClaimLoading(false))
                                message.success("Claimed Badge Successfully")
                                dispatch(setPastContributionsSyncing(true))
                                dispatch(getPastContributionsPolled())
                            },
                            contributionsWithCheckbox
                        )
                    )
                }
            } catch (err) {
                dispatch(setClaimLoading(false))
            }
        }
    }

    const rejectVoucher = async () => {
        if (totalSelected) {
            setRejectLoader(true)
            const memberTokenId = await getAllMembershipBadges(
                address,
                proxyContract,
                false
            )

            const membership_token_id =
                memberTokenId.data.membershipNFTs[0].tokenID
            await dispatch(
                rejectContributionVoucher(
                    membership_token_id,
                    contributionsWithCheckbox?.length
                        ? contributionsWithCheckbox[0]?.voucher_uuid
                        : customBadge[0]?.voucher_uuid,
                    contributionsWithCheckbox?.length
                        ? contributionsWithCheckbox
                        : customBadge
                )
            )
            dispatch(getContributionAsContributorApproved())
            dispatch(getPastContributions())
            setRejectLoader(false)
        }
    }

    // contributor changes here

    return (
        <div className="approved-voucher-club-container">
            {(isFirst && contributionsWithCheckbox?.length) ||
            (isFirst && customBadge) ? (
                <div className="approved-voucher-first-header">
                    <div>
                        {contributionsWithCheckbox.length || customBadge.length}{" "}
                        badges available for claim
                    </div>
                    <div className="approved-header-action-btns">
                        <button
                            className="reject-outline-btn"
                            onClick={rejectVoucher}
                            disabled={!totalSelected}
                        >
                            Reject all
                            {rejectLoader && <Spin indicator={antIcon} />}
                        </button>
                        <button
                            onClick={claimBadge}
                            disabled={!totalSelected || !getInitializedStatus()}
                        >
                            Claim Badge • {totalSelected}
                            {claimLoading && <Spin indicator={antIcon} />}
                        </button>
                    </div>
                </div>
            ) : (
                ""
            )}
            {contributionsWithCheckbox?.map((contribution, index) => (
                <ContributionCardV2
                    key={index}
                    index={index}
                    isMinimum={true}
                    item={contribution}
                    isLast={index === contributionsWithCheckbox?.length - 1}
                    contributionType="approved"
                    isFirst={isFirst}
                    updateCheckbox={updateCheckbox}
                />
            ))}
            {customBadge?.map((contribution, index) => (
                <ContributionCardV2
                    key={index}
                    index={index}
                    isMinimum={true}
                    item={contribution}
                    isLast={index === customBadge?.length - 1}
                    contributionType="approved"
                    isFirst={isFirst}
                    updateCheckbox={updateCheckbox}
                    isContributionBadge={false}
                />
            ))}
        </div>
    )
}
