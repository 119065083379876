import add_photo_alternate from "../assets/customIcons/add_photo_alternate.svg"
import airport_shuttle from "../assets/customIcons/airport_shuttle.svg"
import archive from "../assets/customIcons/archive.svg"
import av_timer from "../assets/customIcons/av_timer.svg"
import beenhere from "../assets/customIcons/beenhere.svg"
import book from "../assets/customIcons/book.svg"
import brightness_1 from "../assets/customIcons/brightness_1.svg"
import calendar_month from "../assets/customIcons/calendar_month.svg"
import center_focus_strong from "../assets/customIcons/center_focus_strong.svg"
import chair from "../assets/customIcons/chair.svg"
import checklist from "../assets/customIcons/checklist.svg"
import cottage from "../assets/customIcons/cottage.svg"
import description from "../assets/customIcons/description.svg"
import desk from "../assets/customIcons/desk.svg"
import door_sliding from "../assets/customIcons/door_sliding.svg"
import electric_bolt from "../assets/customIcons/electric_bolt.svg"
import favorite from "../assets/customIcons/favorite.svg"
import grade from "../assets/customIcons/grade.svg"
import history from "../assets/customIcons/history.svg"
import hourglass_bottom from "../assets/customIcons/hourglass_bottom.svg"
import light from "../assets/customIcons/light.svg"
import light_mode from "../assets/customIcons/light_mode.svg"
import lightbulb from "../assets/customIcons/lightbulb.svg"
import link from "../assets/customIcons/link.svg"
import list_alt from "../assets/customIcons/list_alt.svg"
import new_releases from "../assets/customIcons/new_releases.svg"
import text_snippet from "../assets/customIcons/text_snippet.svg"
import text_snippet1 from "../assets/customIcons/text_snippet-1.svg"
import title from "../assets/customIcons/title.svg"
import title1 from "../assets/customIcons/title-1.svg"
import deleteForever from "../assets/delete_forever.svg"
import backArrowBlack from "../assets/Icons/arrow_back_black_24dp.svg"
import arrowDown from "../assets/Icons/arrow_drop_down.svg"
import arrowUp from "../assets/Icons/arrow_drop_up.svg"
import arrowForwardPurple from "../assets/Icons/arrow_forward_purple.svg"
import backHand from "../assets/Icons/back_hand.png"
import cashIcon from "../assets/Icons/cash_icon.svg"
import checkIcon from "../assets/Icons/check.svg"
import checkBox from "../assets/Icons/check_box.svg"
import checkBoxActive from "../assets/Icons/check_box_active.svg"
import checkoBoxOutline from "../assets/Icons/check_box_outline_blank.svg"
import chevronDownWhite from "../assets/Icons/chevron_down_white.svg"
import chevronRightPurple from "../assets/Icons/chevron_right_purple.svg"
import chevronRightWhite from "../assets/Icons/chevron_right_white.svg"
import chevronUpWhite from "../assets/Icons/chevron_up_white.svg"
import clockIcon from "../assets/Icons/clock_icon.svg"
import crossBlack from "../assets/Icons/cross.svg"
import crossWhite from "../assets/Icons/cross_white.svg"
import deletePurple from "../assets/Icons/delete.svg"
import downBlack from "../assets/Icons/down_black.svg"
import downWhite from "../assets/Icons/down_white.svg"
import editBlack from "../assets/Icons/edit.svg"
import errorIcon from "../assets/Icons/error_icon.svg"
import redWarningIcon from "../assets/Icons/error_icon_red.svg"
import faceIcon from "../assets/Icons/face_icon.svg"
import feedIcon from "../assets/Icons/feed.svg"
import feedIconBlack from "../assets/Icons/feed_black.svg"
import feedbackCardIcon from "../assets/Icons/feedback_card_icon.svg"
import infoIcon from "../assets/Icons/info.svg"
import minusWhite from "../assets/Icons/minus_white.svg"
import plusBlack from "../assets/Icons/plus.svg"
import plusGray from "../assets/Icons/plus_gray_light.svg"
import sentWhite from "../assets/Icons/sent_arrow.svg"
import startWhite from "../assets/Icons/star_white.svg"
import tickPurple from "../assets/Icons/tick.svg"
import toggleIcon from "../assets/Icons/toggle_icon.svg"
import tuneIcon from "../assets/Icons/tune.svg"
import upBlack from "../assets/Icons/up_black.svg"
import uploadFile from "../assets/Icons/upload_file.svg"
import rep3BadgeLogo from "../assets/rep3BadgeLogo.svg"

export const assets = {
    icons: {
        chevronRightPurple,
        toggleIcon,
        plusBlack,
        downWhite,
        plusGray,
        faceIcon,
        feedIcon,
        minusWhite,
        errorIcon,
        cashIcon,
        downBlack,
        feedbackCardIcon,
        clockIcon,
        feedIconBlack,
        upBlack,
        infoIcon,
        checkoBoxOutline,
        backArrowBlack,
        checkBoxActive,
        tuneIcon,
        checkIcon,
        redWarningIcon,
        chevronRightWhite,
        crossBlack,
        rep3BadgeLogo,
        chevronDownWhite,
        chevronUpWhite,
        sentWhite,
        crossWhite,
        editBlack,
        uploadFile,
        tickPurple,
        deletePurple,
        add_photo_alternate,
        airport_shuttle,
        archive,
        av_timer,
        beenhere,
        book,
        brightness_1,
        calendar_month,
        center_focus_strong,
        chair,
        checklist,
        cottage,
        description,
        desk,
        door_sliding,
        electric_bolt,
        favorite,
        grade,
        history,
        hourglass_bottom,
        light_mode,
        light,
        lightbulb,
        link,
        list_alt,
        new_releases,
        text_snippet1,
        text_snippet,
        title1,
        title,
        arrowForwardPurple,
        startWhite,
        checkBox,
        arrowUp,
        arrowDown,
        backHand,
        deleteForever,
    },
}
