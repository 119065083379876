import "./style.scss"

import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import plus_black from "../../../../assets/Icons/plus_black.svg"
import {
    discordBotSetup,
    discordMembershipSetup,
    discordOauth,
} from "../../../../store/actions/auth-action"
import {
    removeBotOnEdit,
    removeDiscordBot,
    setDiscordMembershipRole,
} from "../../../../store/actions/membership-action"
import { getApproverForDao } from "../../../../utils/POCPServiceSdk"
import NextButton from "../../../NextButton"
import MembershipRoleSelection from "../MembershipRoleSelection"

export default function CreationStep1({
    increaseStep,
    membershipBadges,
    setMembershipBadges,
    totalSteps,
    customBadgeName,
    isEditing,
    type = "custom-badge",
    decreaseStep,
}) {
    const membership = useSelector((x) => x.membership.membershipBadges)
    const discordRoles = useSelector((x) => x.membership.discordRoles)
    const onDiscordBotSetup = useSelector((x) => x.membership.onDiscordBotSetup)
    // const editTrack = useSelector((x) => x.membership.setEditDiscordSetupModal)
    const updateBadgeName = (badgeIndex, newValue) => {
        const copyOfBadges = [...membershipBadges]
        copyOfBadges[badgeIndex] = {
            ...copyOfBadges[badgeIndex],
            name: newValue,
        }
        setMembershipBadges(copyOfBadges)
    }

    const addBadge = () => {
        setMembershipBadges((membershipBadges) => [
            ...membershipBadges,
            {
                name: "",
                image: "",
            },
        ])
    }

    const [updatedMembership, setUpdateMenbership] = useState(membership)

    const dispatch = useDispatch()

    if (onDiscordBotSetup) {
        dispatch(discordBotSetup(false))
    }

    const getStepInfo = () => {
        switch (type) {
            case "custom-badge":
                return {
                    title: `${customBadgeName} ${
                        isEditing ? "update" : "creation"
                    }`,
                    heading: "Badge Names",
                }
            case "auto-claim":
                return {
                    title: "Enable Autoclaim",
                    heading: "Select role mapping",
                }
            default:
                return {
                    title: "Enable Autoclaim",
                    heading: "Select role mapping",
                }
        }
    }

    const serverDetail = useSelector((x) => x.auth.selected_server)
    const currentDao = useSelector((x) => x.dao.currentDao)
    const contractAddress = useSelector((x) => x.dao.daoProxyAddress)

    const currentServer = serverDetail || currentDao

    const getContentRightCustomBadge = () => (
        <div>
            {membershipBadges.map((badge, index) => (
                <input
                    type="text"
                    value={badge.name}
                    onChange={(e) => updateBadgeName(index, e.target.value)}
                    placeholder="Level Name"
                    key={index}
                />
            ))}
            <div className="add-btn-wrapper" onClick={addBadge}>
                <div>Add another Level</div>
                <div className="add-btn">
                    <img src={plus_black} alt="add" />
                </div>
            </div>
        </div>
    )

    const updateMembershipWithRole = (label, value, index) => {
        const copyOfBadges = [...updatedMembership]
        copyOfBadges[index] = {
            ...updatedMembership[index],
            discord_role_id: value,
            discord_role_name: label,
        }
        setUpdateMenbership(copyOfBadges)
    }

    const getContentRightAutoClaim = () => (
        <div className="auto-claim-content">
            <div className="server-info">
                <div>
                    <div
                        style={{
                            height: 48,
                            width: 48,
                            background: "gray",
                            borderRadius: 48,
                        }}
                    >
                        {currentServer?.icon && (
                            <img
                                src={currentServer?.icon}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: 48,
                                }}
                            />
                        )}
                    </div>
                    <div className="server-name">
                        {currentServer.name?.slice(0, 6)}
                        {currentServer.name.length > 6 && "..."} .{" "}
                    </div>
                    <div className="server-admin">Owner</div>
                </div>
                <div
                    onClick={async () => {
                        const approverDao = await getApproverForDao(
                            contractAddress
                        )
                        if (
                            approverDao.data.daos[0].approver.includes(
                                "0xc8B4a82A1cc76C62BeFB883906ec12E2F1b4feB6".toLowerCase()
                            )
                        ) {
                            dispatch(discordOauth(true))
                            dispatch(
                                discordMembershipSetup(true, currentDao?.uuid)
                            )
                            // window.location.replace(links.discord_oauth)
                            window.location.replace(
                                `https://discord.com/api/oauth2/authorize?client_id=${
                                    process.env.NODE_ENV === "production"
                                        ? "976409482533961769"
                                        : "976409482533961769"
                                }&redirect_uri=${encodeURIComponent(
                                    `${window.location.origin}/discord/fallback`
                                )}&response_type=code&scope=identify%20guilds%20guilds.members.read`
                            )
                            dispatch(removeBotOnEdit())
                        } else {
                            dispatch(removeDiscordBot(currentServer?.id))
                        }
                        decreaseStep()
                    }}
                    className="change-server-btn"
                >
                    <div>Change</div>
                </div>
            </div>
            {membership.map((x, i) => (
                <div className="server-item" key={i}>
                    <MembershipRoleSelection
                        isLast={membership.length - 1 === i}
                        item={x}
                        roles={discordRoles}
                        updateWithRole={(label, value) =>
                            updateMembershipWithRole(label, value, i)
                        }
                        membership={updatedMembership}
                    />
                </div>
            ))}
        </div>
    )

    const getRightContent = () => {
        switch (type) {
            case "custom-badge":
                return getContentRightCustomBadge()
            case "auto-claim":
                return getContentRightAutoClaim()
        }
    }

    const checkIsDisabled = () => {
        let isDisabled = true
        updatedMembership.forEach((badge) => {
            if (badge.discord_role_id) {
                isDisabled = false
            }
        })
        return isDisabled
    }
    const checkBagdeDisabled = () => {
        let isDisabled = true
        membershipBadges.forEach((badge) => {
            if (badge.name) {
                isDisabled = false
            }
        })
        return isDisabled
    }
    console.log(checkBagdeDisabled())
    return (
        <div className="membership-step1">
            <div className="membership-heading">{getStepInfo().title}</div>
            <div className="membership-content">
                <div className="membership-left">
                    <div className="left-bold">{getStepInfo().heading}</div>
                    <div className="left-greyed">Step 2 of {totalSteps}</div>
                </div>
                <div className="membership-right">
                    {getRightContent()}
                    <div className="next-btn-wrapper">
                        <NextButton
                            text="Review Setup"
                            isDisabled={
                                type !== "custom-badge"
                                    ? checkIsDisabled()
                                    : checkBagdeDisabled()
                            }
                            nextButtonCallback={() => {
                                dispatch(
                                    setDiscordMembershipRole(updatedMembership)
                                )
                                increaseStep()
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
