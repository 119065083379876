import "./style.scss"

import React from "react"

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        console.error("error", error)
        return { hasError: true }
    }

    // componentDidCatch(error, errorInfo) {
    //     // You can also log the error to an error reporting service
    //       logErrorToMyService(error, errorInfo);
    // }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className="error-boundary-container">
                    <h1 className="error-boundary-text">
                        Oops! something went wrong.
                    </h1>
                    <p className="error-boundary-text">
                        Please refresh the page and try again
                    </p>
                </div>
            )
        }

        return this.props.children
    }
}
