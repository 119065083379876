import "./style.scss"

import React, { useState } from "react"
import { useDispatch } from "react-redux"

import appreciationIcon from "../../../../assets/Icons/appreciationIcon.svg"
import arrow_forward from "../../../../assets/Icons/arrow_forward.svg"
import contributionIcon from "../../../../assets/Icons/contributionIcon.svg"
import cross from "../../../../assets/Icons/cross.svg"
import membershipIcon from "../../../../assets/Icons/membershipIcon.svg"
import { assets } from "../../../../constant/assets"
import {
    createMembershipBadges,
    getAllMembershipBadgesList,
    setDiscordAutoClaimPopup,
    updateMembershipBadges,
} from "../../../../store/actions/membership-action"
import MembershipCreationStep1 from "../MembershipCreationStep1"
import MembershipCreationStep2 from "../MembershipCreationStep2"

export default function Modal({ closeModal, membershipBadges, isEditing }) {
    const [membershipStep, setMembershipStep] = useState(1)
    // const [badgeType, setBadgeType] = useState(null)
    const [localMembershipBadges, setLocalMembershipBadges] = useState(
        isEditing
            ? membershipBadges
            : [
                  {
                      name: "",
                      image_url: "",
                      description: "",
                  },
              ]
    )
    const [membershipLoading, setMembershipLoading] = useState(false)

    const dispatch = useDispatch()

    const increaseStep = async () => {
        if (membershipStep >= 2) {
            setMembershipLoading(true)
            const formData = new FormData()
            localMembershipBadges.forEach((badge, index) => {
                if (isEditing) {
                    const oldMembership = membershipBadges[index]
                    if (badge.file || oldMembership.name !== badge.name) {
                        formData.append("file", badge.file)
                        formData.append("name", badge.name)
                        formData.append("level", badge.level || index + 1)
                        formData.append("category", 1)
                        formData.append("description", badge.description)
                        formData.append("image", badge.image_url)
                    }
                } else {
                    formData.append("file", badge.file)
                    formData.append("name", badge.name)
                    formData.append("level", badge.level || index + 1)
                    formData.append("category", 1)
                    formData.append("description", badge.description)
                }
            })
            if (isEditing) {
                const membershipBadgesUpdated = localMembershipBadges.map(
                    (badge, index) => {
                        const oldMembership = membershipBadges[index]

                        return {
                            ...badge,
                            update: badge.file
                                ? true
                                : oldMembership.name !== badge.name,
                        }
                    }
                )
                await dispatch(
                    updateMembershipBadges(formData, membershipBadgesUpdated)
                )
            } else {
                await dispatch(
                    createMembershipBadges(formData, localMembershipBadges)
                )
                await dispatch(getAllMembershipBadgesList())
                dispatch(setDiscordAutoClaimPopup(true))
            }
            setMembershipLoading(false)
            closeModal()
            setMembershipStep(1)
            // here add the confirmqation code
        } else {
            setMembershipStep((membershipStep) => membershipStep + 1)
        }
    }

    const SelectBadgeType = () => {
        return (
            <>
                <div className="heading">Select badge type</div>
                <div
                    className="badge-type-row"
                    onClick={() => {
                        // setBadgeType("membership")
                        increaseStep()
                    }}
                >
                    <div className="badge-type">
                        <div className="badge-icon">
                            <img src={membershipIcon} alt="membership" />
                        </div>
                        <div>
                            <div className="badge-type-heading">
                                Membership badges
                            </div>
                            <div className="badge-type-content">
                                Membership badges are upgradable entity that
                                will be upgraded with community
                            </div>
                        </div>
                        <div>
                            <div className="badge-type-btn">
                                <img src={arrow_forward} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="badge-type-row"
                    onClick={() => {
                        // setBadgeType("contribution")
                        increaseStep()
                    }}
                >
                    <div className="badge-type">
                        <div className="badge-icon">
                            <img src={contributionIcon} alt="membership" />
                        </div>
                        <div>
                            <div className="badge-type-heading">
                                Contribution badges
                            </div>
                            <div className="badge-type-content">
                                Contribution badges are individual badges that
                                work as a proof of contribution, they can either
                                be requested or awarded to community.
                            </div>
                        </div>
                        <div>
                            <div className="badge-type-btn">
                                <img src={arrow_forward} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="badge-type-row"
                    onClick={() => {
                        // setBadgeType("appreciation")
                        increaseStep()
                    }}
                >
                    <div className="badge-type">
                        <div className="badge-icon">
                            <img src={appreciationIcon} alt="membership" />
                        </div>
                        <div>
                            <div className="badge-type-heading">
                                Appreciation badges
                            </div>
                            <div className="badge-type-content">
                                Appreciation badges are individual badges that
                                can be given to members for outlier performance.
                            </div>
                        </div>
                        <div>
                            <div className="badge-type-btn">
                                <img src={arrow_forward} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const renderOnStep = () => {
        switch (membershipStep) {
            case 0:
                return <SelectBadgeType />
            case 1:
                return (
                    <MembershipCreationStep1
                        increaseStep={increaseStep}
                        membershipBadges={localMembershipBadges}
                        setMembershipBadges={setLocalMembershipBadges}
                    />
                )
            case 2:
                return (
                    <MembershipCreationStep2
                        increaseStep={increaseStep}
                        membershipBadges={localMembershipBadges}
                        setMembershipBadges={setLocalMembershipBadges}
                        membershipLoading={membershipLoading}
                    />
                )
            default:
                return <SelectBadgeType />
        }
    }

    return (
        <div className="badges-modal-container">
            <div
                className={`modal-backdrop ${
                    membershipStep !== 0 && "main-at-bottom"
                }`}
                onClick={closeModal}
            >
                <div
                    className={`modal-main ${
                        membershipStep !== 0 && "main-expanded"
                    }`}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="close-btn">
                        {membershipStep > 1 ? (
                            <img
                                onClick={() =>
                                    setMembershipStep(membershipStep - 1)
                                }
                                src={assets.icons.backArrowBlack}
                                alt=""
                            />
                        ) : (
                            <img onClick={closeModal} src={cross} alt="" />
                        )}
                    </div>
                    {renderOnStep()}
                </div>
            </div>
        </div>
    )
}
