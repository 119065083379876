import * as Sentry from "@sentry/react"
import { message } from "antd"

import routes from "../../constant/routes"
import apiClient from "../../utils/api_client"
import { chainType } from "../../utils/chainType"
import {
    getAllMembershipBadges,
    // getMembershipBadgeFromTxHash,
} from "../../utils/POCPServiceSdk"
import {
    claimVoucher,
    createMembershipVoucher,
} from "../../utils/zkSyncContractCalls"
import { authActions } from "../reducers/auth-slice"
import { customBadgeAction } from "../reducers/custom-badge-slice"
import { membershipAction } from "../reducers/membership-slice"
import { toastAction } from "../reducers/toast-slice"

export const getAllMembershipBadgesList = () => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const uuid = getState().dao.currentDao?.uuid
        try {
            const res = await apiClient.get(
                `${process.env.REACT_APP_DAO_TOOL_URL}${routes.membership.getMembershipBadgesList}?dao_uuid=${uuid}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            const discordRoles = []
            console.log("membership count", res?.data?.data?.memberships)
            res?.data?.data?.memberships.forEach((x) => {
                if (x.discord_role_name) discordRoles.push(x.discord_role_name)
            })
            dispatch(
                membershipAction.setMembershipBadges({
                    discord_roles: discordRoles.length,
                    membershipBadges: res?.data?.data?.memberships,
                })
            )
        } catch (err) {
            console.error(err)
        }
    }
}

export const getAllMembershipVouchers = (claimer) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const uuid = getState().dao.currentDao?.uuid
        try {
            const res = await apiClient.get(
                `${process.env.REACT_APP_DAO_TOOL_URL}/membership/get_vouchers?dao_uuid=${uuid}&addr=${claimer}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )

            return res?.data?.data
        } catch (err) {
            console.error(err)
            return 0
        }
    }
}

export const getAllDaoMembers = () => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt

        const uuid = getState().dao.currentDao?.uuid
        try {
            const res = await apiClient.get(
                `${
                    process.env.REACT_APP_DAO_TOOL_URL
                }${"/dao/contributors"}?dao_uuid=${uuid}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            dispatch(
                membershipAction.setDaoMembers({
                    allDaoMembers: res?.data?.data,
                })
            )
        } catch (err) {
            console.error(err)
        }
    }
}
export const setAllDaoMember = (allDaoMembers) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt

        const uuid = getState().dao.currentDao?.uuid
        try {
            await apiClient.get(
                `${
                    process.env.REACT_APP_DAO_TOOL_URL
                }${"/dao/contributors"}?dao_uuid=${uuid}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            dispatch(
                membershipAction.setDaoMembers({
                    allDaoMembers,
                })
            )
        } catch (err) {
            console.error(err)
        }
    }
}
export const getMembershipVoucher = () => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const uuid = getState().dao.currentDao?.uuid
        try {
            const res = await apiClient.get(
                `${process.env.REACT_APP_DAO_TOOL_URL}${routes.membership.getMembershipVoucher}?dao_uuid=${uuid}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            dispatch(
                membershipAction.setMembershipVoucher({
                    membershipVoucher: res?.data?.data,
                })
            )
            if (res.data?.data?.length) {
                return 1
            }
            return 0
        } catch (err) {
            console.error(err)
            return 0
        }
    }
}

// const poll = async function (fn, fnCondition, ms) {
//     let result = await fn()
//     while (fnCondition(result)) {
//         await wait(ms)
//         result = await fn()
//     }
//     return result
// }

// const wait = function (ms = 1000) {
//     return new Promise((resolve) => {
//         setTimeout(resolve, ms)
//     })
// }

export const claimMembershipVoucher = (membershipVoucherInfo, chainId) => {
    return async (dispatch, getState) => {
        const proxyContract = getState().dao.daoProxyAddress
        const claimerAddress = getState().auth.address
        const currentDao = getState().dao.currentDao
        try {
            await claimVoucher(
                proxyContract,
                membershipVoucherInfo?.signed_voucher,
                membershipVoucherInfo?.voucher_address_index,
                currentDao?.uuid,
                async (x) => {
                    dispatch(
                        updateTxHash(
                            x,
                            "claim",
                            null,
                            chainId,
                            claimerAddress,
                            membershipVoucherInfo
                        )
                    )
                },
                async (x) => {
                    // console.log
                    // const fetchNFT = () =>
                    //     getMembershipBadgeFromTxHash(
                    //         x.transactionHash,
                    //         currentDao?.uuid
                    //     )
                    // const validate = (result) =>
                    //     !result?.data?.membershipNFTs?.length

                    dispatch(setTxHashFetched(true))

                    dispatch(
                        setClaimMembershipLoading({
                            status: true,
                            membership_uuid: membershipVoucherInfo.uuid,
                        })
                    )

                    dispatch(setShowMetamaskSignText(false))

                    dispatch(
                        setClaimTakingTime({
                            claimText:
                                "Takes around 20sec, please don’t leave the page",
                            claimColor: "#fff",
                        })
                    )

                    setTimeout(() => {
                        dispatch(
                            setClaimTakingTime({
                                claimText:
                                    "Oops, polygon seems to be a bit slow at the moment, please give it a bit longer",
                                claimColor: "#FFB22E",
                            })
                        )
                    }, 30000)

                    setTimeout(() => {
                        dispatch(
                            setClaimTakingTime({
                                claimText:
                                    "It’s taking longer than expected. Why not have some water in the mean time? 🚰 ",
                                claimColor: "#FF6262",
                            })
                        )
                    }, 90000)

                    // const response = await poll(fetchNFT, validate, 3000)
                    // const membershipNFT = response?.data?.membershipNFTs?.[0]
                    const nftData = x.events.filter(
                        (ele) => ele.event === "Claim"
                    )
                    console.log(
                        "args are",
                        nftData,
                        nftData?.[0]?.args,
                        nftData?.[0]?.args?.tokenId?.toNumber()
                    )

                    dispatch(
                        setMembershipBadgeClaimed({
                            ...membershipVoucherInfo,
                            contractAddress: x?.to,
                            tokenID: nftData?.[0]?.args?.tokenId?.toNumber(),
                            membership_txn_hash: x.transactionHash,
                        })
                    )
                    dispatch(setShowMembershipBadgeClaimed(true))
                    // dispatch(
                    //     getAllMembershipBadgesForAddress(
                    //         claimerAddress,
                    //         proxyContract
                    //     )
                    // )
                    dispatch(
                        membershipAction.setMembershipUnclaimed({
                            unclaimedMembership: [],
                        })
                    )
                    dispatch(
                        setClaimMembershipLoading({
                            status: false,
                            membership_uuid: null,
                        })
                    )
                    dispatch(setDisableClaimBtn(false))
                }
            )
            return 1
        } catch (err) {
            console.error("claiming signing error", err)
            Sentry.captureMessage("Membership claiming error", {
                extra: {
                    proxyContract,
                    membershipVoucherInfo,
                    currentDao,
                    claimerAddress,
                },
                level: "error",
            })
            dispatch(
                setClaimMembershipLoading({
                    status: false,
                    membership_uuid: null,
                })
            )
            dispatch(setClaimTakingTime(false))
            dispatch(setDisableClaimBtn(false))
            return 0
        }
    }
}

export const getAllMembershipBadgesForAddress = () => {
    return async (dispatch, getState) => {
        const proxyContract = getState().dao.daoProxyAddress
        const address = getState().auth.address
        const currentDao = getState().dao.currentDao
        const membershipVoucher = getState().membership.membershipVoucher
        const membership = getState().membership.membershipBadges

        try {
            const membershipBadges = await getAllMembershipBadges(
                address,
                proxyContract,
                currentDao?.uuid
            )

            dispatch(
                membershipAction.setMembershipBadgesForAddress({
                    membershipBadgesForAddress:
                        membershipBadges?.data?.membershipNFTs,
                })
            )
            if (membershipBadges?.data?.membershipNFTs.length > 0) {
                dispatch(
                    membershipAction.setMembershipUnclaimed({
                        unclaimedMembership: [],
                    })
                )
            } else {
                const vouchers = []

                membershipVoucher.forEach((x) => {
                    membership.forEach((badges) => {
                        if (x.membership_uuid === badges.uuid) {
                            vouchers.push({ ...x, ...badges })
                        }
                    })
                })

                dispatch(
                    membershipAction.setMembershipUnclaimed({
                        unclaimedMembership: vouchers,
                    })
                )
            }
        } catch (err) {
            console.error(err)
        }
    }
}

const setMembershipBadgeClaimed = (membershipBadgeClaimed) => {
    return async (dispatch) => {
        try {
            dispatch(
                membershipAction.setMembershipBadgeClaimed({
                    membershipBadgeClaimed,
                })
            )
        } catch (err) {
            console.error(err)
        }
    }
}

const setClaimMembershipLoading = (loadingStatus) => {
    return async (dispatch) => {
        try {
            dispatch(
                membershipAction.setClaimMembershipLoading({
                    claimMembershipLoading: loadingStatus,
                })
            )
        } catch (err) {
            console.error(err)
        }
    }
}

const setClaimTakingTime = (claimTakingTimeInfo) => {
    return async (dispatch) => {
        try {
            dispatch(
                membershipAction.setClaimTakingTime({
                    claimTakingTime: claimTakingTimeInfo,
                })
            )
        } catch (err) {
            console.error(err)
        }
    }
}

export const setDisableClaimBtn = (status) => {
    return async (dispatch) => {
        try {
            dispatch(
                membershipAction.setDisableClaimBtn({
                    disableClaimBtn: status,
                })
            )
        } catch (err) {
            console.error(err)
        }
    }
}

export const setShowMetamaskSignText = (status) => {
    return async (dispatch) => {
        try {
            dispatch(
                membershipAction.setShowMetamaskSignText({
                    showMetamaskSignText: status,
                })
            )
        } catch (err) {
            console.error(err)
        }
    }
}

const setTxHashFetched = (status) => {
    return async (dispatch) => {
        try {
            dispatch(
                membershipAction.setTxHashFetched({
                    txHashFetched: status,
                })
            )
        } catch (err) {
            console.error(err)
        }
    }
}

export const createMembershipBadges = (formData, memberships) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const uuid = getState().dao.currentDao?.uuid

        try {
            const response = await apiClient.post(
                // `${process.env.REACT_APP_ARWEAVE_SERVER}${routes.arweave.membership}`,
                `https://test-staging.api.drepute.xyz/arweave_server/membership`,
                formData
            )
            if (response?.data?.success) {
                const arweaveHashArray = response.data?.data

                const membershipsUpdated = memberships.map(
                    (membership, index) => {
                        return {
                            ...membership,
                            image_url: arweaveHashArray[index]?.media,
                            metadata_hash: arweaveHashArray[index]?.metadata,
                            is_html: false,
                            category: 1,
                            level: index + 1,
                            discord_role_name:
                                membership[index]?.discord_role_name,
                            discord_role_id: membership[index]?.discord_role_id,
                        }
                    }
                )

                console.log("here", membershipsUpdated)

                const res = await apiClient.post(
                    `${process.env.REACT_APP_DAO_TOOL_URL}${routes.membership.createMembershipBadges}`,
                    {
                        dao_uuid: uuid,
                        memberships: membershipsUpdated,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                    }
                )

                if (res.data.success) {
                    dispatch(
                        toastAction.setToastInfo({
                            toastInfo: {
                                content: "Created successfully",
                                toastType: "success",
                            },
                        })
                    )
                    dispatch(toastAction.setShowToast({ status: true }))
                    dispatch(getAllMembershipBadgesList())
                    dispatch(setShowMembershipCreateModal(false))
                }
            }
        } catch (err) {
            console.error(err)
            Sentry.captureMessage("Membership creating error", {
                extra: {
                    daoUuid: uuid,
                    formData,
                    memberships,
                },
                level: "error",
            })
            dispatch(showDefaultToastError())
        }
    }
}

export const updateMembershipWithRoles = (membershipsUpdated) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const uuid = getState().dao.currentDao?.uuid
        await apiClient.put(
            `${process.env.REACT_APP_DAO_TOOL_URL}${routes.membership.createMembershipBadges}`,
            {
                dao_uuid: uuid,
                memberships: membershipsUpdated,
            },
            {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            }
        )
    }
}

export const updateMembershipBadges = (formData, memberships) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const uuid = getState().dao.currentDao?.uuid

        try {
            const response = await apiClient.put(
                // `${process.env.REACT_APP_ARWEAVE_SERVER}${routes.arweave.membership}`,
                `https://test-staging.api.drepute.xyz/arweave_server/membership`,
                formData
            )
            if (response?.data?.success) {
                const arweaveHashArray = response.data?.data
                let arweaveArrayIndex = 0

                const membershipsUpdated = memberships.map(
                    (membership, index) => {
                        const image_url = membership?.update
                            ? arweaveHashArray[arweaveArrayIndex].media
                            : membership?.image_url

                        const metadata_hash = membership?.update
                            ? arweaveHashArray[arweaveArrayIndex].metadata
                            : membership?.metadata_hash

                        if (membership?.update) {
                            arweaveArrayIndex = arweaveArrayIndex + 1
                        }

                        const returnObj = {
                            description: membership.description,
                            image_url,
                            metadata_hash,
                            level: membership?.level || index + 1,
                            category: 1,
                            is_video: membership?.is_video,
                            is_html: false,
                            name: membership.name,
                            discord_role_id: membership.discord_role_id,
                            discord_role_name: membership.discord_role_name,
                        }

                        if (membership.uuid) {
                            returnObj.uuid = membership.uuid
                        }

                        return returnObj
                    }
                )

                let res
                const oldMemberships = membershipsUpdated.filter(
                    (ele) => ele.uuid
                )
                const newMemberships = membershipsUpdated.filter(
                    (ele) => !ele.uuid
                )
                console.log("membership", oldMemberships, newMemberships)
                res = await apiClient.put(
                    `${process.env.REACT_APP_DAO_TOOL_URL}${routes.membership.createMembershipBadges}`,
                    {
                        dao_uuid: uuid,
                        memberships: oldMemberships,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                    }
                )
                if (newMemberships?.length) {
                    res = await apiClient.post(
                        `${process.env.REACT_APP_DAO_TOOL_URL}${routes.membership.createMembershipBadges}`,
                        {
                            dao_uuid: uuid,
                            memberships: newMemberships,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${jwt}`,
                            },
                        }
                    )
                }

                if (res.data.success) {
                    dispatch(
                        toastAction.setToastInfo({
                            toastInfo: {
                                content: "Updated successfully",
                                toastType: "success",
                            },
                        })
                    )
                    dispatch(toastAction.setShowToast({ status: true }))
                    dispatch(getAllMembershipBadgesList())
                    dispatch(setShowMembershipCreateModal(false))
                }
            }
        } catch (err) {
            console.error(err)
            Sentry.captureMessage("Membership updating error", {
                extra: {
                    daoUuid: uuid,
                    formData,
                    memberships,
                },
                level: "error",
            })
            dispatch(showDefaultToastError())
        }
    }
}

const showDefaultToastError = () => {
    return (dispatch) => {
        dispatch(
            toastAction.setToastInfo({
                toastInfo: {
                    content: "Something went wrong please try again",
                    toastType: "error",
                },
            })
        )
        dispatch(toastAction.setShowToast({ status: true }))
    }
}

export const setSelectedMember = (member) => {
    return (dispatch) => {
        dispatch(
            membershipAction.setSelectedMember({
                selectedMember: member,
            })
        )
    }
}

export const getSelectedMemberContributions = (memberAddress) => {
    return async (dispatch, getState) => {
        const uuid = getState().dao.currentDao?.uuid
        const jwt = getState().auth.jwt

        try {
            const res = await apiClient.get(
                `${process.env.REACT_APP_DAO_TOOL_URL}${routes.contribution.pastContributions}?dao_uuid=${uuid}&addr=${memberAddress}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            const res2 = await apiClient.get(
                `${process.env.REACT_APP_DAO_TOOL_URL}${routes.customBadge.pastBadges}?dao_uuid=${uuid}&addr=${memberAddress}&is_contributor_view=0`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            console.log("", res2.data.data.customized_badge)
            if (res?.data?.success) {
                const contributions = res.data.data.contributions
                const approvedContributions = contributions?.filter(
                    (ele) => ele.status === "APPROVED"
                )
                dispatch(
                    membershipAction.setSelectedMemberPastContributions({
                        selectedMemberPastContributions: approvedContributions,
                    })
                )
                dispatch(
                    customBadgeAction.setSelectedMemberCustomBadge({
                        customBadges: res2.data.data.customized_badge,
                    })
                )
            }
        } catch (err) {
            console.error(err)
            dispatch(
                membershipAction.setSelectedMemberPastContributions({
                    selectedMemberPastContributions: [],
                })
            )
            dispatch(
                customBadgeAction.setSelectedMemberCustomBadge({
                    customBadges: [],
                })
            )
        }
    }
}

export const setSelectedNav = (selectedNav) => {
    return async (dispatch) => {
        dispatch(
            membershipAction.setSelectedNav({
                selectedNav,
            })
        )
    }
}

export const setShowMembershipChangeModal = (showMembershipChangeModal) => {
    return async (dispatch) => {
        dispatch(
            membershipAction.setShowMembershipChangeModal({
                showMembershipChangeModal,
            })
        )
    }
}

export const setShowMembershipCreateModal = (showMembershipCreateModal) => {
    return (dispatch) => {
        dispatch(
            membershipAction.setShowMembershipCreateModal({
                showMembershipCreateModal,
            })
        )
    }
}

export const setShowMembershipMintingModal = (showMembershipMintingModal) => {
    return (dispatch) => {
        dispatch(
            membershipAction.setShowMembershipMintingModal({
                showMembershipMintingModal,
            })
        )
    }
}

export const mintBadges = (
    selectedMembershipBadge,
    addresses,
    chainId,
    signer
) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        // const uuid = getState().dao.currentDao?.uuid
        const proxyContract = getState().dao.currentDao?.contract_address
        const mapArr = addresses.reduce(
            (acc, curr) => {
                if (acc[acc.length - 1].length < 24) {
                    acc[acc.length - 1].push(curr)
                } else {
                    acc.push([curr])
                }
                return acc
            },
            [[]]
        )

        try {
            const mapArrWithSignedVoucher = await Promise.all(
                mapArr.map(async (ele) => {
                    // eslint-disable-next-line no-useless-catch
                    try {
                        const signedObject = await createMembershipVoucher(
                            proxyContract,
                            // "0xfb06e23d1cb18933840d447e4079b704529455f3",
                            [selectedMembershipBadge?.level],
                            [selectedMembershipBadge?.category],
                            [],
                            ele,
                            `${selectedMembershipBadge?.metadata_hash},`,
                            chainId,
                            signer
                        )
                        if (signedObject) {
                            return {
                                addresses: [...ele],
                                signed_voucher: signedObject,
                            }
                        } else {
                            return false
                        }
                    } catch (error) {
                        console.log("error", error)
                        throw error
                    }
                })
            )

            const arrWithApiCall = mapArrWithSignedVoucher.map((ele) =>
                apiClient.post(
                    `${process.env.REACT_APP_DAO_TOOL_URL}${routes.membership.createMembershipVoucher}`,
                    {
                        addresses: ele.addresses,
                        signed_voucher: ele.signed_voucher,
                        membership_uuid: selectedMembershipBadge?.uuid,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                    }
                )
            )

            const res = await Promise.all(arrWithApiCall)
            if (res.data?.success) {
                dispatch(
                    toastAction.setToastInfo({
                        toastInfo: {
                            content: "Created successfully",
                            toastType: "success",
                        },
                    })
                )
                dispatch(toastAction.setShowToast({ status: true }))
            }
        } catch (err) {}
    }
}

export const createDynamicMembershipVoucher = (
    selectedMembershipBadge,
    addresses,
    metadataHash
) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const uuid = getState().dao.currentDao?.uuid
        const proxyContract = getState().dao.daoProxyAddress

        const endArray = []

        addresses.forEach((x, i) => {
            if (i + 1 < addresses.length) {
                endArray.push(i + 1)
            }
        })

        try {
            const signedObject = await createMembershipVoucher(
                proxyContract,

                new Array(addresses.length).fill(
                    selectedMembershipBadge?.level
                ),

                new Array(addresses.length).fill(
                    selectedMembershipBadge?.category
                ),

                endArray,
                addresses,
                `${metadataHash.toString()},`,
                uuid
            )
            const res = apiClient.post(
                `${process.env.REACT_APP_DAO_TOOL_URL}${routes.membership.createMembershipVoucher}`,
                {
                    addresses,
                    signed_voucher: signedObject,
                    membership_uuid: selectedMembershipBadge?.uuid,
                },
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            if (res.data?.success) {
                dispatch(
                    toastAction.setToastInfo({
                        toastInfo: {
                            content: "Created successfully",
                            toastType: "success",
                        },
                    })
                )
                dispatch(toastAction.setShowToast({ status: true }))
            }
        } catch (error) {
            message.error("error on action!")
        }
    }
}

export const updateTxHash = (
    txnHash,
    type,
    prevHash,
    chainId,
    claimerAddress,
    membershipInfo
) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt

        const currentDao = getState().dao.currentDao
        const data = {
            txn_hash: txnHash,
            chain_id: chainId === 4 ? "80001" : "137",
            prev_txn_hash: type === "claim" ? null : prevHash,
            type,
            dao_uuid: currentDao?.uuid,
            chain: "zksync",
            user_addr: claimerAddress,
            membership_uuid: membershipInfo?.uuid,
        }
        try {
            apiClient.post(
                `${
                    process.env.REACT_APP_DAO_TOOL_URL
                }${"/membership/membership_txn"}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            return 1
        } catch (error) {
            console.error("err", error)
            return 0
        }
    }
}

export const getAddressVouchers = (address) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        try {
            const res = await apiClient.get(
                `${process.env.REACT_APP_DAO_TOOL_URL}${routes.membership.unclaimedVouchers}?addr=${address}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            if (res.data?.success) {
                if (res.data?.data) {
                    dispatch(
                        membershipAction.setUnclaimedMembershipVouchersForAddress(
                            {
                                unclaimedMembershipVouchersForAddress:
                                    res.data.data,
                            }
                        )
                    )
                    if (res.data.data?.length) return 1
                    return 0
                }
            }
            return 0
        } catch (error) {
            console.error("err", error)
            return 0
        }
    }
}

export const setMembershipBadgesForAddress = (membershipBadgesForAddress) => {
    return async (dispatch) => {
        dispatch(
            membershipAction.setMembershipBadgesForAddress({
                membershipBadgesForAddress,
            })
        )
    }
}

export const setDiscordAutoClaimPopup = (status) => {
    return async (dispatch) => {
        dispatch(
            membershipAction.setShowDiscordPopup({
                status,
            })
        )
    }
}

export const setDiscordServers = (servers) => {
    return (dispatch) => {
        dispatch(membershipAction.setDiscordServers({ servers }))
    }
}

export const setDiscordAutoClaimSetupModal = (status) => {
    return async (dispatch) => {
        dispatch(
            membershipAction.setShowDiscordSetupModal({
                status,
            })
        )
    }
}

export const setDiscordAutoClaimSetupEditModal = (status, edit) => {
    return async (dispatch) => {
        dispatch(
            membershipAction.setShowDiscordSetupEditModal({
                status,
                edit,
            })
        )
    }
}

export const setShowMembershipBadgeClaimed = (showMembershipBadgeClaimed) => {
    return (dispatch) => {
        dispatch(
            membershipAction.setShowMembershipBadgeClaimed({
                showMembershipBadgeClaimed,
            })
        )
    }
}

export const discordDisconnectModalShow = (status) => {
    return (dispatch) => {
        dispatch(
            authActions.discordDisconnectModal({
                status,
            })
        )
    }
}
export const fetchContributorClaimedDataBackend = (chainId) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const address = getState().auth.address
        const lastSelected = getState().auth.lastSelection
        try {
            const res = await apiClient.get(
                `${process.env.REACT_APP_DAO_TOOL_URL}${routes.dao.getDaoMembership}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )

            if (res.data.data.length > 0) {
                const dao_details = []

                res.data.data.forEach((x) => {
                    if (
                        chainType(x.dao_details.chain_id) === chainType(chainId)
                    ) {
                        dao_details.push(x)
                    }
                })

                let selectionIndex = 0

                if (lastSelected) {
                    const isLastSelection = lastSelected.filter(
                        (x) => x.address === address
                    )
                    dao_details.forEach((item, index) => {
                        if (
                            isLastSelection.length > 0 &&
                            item?.dao_details?.uuid ===
                                isLastSelection[0]?.dao_uuid
                        ) {
                            selectionIndex = index
                        }
                    })
                }
                console.log(
                    "current membership in action",
                    dao_details[selectionIndex].memberships[0]
                )

                dispatch(
                    membershipAction.setClaimedDataFromBE({
                        contributorClaimedDataBackend: {
                            membership: {
                                ...dao_details[selectionIndex]
                                    .membership_txns[0],
                                ...dao_details[selectionIndex].memberships[0],
                            },
                            recentlyUpdate:
                                dao_details[selectionIndex].membership_update,
                        },
                    })
                )
            } else {
                dispatch(
                    membershipAction.setClaimedDataFromBE({
                        membership: null,
                        recentlyUpdate: null,
                    })
                )
                return 0
            }
        } catch (error) {
            dispatch(
                membershipAction.setClaimedDataFromBE({
                    membership: null,
                    recentlyUpdate: null,
                })
            )
        }
    }
}

export const getDiscordRoleOnGuild = (guildId) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt

        try {
            const res = await apiClient.get(
                `${process.env.REACT_APP_DISCORD_URL}/guildRoles/${guildId}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )

            const roles = []
            res?.data.data.forEach((element) => {
                roles.push({ value: element.id, label: element.name })
            })
            console.log("membership roles", roles)
            dispatch(
                membershipAction.setDiscordRoles({
                    roles,
                })
            )
            return res?.data.data
        } catch (error) {
            console.error("err", error)
            return 0
        }
    }
}

export const setDiscordRole = (guildId) => {
    return (dispatch) => {
        dispatch(membershipAction.set_guild_id({ guildId }))
    }
}

export const setDiscordMembershipRole = (updatedMembership) => {
    return (dispatch) => {
        dispatch(membershipAction.set_membership_role({ updatedMembership }))
    }
}
export const removeDiscordBot = (guild_id) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        try {
            await apiClient.post(
                `${process.env.REACT_APP_DISCORD_URL}/removeBot`,
                {
                    guild_id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
        } catch (error) {
            console.log("error", error.toString())
        }
    }
}

export const pauseDiscordBot = () => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const currentDao = getState().dao.currentDao
        try {
            await apiClient.post(
                `${process.env.REACT_APP_DAO_TOOL_URL}/discord/pause`,
                {
                    dao_uuid: currentDao?.uuid,
                },
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
        } catch (error) {
            console.log("error", error.toString())
        }
    }
}

export const removeBotOnEdit = () => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const currentDao = getState().dao.currentDao
        console.log("guild", currentDao)
        try {
            await apiClient.post(
                `${process.env.REACT_APP_DAO_TOOL_URL}/discord/remove`,
                {
                    dao_uuid: currentDao?.uuid,
                },
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
        } catch (error) {
            console.log("error", error.toString())
        }
    }
}
