import axios from "axios"

export const uploadApproveMetaDataUpload = async (approveContri, jwt) => {
    const data = {
        contribution_meta_array: approveContri,
        callbackApi: `${process.env.REACT_APP_DAO_TOOL_URL}`,
    }
    try {
        const res = await axios.post(
            `https://test-staging.api.drepute.xyz/ipfs_server/upload`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            }
        )
        if (res) {
            return 1
        }
    } catch (error) {}
}

const buildQuery = (cid) => {
    const cid_and_array = []

    cid?.forEach((x) => {
        cid_and_array.push(`cid=${x}`.toString())
    })
    let queryString = cid_and_array.toString()
    queryString = queryString.replace(/,/g, "&")
    return queryString
}

export const getIpfsUrl = async (jwt, dao_uuid, cid) => {
    try {
        const query = buildQuery(cid)
        const res = await axios.get(
            `${process.env.REACT_APP_DAO_TOOL_URL}/contrib/get/ipfs?${query}&dao_uuid=${dao_uuid}`,
            {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            }
        )
        if (res.data?.data?.contributions?.length > 0) {
            const cid = []
            const url = []
            let status = true
            res.data?.data?.contributions?.forEach((item) => {
                if (item?.ipfs_url === null) {
                    status = false
                } else {
                    cid.push(item?.id.toString())
                    url.push(`https://ipfs.infura.io/ipfs/${item?.ipfs_url}`)
                }
            })

            return { cid, url, status }
        } else {
            return { cid, url: [], status: false }
        }
    } catch (error) {
        // //console.log('error', error.toString())
        return { cid, url: [], status: false }
    }
}
