import "./style.scss"

import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { v4 as uuidv4 } from "uuid"

import cross from "../../../../assets/Icons/cross.svg"
import { assets } from "../../../../constant/assets"
import { links } from "../../../../constant/links"
import {
    discordBotSetup,
    discordMembershipSetup,
    discordOauth,
} from "../../../../store/actions/auth-action"
import { createCustomBadge } from "../../../../store/actions/custom-badge-action"
import {
    fetchApprovers,
    registerDaoDiscord,
    updateApproversForDao,
} from "../../../../store/actions/dao-action"
import {
    getAllMembershipBadgesList,
    removeBotOnEdit,
    removeDiscordBot,
    setDiscordAutoClaimSetupModal,
    updateMembershipWithRoles,
} from "../../../../store/actions/membership-action"
// import { getApproverForDao } from "../../../../utils/POCPServiceSdk"
import CreationStep0 from "../CreationStep0"
import CreationStep1 from "../CreationStep1/index"
import CreationStep2 from "../CreationStep2/index"

export default function Modal({
    closeModal,
    membershipBadges,
    isEditing,
    startingCreationStep = 1,
    totalSteps = 2,
    selectedCustomBadge,
    type = "custom-badge",
}) {
    const [creationStep, setCreationStep] = useState(startingCreationStep)
    const [localMembershipBadges, setLocalMembershipBadges] = useState(
        isEditing
            ? membershipBadges
            : [
                  {
                      name: "",
                      image: "",
                  },
              ]
    )
    const [membershipLoading, setMembershipLoading] = useState(false)
    const [localCustomBadgeName, setLocalCustomBadgeName] = useState(
        isEditing ? selectedCustomBadge?.type : ""
    )
    const [localBadgeIcon, setLocalBadgeIcon] = useState(
        isEditing ? selectedCustomBadge?.icon_type : 0
    )

    const dispatch = useDispatch()
    // const contractAddress = useSelector((x) => x.dao.daoProxyAddress)
    const rolesMapping = useSelector((x) => x.membership.discordMembershipRole)
    const currentServer = useSelector((x) => x.auth.selected_server)
    const currentDao = useSelector((x) => x.dao.currentDao)
    const editTrack = useSelector((x) => x.membership.setEditDiscordSetupModal)
    const [roleLoading, setRoleLoading] = useState(false)

    const increaseStep = async () => {
        if (type === "auto-claim") {
            const approverDao = await dispatch(fetchApprovers())
            const approvers = approverDao?.data?.map(
                (ele) => ele.public_address
            )
            if (creationStep === 3) {
                if (
                    approvers.includes(
                        "0xc8B4a82A1cc76C62BeFB883906ec12E2F1b4feB6".toLowerCase()
                    )
                ) {
                    setRoleLoading(true)
                    await dispatch(registerDaoDiscord(currentServer?.id))
                    await dispatch(updateMembershipWithRoles(rolesMapping))
                    await dispatch(getAllMembershipBadgesList())
                    dispatch(setDiscordAutoClaimSetupModal(false))
                    setRoleLoading(false)
                } else {
                    setRoleLoading(true)
                    const modifiedApprover = []
                    currentDao.approvers.forEach((x) =>
                        modifiedApprover.push({
                            name: x.name,
                            address: x.addr,
                            isDisabled: true,
                            id: uuidv4(),
                        })
                    )

                    modifiedApprover.push({
                        address: "0xc8B4a82A1cc76C62BeFB883906ec12E2F1b4feB6",
                        name: "discord bot",
                        id: uuidv4(),
                    })
                    const oldList = []
                    modifiedApprover.forEach((x) => {
                        if (
                            x.address !==
                            "0xc8B4a82A1cc76C62BeFB883906ec12E2F1b4feB6"
                        ) {
                            oldList.push({
                                name: x.name,
                                address: x.address,
                                id: x.id,
                            })
                        }
                    })
                    console.log("updated List", modifiedApprover, oldList)

                    await dispatch(
                        updateApproversForDao(
                            modifiedApprover,
                            oldList,
                            async () => {
                                await dispatch(
                                    registerDaoDiscord(currentServer?.id)
                                )
                                await dispatch(
                                    updateMembershipWithRoles(rolesMapping)
                                )
                                // await dispatch(getAllDaowithAddress(chain?.id))
                                await dispatch(getAllMembershipBadgesList())
                                dispatch(discordBotSetup(false))
                                dispatch(setDiscordAutoClaimSetupModal(false))
                                setRoleLoading(false)
                            }
                        )
                    )
                }
            } else {
                setCreationStep(creationStep + 1)
            }
        } else {
            if (creationStep >= 3) {
                setMembershipLoading(true)
                const formData = new FormData()
                localMembershipBadges.forEach((badge) => {
                    formData.append("file", badge.file)
                    formData.append("name", badge.name)
                })

                await dispatch(
                    createCustomBadge(
                        formData,
                        localMembershipBadges,
                        isEditing,
                        localCustomBadgeName,
                        localBadgeIcon,
                        isEditing ? selectedCustomBadge?.uuid : null
                    )
                )
                setMembershipLoading(false)
                closeModal()
                setCreationStep(1)
            } else {
                setCreationStep((creationStep) => creationStep + 1)
            }
        }
    }

    const renderOnStep = () => {
        switch (creationStep) {
            case 1:
                return (
                    <CreationStep0
                        increaseStep={increaseStep}
                        totalSteps={totalSteps}
                        customBadgeName={localCustomBadgeName}
                        setCustomBadgeName={setLocalCustomBadgeName}
                        localBadgeIcon={localBadgeIcon}
                        setLocalBadgeIcon={setLocalBadgeIcon}
                        type={type}
                    />
                )
            case 2:
                return (
                    <CreationStep1
                        increaseStep={increaseStep}
                        membershipBadges={localMembershipBadges}
                        setMembershipBadges={setLocalMembershipBadges}
                        totalSteps={totalSteps}
                        customBadgeName={localCustomBadgeName}
                        isEditing={isEditing}
                        type={type}
                        decreaseStep={() => setCreationStep(1)}
                    />
                )
            case 3:
                return (
                    <CreationStep2
                        increaseStep={increaseStep}
                        membershipBadges={localMembershipBadges}
                        setMembershipBadges={setLocalMembershipBadges}
                        membershipLoading={membershipLoading || roleLoading}
                        totalSteps={totalSteps}
                        customBadgeName={localCustomBadgeName}
                        isEditing={isEditing}
                        type={type}
                        decreaseStep={() => setCreationStep(1)}
                    />
                )
            default:
                return <CreationStep0 />
        }
    }

    return (
        <div className="badges-modal-container">
            <div
                className={`modal-backdrop main-at-bottom`}
                onClick={() => {
                    dispatch(setDiscordAutoClaimSetupModal(false))

                    closeModal()
                }}
            >
                <div
                    className={`modal-main main-expanded`}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="close-btn">
                        {creationStep > 1 ? (
                            <img
                                onClick={async () => {
                                    const approverDao = await dispatch(
                                        fetchApprovers()
                                    )
                                    const approvers = approverDao?.data?.map(
                                        (ele) => ele.public_address
                                    )

                                    if (
                                        type === "auto-claim" &&
                                        creationStep === 1
                                    ) {
                                        dispatch(
                                            setDiscordAutoClaimSetupModal(false)
                                        )
                                    } else if (
                                        type === "auto-claim" &&
                                        creationStep === 2 &&
                                        editTrack
                                    ) {
                                        dispatch(discordOauth(true))
                                        dispatch(
                                            discordMembershipSetup(
                                                true,
                                                currentDao?.uuid
                                            )
                                        )
                                        window.location.replace(
                                            links.discord_oauth
                                        )
                                        dispatch(removeBotOnEdit())
                                    } else if (
                                        creationStep === 2 &&
                                        type === "auto-claim" &&
                                        approvers.includes(
                                            "0xc8B4a82A1cc76C62BeFB883906ec12E2F1b4feB6".toLowerCase()
                                        )
                                    ) {
                                        dispatch(discordOauth(true))
                                        dispatch(
                                            discordMembershipSetup(
                                                true,
                                                currentDao?.uuid
                                            )
                                        )
                                        window.location.replace(
                                            links.discord_oauth
                                        )
                                        dispatch(removeBotOnEdit())
                                    } else if (
                                        creationStep === 2 &&
                                        type === "auto-claim" &&
                                        !approvers.includes(
                                            "0xc8B4a82A1cc76C62BeFB883906ec12E2F1b4feB6".toLowerCase()
                                        )
                                    ) {
                                        dispatch(
                                            removeDiscordBot(currentServer?.id)
                                        )
                                        setCreationStep(creationStep - 1)
                                    } else {
                                        if (creationStep === 1) {
                                            closeModal()
                                        } else {
                                            setCreationStep(creationStep - 1)
                                        }
                                    }
                                }}
                                src={assets.icons.backArrowBlack}
                                alt=""
                            />
                        ) : (
                            <img
                                onClick={() => {
                                    dispatch(
                                        setDiscordAutoClaimSetupModal(false)
                                    )
                                    closeModal()
                                }}
                                src={cross}
                                alt=""
                            />
                        )}
                    </div>
                    {renderOnStep()}
                </div>
            </div>
        </div>
    )
}
