import "./style.scss"

import React from "react"
import { useDispatch, useSelector } from "react-redux"

import cross from "../../../../assets/Icons/cross.svg"
import rightArrow from "../../../../assets/Icons/right_arrow_white.svg"
import { assets } from "../../../../constant/assets"
import { links } from "../../../../constant/links"
import {
    discordMembershipSetup,
    discordOauth,
} from "../../../../store/actions/auth-action"
import {
    actionOnContributionRequestModal,
    actionOnGenerateSchemaModal,
    successConfirmationModal,
} from "../../../../store/actions/contibutor-action"
import { setContractAddress } from "../../../../store/actions/dao-action"
import {
    setDiscordAutoClaimPopup,
    setShowMembershipMintingModal,
} from "../../../../store/actions/membership-action"

export default function ConfirmationBadgesModal({ type, badgeSchema }) {
    const dispatch = useDispatch()
    const dismissModal = () => {
        dispatch(setDiscordAutoClaimPopup(false))
        dispatch(successConfirmationModal(false))
    }

    const onEdit = () => {
        dispatch(successConfirmationModal(false))
        dispatch(actionOnGenerateSchemaModal(true))
    }
    const membershipBadges = useSelector((x) => x.membership.membershipBadges)

    const renderContributionBadgeChild = () => (
        <>
            <div className="modal-title-div">
                <div className="modal-tilte">{`${type} Badges`}</div>
            </div>

            <div className="badge-schema-div">
                {badgeSchema?.map((badge, index) => (
                    <div className="badge-element-div" key={index}>
                        <div className="badge-schema-field">
                            {badge.fieldName}
                        </div>
                        <div className="badge-schema-type">
                            {badge.fieldType}
                        </div>
                    </div>
                ))}
            </div>
            <div className="btn-wrapper-div">
                <div onClick={() => onEdit()}>Edit Schema</div>
                <button
                    onClick={() => {
                        dispatch(actionOnContributionRequestModal(true))
                        dispatch(successConfirmationModal(false))
                    }}
                    className="badge-confirmation-btn"
                >
                    <div>Mint Contribution badge</div>
                    <img src={rightArrow} alt="right" />
                </button>
            </div>
        </>
    )

    const onDiscordAuth = () => {
        dispatch(discordOauth(true))
        dispatch(discordMembershipSetup(true, currentDao?.uuid))
        window.location.replace(links.discord_oauth)
    }
    const currentDao = useSelector((x) => x.dao.currentDao)
    const membershipName = membershipBadges.map((x) => x.name)
    console.log("membership bdge", membershipBadges.length)
    const renderMembershipBadgeChild = () => (
        <div style={{}}>
            <div
                style={{ padding: type === "Membership" && "0rem 2rem" }}
                className="membership-badge-type-header"
            >
                <div className="memberships-header-title">
                    Membership Badge
                    <br /> {membershipName?.slice(0, 3).toString()}{" "}
                    {membershipBadges.length > 3 &&
                        `and ${membershipBadges.length - 3} more`}
                </div>
                <div className="stack-cards">
                    <img
                        className="mem-1"
                        src={membershipBadges[0]?.image_url}
                    />
                    {membershipBadges.length === 2 && (
                        <img
                            className="mem-2"
                            src={membershipBadges[1]?.image_url}
                        />
                    )}
                    {membershipBadges.length === 3 && (
                        <img
                            className="mem-3"
                            src={membershipBadges[2]?.image_url}
                        />
                    )}
                    {membershipBadges.length === 4 && (
                        <img
                            className="mem-4"
                            src={membershipBadges[3]?.image_url}
                        />
                    )}
                    {membershipBadges.length > 4 && (
                        <div className="count-div">
                            <div>+{membershipBadges.length - 3}</div>
                        </div>
                    )}
                </div>
            </div>
            <div
                onClick={async () => {
                    await dispatch(
                        setContractAddress(currentDao?.proxy_txn_hash)
                    )
                    dispatch(setDiscordAutoClaimPopup(false))
                    dispatch(setShowMembershipMintingModal(true))
                }}
                className="mint-btn-ctn"
            >
                <div>Mint Membership badge</div>
                <img src={assets.icons.arrowForwardPurple} />
            </div>
            <div className="autoclaim-banner">
                <div className="div-wrapper">
                    <img src={assets.icons.startWhite} className="star-icon" />
                    <div>
                        <div className="autoclaim-title">Enable Autoclaim</div>
                        <div className="autoclaim-description">
                            Auto claim will allow people with a certain discord
                            role to automatically claim a badge. how it works
                        </div>
                        <div
                            onClick={() => onDiscordAuth()}
                            className="autoclaim-btn"
                        >
                            <div>Connect discord and Setup Autoclaim</div>
                            <img src={assets.icons.chevronRightPurple} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    const renderChild = () => {
        switch (type) {
            case "Contribution":
                return renderContributionBadgeChild()
            case "Membership":
                return renderMembershipBadgeChild()
            default:
                return false
        }
    }

    return (
        <div className="contribution-confirm-modal-container">
            <div onClick={dismissModal} className="modal-backdrop">
                <div
                    style={{
                        padding: type === "Membership" && "2rem 0rem 0rem 0rem",
                    }}
                    className="modal-div"
                    onClick={(e) => e.stopPropagation()}
                >
                    <img
                        src={cross}
                        style={{ marginLeft: type === "Membership" && "2rem" }}
                        alt=""
                        onClick={dismissModal}
                        className="modal-cross-btn"
                    />
                    <div
                        style={{
                            padding: type === "Membership" && "0rem 2rem",
                        }}
                        className="modal-header-div"
                    >
                        <div className="modal-header-green">
                            Congratulations
                        </div>
                        <div className="modal-header">Setup complete</div>
                    </div>
                    {renderChild()}
                </div>
            </div>
        </div>
    )
}
