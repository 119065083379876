// import { assets } from "../../constant/assets"
import "./styles.scss"

import React, { useState } from "react"

import iconType from "../../utils/customBadgeIconTypes"

const IconBox = ({ getSelected, localBadgeIcon }) => {
    const [iconSelected, setIconSelected] = useState(localBadgeIcon)
    const [boxOpen, setBoxOpen] = useState(false)
    // const [active, setActive] = useState(true)

    // eslint-disable-next-line no-array-constructor
    // const arrayName = new Array(24).fill("x")

    const setSelected = (index) => {
        setIconSelected(index)
        setBoxOpen(false)
        getSelected(index)
    }

    return (
        <div className="icon-box-overlay">
            <div
                onClick={() => {
                    setBoxOpen(!boxOpen)
                }}
                style={{
                    border: "solid 1px #6852FF",
                    borderRight: 0,
                }}
                className="container"
            >
                <img
                    src={iconType[iconSelected]}
                    alt=""
                    className="icon-badge"
                />
            </div>
            {boxOpen && (
                <div className="icon-overlay">
                    {iconType.map((x, i) => (
                        <div key={i}>
                            <img
                                onClick={() => setSelected(i)}
                                style={{
                                    height: 20,
                                    width: 20,
                                    marginLeft: 20,
                                }}
                                src={x}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default IconBox
