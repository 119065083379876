import "./style.scss"

import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { setContributionDetail } from "../../store/actions/contibutor-action"
import { getDataFromApi } from "../../store/actions/custom-badge-action"
import { convertToHttps } from "../../utils/conversion"

const BadgeItem = ({ item, setupDisplay = false }) => {
    const [onHover, setHover] = useState(false)
    const [imageUri, setImageUri] = useState("")
    const dispatch = useDispatch()
    const onBadgeClick = () => {
        dispatch(setContributionDetail({ ...item }))
    }
    const contribution_detail = useSelector(
        (x) => x.contributor.contribution_detail
    )
    console.log("badges", item)
    useEffect(async () => {
        if (item?.entity?.metadata_hash && !item?.badge_type?.image) {
            const res = await dispatch(
                getDataFromApi(
                    `http://arweave.net/${item?.entity?.metadata_hash}`
                )
            )
            if (res?.data?.data?.image) {
                setImageUri(convertToHttps(res?.data?.data?.image))
            }
        }
    }, [item])
    console.log(
        item?.badge_type,
        item?.badge_type?.image ? item?.badge_type?.image : imageUri
    )
    return (
        <div
            onClick={() => onBadgeClick()}
            onMouseLeave={() => setHover(false)}
            onMouseEnter={() => setHover(true)}
            style={{
                padding: "20px",
                border: !setupDisplay && "0.5px solid #C4C4C440",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderTop: 0,
                background:
                    (onHover || contribution_detail?.uuid === item?.uuid) &&
                    !setupDisplay &&
                    "#292929",
            }}
            className="badge-item-container"
        >
            <div className="contri-badge">
                {!item?.badge_type?.is_video ? (
                    <img
                        src={
                            item?.badge_type?.image
                                ? item?.badge_type?.image
                                : imageUri
                        }
                        alt=""
                        className="contri-badge-bg"
                    />
                ) : (
                    <video autoPlay loop muted className="contri-badge-bg-vd">
                        <source
                            src={
                                item?.badge_type?.image
                                    ? item?.badge_type?.image
                                    : imageUri
                            }
                        />
                    </video>
                )}
            </div>
        </div>
    )
}

export default BadgeItem
