import { createSlice } from "@reduxjs/toolkit"

const authSlice = createSlice({
    name: "auth",
    initialState: {
        jwt: null,
        provider: null,
        web3Provider: null,
        address: null,
        signer: null,
        chainId: null,
        chain: null,
        loggedIn: false,
        role: null,
        contributorName: "",
        isAdmin: true,
        lastSelection: [],
        contributorDiscordSetup: false,
        contributorDiscordAuth: {
            status: false,
            name: "",
            dao_uuid: "",
        },
        discordEnable: false,
        discordMembershipSetup: false,
        discordBotSetup: false,
        discord_selected: false,
        discord_server: [],
        selected_server: null,
        memebershipDiscordEdit: false,
        discordDisconnectModal: false,
        dao_uuid: false,
    },
    reducers: {
        set_web3(state, action) {
            state.provider = action.payload.provider
            state.web3Provider = action.payload.web3Provider
            state.chainId = action.payload.chainId
        },
        discordDisconnectModal(state, action) {
            state.discordDisconnectModal = action.payload.status
        },
        set_address(state, action) {
            state.address = action.payload.address
        },
        set_signing(state, action) {
            // console.log("jwt store", action.payload.jwt)
            state.jwt = action.payload.jwt
            state.loggedIn = true
        },
        set_loggedIn(state, action) {
            state.loggedIn = action.payload.status
        },
        set_contri_setup(state, action) {
            state.contributorName = action.payload.name
            state.role = action.payload.role
        },
        set_admin(state, action) {
            state.isAdmin = action.payload.status
        },
        set_discord_oauth(state, action) {
            state.discordEnable = action.payload.status
        },
        set_discord_membership_setup(state, action) {
            state.discordMembershipSetup = action.payload.status
            state.dao_uuid = action.payload.dao_uuid
        },
        set_discord_membership_setup_edit(state, action) {
            state.memebershipDiscordEdit = action.payload.status
        },
        set_discord_bot_setup(state, action) {
            state.discordBotSetup = action.payload.status
        },
        set_discord_server(state, action) {
            state.discord_server = action.payload.server
        },
        reset_auth(state) {
            state.jwt = null
            state.provider = null
            state.web3Provider = null
            state.address = null
            state.chainId = null
            state.chain = null
            state.loggedIn = false
            state.role = null
            state.contributorName = ""
            state.isAdmin = true
        },
        set_last_selection(state, action) {
            if (state.lastSelection) {
                const lastSelection = state.lastSelection
                const isInclude = lastSelection.filter(
                    (x) => x.address === action.payload.address
                )
                if (isInclude.length > 0) {
                    const newSelection = isInclude.filter(
                        (x) => x.address !== action.payload.address
                    )
                    newSelection.push({
                        address: action.payload.address,
                        dao_uuid: action.payload.dao_uuid,
                    })
                    state.lastSelection = newSelection
                } else {
                    lastSelection.push({
                        address: action.payload.address,
                        dao_uuid: action.payload.dao_uuid,
                    })
                    state.lastSelection = lastSelection
                }
            } else {
                // //console.log('initial', action.payload.address, action.payload.dao_uuid)
                state.lastSelection = [
                    {
                        address: action.payload.address,
                        dao_uuid: action.payload.dao_uuid,
                    },
                ]
            }
        },
        setContributorDiscordSetup(state, action) {
            state.contributorDiscordSetup =
                action.payload.contributorDiscordSetup
        },
        setContributorDiscordAuth(state, action) {
            state.contributorDiscordAuth = {
                status: action.payload.status,
                name: action.payload.name,
                dao_uuid: action.payload.dao_uuid,
            }
        },
        set_selected_discord_server(state, action) {
            state.selected_server = action.payload.server
        },
    },
})

export const authActions = authSlice.actions

export default authSlice
