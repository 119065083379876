import routes from "../../constant/routes"
import apiClient from "../../utils/api_client"
import { contributorAction } from "../reducers/contributor-slice"
import { daoAction } from "../reducers/dao-slice"
import { transactionAction } from "../reducers/transaction-slice"

export const setTransaction = (item, ethPrice, isPOCPApproved = false) => {
    return (dispatch) => {
        dispatch(
            transactionAction.set_current_transaction({
                data: item,
                price: ethPrice,
                isPOCPApproved,
            })
        )
    }
}

export const setEthPrice = (ethPrice) => {
    return (dispatch) => {
        dispatch(transactionAction.set_current_Ethprice({ price: ethPrice }))
    }
}

export const setPayment = (item) => {
    return (dispatch) => {
        setTransaction(null)
        dispatch(transactionAction.set_current_payment({ data: item }))
    }
}

export const resetApprovedRequest = () => {
    return async (dispatch) => {
        dispatch(transactionAction.reset_approved_request())
    }
}

export const approveContriRequest = (
    payout,
    isExternal = false,
    feedback,
    mint_badge,
    metadata_hash
) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const currentTransaction = getState().transaction.currentTransaction
        if (isExternal) {
            dispatch(
                transactionAction.set_approved_request({
                    item: { contri_detail: {}, payout },
                })
            )
        } else {
            if (payout.length > 0) {
                dispatch(
                    transactionAction.set_approved_request({
                        item: {
                            contri_detail: currentTransaction,
                            payout,
                            feedback,
                        },
                    })
                )
            }

            const data = {
                status: "APPROVED",
                tokens: payout.length > 0 ? payout : [],
                uuid: currentTransaction.uuid,
                feedback,
                mint_badge,
                metadata_hash,
            }

            try {
                const res = await apiClient.post(
                    `${process.env.REACT_APP_DAO_TOOL_URL}${routes.contribution.createContri}/update`,
                    data,
                    {
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                    }
                )
                if (res.data.success) {
                    const contri_request =
                        getState().contributor.contributionForAdmin.filter(
                            (x) => x.uuid !== currentTransaction.uuid
                        )
                    const pastContributions =
                        getState().contributor.contributionForAdminPast
                    dispatch(
                        contributorAction.set_admin_contribution({
                            contribution: contri_request,
                            contributionForAdminPast: pastContributions,
                        })
                    )

                    return 1
                } else {
                    return 0
                }
            } catch (error) {
                console.error("error....", error)
            }
        }
    }
}

export const rejectContriRequest = (id) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt

        const data = {
            status: "REJECTED",
            tokens: [],
            id,
        }
        try {
            const res = await apiClient.post(
                `${process.env.REACT_APP_DAO_TOOL_URL}${routes.contribution.createContri}/update`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )

            if (res.data.success) {
                const currentList = getState().dao.contribution_request
                dispatch(
                    daoAction.set_after_claim({
                        list: currentList.filter((x) => x.id !== id),
                    })
                )
                return 1
            } else {
                return 0
            }
        } catch (error) {
            // console.log('error....', error)
        }
    }
}

export const rejectApproval = (uuid) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt

        const data = {
            status: "REQUESTED",
            tokens: [],
            uuid,
        }

        try {
            const res = await apiClient.post(
                `${process.env.REACT_APP_DAO_TOOL_URL}${routes.contribution.createContri}/update`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )

            if (res.data.success) {
                // dispatch(transactionAction.)
                dispatch(transactionAction.set_reject_request({ uuid }))
                return 1
            } else {
                return 0
            }
        } catch (error) {
            // console.log('error....', error)
        }
    }
}

export const setRejectModal = (status) => {
    return (dispatch) => {
        dispatch(transactionAction.set_reject_modal({ status }))
    }
}
