import routes from "../../constant/routes"
import apiClient from "../../utils/api_client"
import { claimContributionBadge } from "../../utils/POCPServiceSdk"
import { contributorAction } from "../reducers/contributor-slice"
import { daoAction } from "../reducers/dao-slice"
import { transactionAction } from "../reducers/transaction-slice"
export const set_invite_id = (id) => {
    return (dispatch) => {
        dispatch(contributorAction.set_invite_code({ id }))
    }
}

export const getContributionAsContributorApproved = () => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const uuid = getState().dao.currentDao?.uuid
        try {
            const res = await apiClient.get(
                `${
                    process.env.REACT_APP_DAO_TOOL_URL
                }${`/contrib`}?dao_uuid=${uuid}&contributor=1&voucher=1`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            const res2 = await apiClient.get(
                `${
                    process.env.REACT_APP_DAO_TOOL_URL
                }${`/badge/approved`}?dao_uuid=${uuid}&is_contributor_view=1`,

                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )

            if (res.data.success && res.data.success) {
                const newApproved = [
                    ...res.data.data.contributions,
                    ...res2.data.data.customized_badge,
                ]

                newApproved.sort(
                    (a, b) => a[Object.keys(a)].nonce - b[Object.keys(b)].nonce
                )

                console.log("sorted...", newApproved)
                dispatch(
                    contributorAction.set_contributor_contribution_approved({
                        approved: newApproved,
                    })
                )
                dispatch(
                    contributorAction.set_contribution_counts({
                        contribution_counts: res.data.data.counts,
                    })
                )
            } else {
                return false
            }
        } catch (error) {
            console.error(error)
        }
    }
}

export const getContributionAsAdmin = () => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const uuid = getState().dao.currentDao?.uuid

        try {
            const res = await apiClient.get(
                `${
                    process.env.REACT_APP_DAO_TOOL_URL
                }${`/contrib`}?dao_uuid=${uuid}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            const res2 = await apiClient.get(
                `${
                    process.env.REACT_APP_DAO_TOOL_URL
                }${`/badge/approved`}?dao_uuid=${uuid}&is_contributor_view=0`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )

            if (res.data.success) {
                const pendingContribution = []
                const pastContribution = [...res2.data.data.customized_badge]
                console.log(
                    "Contribution badge",
                    res2.data.data.customized_badge
                )
                res.data.data.contributions.forEach((x) => {
                    if (x.status === "REQUESTED" && !x.payout_status) {
                        pendingContribution.push(x)
                    } else if (x.status === "APPROVED") {
                        if (!x.voucher_id && x?.mint_badge) {
                            dispatch(
                                daoAction.add_approved_badges({
                                    contribution: x,
                                })
                            )
                        }
                        if (
                            (x.voucher_id && x.mint_badge) ||
                            x.badge_status ||
                            x.is_badge
                        ) {
                            pastContribution.push(x)
                        }
                        if (x.tokens.length > 0 && !x.payout_status) {
                            dispatch(
                                transactionAction.set_approved_request({
                                    item: {
                                        contri_detail: x,
                                        payout: x.tokens,
                                        feedback: x.feedback,
                                    },
                                })
                            )
                        }
                    }
                })
                const approvedRequest = res.data.data.contributions.filter(
                    (x) => x.status === "APPROVED"
                )
                dispatch(
                    contributorAction.set_admin_contribution({
                        contribution: pendingContribution,
                        contributionForAdminPast: pastContribution,
                        count: res.data.data.contributions.length,
                        approved_request: approvedRequest,
                    })
                )
            } else {
                return false
            }
        } catch (error) {
            dispatch(
                contributorAction.set_admin_contribution({
                    contribution: [],
                    contributionForAdminPast: [],
                    count: 0,
                    approved_request: [],
                })
            )
        }
    }
}

export const getRole = (uuid) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const address = getState().auth.address
        const data = {
            uuid,
            wallet_addr: address,
        }
        const res = await apiClient.post(
            `${process.env.REACT_APP_DAO_TOOL_URL}${routes.dao.getRole}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            }
        )
        if (res.data.success) {
            if (res.data.data.role === "NO_ROLE") {
                return false
            } else {
                return res.data.data.role
            }
        } else {
            return false
        }
    }
}

export const getDiscordUserId = (grant_code, redirect_uri) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const res = await apiClient.get(
            `${process.env.REACT_APP_DAO_TOOL_URL}${routes.discord.userId}?discord_code=${grant_code}&redirect_uri=${redirect_uri}`,
            {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            }
        )
        if (res.data.success) {
            return res.data.data.discord_user_id
        } else {
            return null
        }
    }
}

export const getDiscordSevers = (grant_code, redirect_uri, dao_uuid) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt

        const res = await apiClient.get(
            `${
                process.env.REACT_APP_DISCORD_URL
            }${"/userGuilds"}?discord_code=${grant_code}&redirect_uri=${redirect_uri}`,
            {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            }
        )
        console.log(res)
        if (res) {
            await apiClient.post(
                `${process.env.REACT_APP_DAO_TOOL_URL}${"/dao/update/user"}`,
                {
                    dao_uuid,
                    discord_id: res.data.data.user.id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
        }

        return res.data.data.guilds
    }
}

export const raiseContributionRequest = (membership_id, details) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const address = getState().auth.address
        const uuid = getState().dao.currentDao?.uuid
        const data = {
            created_for: address,
            request: 1,
            dao_uuid: uuid,
            membership_id,
            contrib_schema_id: 1,
            details,
        }
        try {
            const res = await apiClient.post(
                `${process.env.REACT_APP_DAO_TOOL_URL}${`/contrib`}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            if (res.data.success) {
                return true
            } else {
                return false
            }
        } catch (error) {
            return false
        }
    }
}

export const createContributionBadgeSchema = (schemaArray, id) => {
    return async (dispatch, getState) => {
        const uuid = getState().dao.currentDao?.uuid
        const jwt = getState().auth.jwt

        const data = {
            dao_uuid: uuid,
            schema: schemaArray,
            version: id,
        }
        try {
            const res = await apiClient.post(
                `${process.env.REACT_APP_DAO_TOOL_URL}${routes.contribution.createSchema}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )

            if (res.data.success) {
                dispatch(
                    contributorAction.set_contributor_schema({
                        schema: schemaArray,
                        id,
                    })
                )
                return 1
            } else {
                dispatch(
                    contributorAction.set_contributor_schema({
                        schema: [],
                        id: 0,
                    })
                )
                return 0
            }
        } catch (error) {
            console.error("error, on schema creation", error)
        }
    }
}

export const setContributionDetail = (item) => {
    return (dispatch) => {
        dispatch(contributorAction.set_contribution_detail({ item }))
    }
}

export const setDaoName = (name) => {
    return (dispatch) => {
        dispatch(contributorAction.setDaoName({ name }))
    }
}

export const actionOnGenerateSchemaModal = (status) => {
    return (dispatch) => {
        dispatch(contributorAction.set_schema_modal({ status }))
    }
}

export const actionOnBadgesModal = (status) => {
    return (dispatch) => {
        dispatch(contributorAction.set_badges_mint_modal({ status }))
    }
}

export const successConfirmationModal = (status) => {
    return (dispatch) => {
        dispatch(contributorAction.set_success_modal({ status }))
    }
}

export const actionOnContributionRequestModal = (status) => {
    return (dispatch) => {
        dispatch(contributorAction.set_contribution_badge_modal({ status }))
    }
}

export const badgeSelectionMember = (address) => {
    return (dispatch) => {
        dispatch(contributorAction.set_badges_mint_address({ address }))
    }
}

export const updateContributionVoucher = (
    signed_voucher,
    contribution_uuids
) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const uuid = getState().dao.currentDao?.uuid
        const data = { dao_uuid: uuid, signed_voucher, contribution_uuids }
        try {
            await apiClient.post(
                `${process.env.REACT_APP_DAO_TOOL_URL}${`/contrib/voucher`}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
        } catch (error) {}
    }
}

export const createContributionVouchers = (
    address,
    signed_voucher,
    details,
    metadata_hash
) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const uuid = getState().dao.currentDao?.uuid
        const contrib_schema_id = getState().contributor.contributorSchemaId
        const data = {
            created_for: address,
            request: false,
            dao_uuid: uuid,
            contrib_schema_id,
            signed_voucher,
            details,
            metadata_hash,
        }
        try {
            const res = await apiClient.post(
                `${process.env.REACT_APP_DAO_TOOL_URL}${`/contrib`}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            if (res.data.success) {
                return true
            } else {
                return false
            }
        } catch (error) {
            return false
        }
    }
}

export const getContributionSchema = () => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const uuid = getState().dao.currentDao?.uuid
        try {
            const res = await apiClient.get(
                `${
                    process.env.REACT_APP_DAO_TOOL_URL
                }${`/contrib/get_contrib_schema`}?dao_uuid=${uuid}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            console.log("schema", res.data)
            if (res.data.success) {
                dispatch(
                    contributorAction.set_contributor_schema({
                        schema: res.data.data.schema,
                        id: res.data.data.version,
                    })
                )
            } else {
                return false
            }
        } catch (error) {
            dispatch(
                contributorAction.set_contributor_schema({
                    schema: [],
                    id: 0,
                })
            )
        }
    }
}

export const setContributionSelection = (contribution) => {
    return (dispatch) => {
        dispatch(contributorAction.set_contribution_selection({ contribution }))
    }
}

export const getPendingContributions = () => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const uuid = getState().dao.currentDao?.uuid

        try {
            const res = await apiClient.get(
                `${
                    process.env.REACT_APP_DAO_TOOL_URL
                }${`/contrib`}?dao_uuid=${uuid}&contributor=1`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            if (res.data.success) {
                dispatch(
                    contributorAction.set_contributor_contribution_pending({
                        pending: res.data.data.contributions,
                    })
                )
                dispatch(
                    contributorAction.set_contribution_counts({
                        contribution_counts: res.data.data.counts,
                    })
                )
            } else {
                return false
            }
        } catch (error) {
            console.error(error)
        }
    }
}

export const getPastContributions = () => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const uuid = getState().dao.currentDao?.uuid
        const address = getState().auth.address

        try {
            const res = await apiClient.get(
                `${process.env.REACT_APP_DAO_TOOL_URL}${routes.contribution.pastContributions}?dao_uuid=${uuid}&addr=${address}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )

            const res2 = await apiClient.get(
                `${process.env.REACT_APP_DAO_TOOL_URL}${routes.customBadge.pastBadges}?dao_uuid=${uuid}&addr=${address}&is_contributor_view=1`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            console.log("res past", res2.data.data, res.data.data.contributions)
            if (res.data.success) {
                dispatch(
                    contributorAction.set_contributor_contribution_past({
                        past: [
                            ...res.data.data.contributions,
                            ...res2.data.data.customized_badge,
                        ],
                    })
                )
                return [
                    ...res.data.data.contributions,
                    ...res2.data.data.customized_badge,
                ]
            } else {
                return false
            }
        } catch (error) {
            console.error(error)
        }
    }
}

export const contributionBadgeClaim = (
    contributionUuid,
    memberTokenId,
    callbackOnSuccess,
    contributions
) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const proxyContract = getState().dao.daoProxyAddress
        try {
            const res = await apiClient.get(
                `${
                    process.env.REACT_APP_DAO_TOOL_URL
                }${`/contrib/voucher`}?contribution_uuid=${contributionUuid}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            if (res.data.success) {
                const tokens =
                    res.data?.data?.signed_voucher?.tokenUri?.split(",")
                const approveIndexes = []
                contributions.forEach((contribution) => {
                    if (contribution.isChecked) {
                        approveIndexes.push(
                            tokens.indexOf(contribution?.metadata_hash)
                        )
                    }
                })

                const hashCallbackFn = (x) => {
                    dispatch(
                        sendClaimTxHash(
                            x,
                            memberTokenId,
                            approveIndexes,
                            contributions[0].voucher_uuid
                        )
                    )
                }
                await claimContributionBadge(
                    proxyContract,
                    res.data?.data?.signed_voucher,
                    memberTokenId,
                    approveIndexes,
                    hashCallbackFn,
                    callbackOnSuccess
                )
            } else {
                return false
            }
        } catch (error) {
            return false
        }
    }
}

export const customBadgeClaim = (
    signed_voucher,
    memberTokenId,
    callbackOnSuccess,
    customBadges
) => {
    return async (dispatch, getState) => {
        const proxyContract = getState().dao.daoProxyAddress
        try {
            const index = signed_voucher.memberTokenIds?.indexOf(
                parseInt(memberTokenId)
            )

            const hashCallbackFn = (x) => {
                dispatch(
                    sendCustomBadgeTxHash(
                        x,
                        memberTokenId,
                        [signed_voucher?.index],
                        customBadges[0].voucher_uuid
                    )
                )
            }

            await claimContributionBadge(
                proxyContract,
                signed_voucher,
                memberTokenId,
                [index],
                hashCallbackFn,
                callbackOnSuccess
            )
        } catch (error) {
            return false
        }
    }
}

export const rejectContributionVoucher = (
    token_id,
    voucher_uuid,
    contributions
) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const uuid = getState().dao.currentDao?.uuid
        try {
            const res = await apiClient.post(
                `${
                    process.env.REACT_APP_DAO_TOOL_URL
                }${`/contrib/reject_voucher`}`,
                {
                    dao_uuid: uuid,
                    token_id,
                    voucher_uuid,
                },
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            if (res.data.success) {
                dispatch(removeClaimedContributionVoucher(contributions, true))
            }
        } catch (err) {
            console.error("err", err)
            return false
        }
    }
}

const sendClaimTxHash = (
    tx_hash,
    membership_token_id,
    claimed_indexes,
    voucher_uuid
) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const uuid = getState().dao.currentDao?.uuid
        try {
            const res = await apiClient.post(
                `${
                    process.env.REACT_APP_DAO_TOOL_URL
                }${`/contrib/update/voucher`}`,
                {
                    dao_uuid: uuid,
                    voucher_uuid,
                    tx_hash,
                    membership_token_id,
                    claimed_indexes,
                    is_contribution: 1,
                },
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            if (res.data.success) {
                dispatch(getContributionAsContributorApproved())
            }
        } catch (err) {
            console.error("err", err)
            return false
        }
    }
}

const sendCustomBadgeTxHash = (
    tx_hash,
    membership_token_id,
    claimed_indexes,
    voucher_uuid
) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const uuid = getState().dao.currentDao?.uuid
        try {
            const res = await apiClient.post(
                `${
                    process.env.REACT_APP_DAO_TOOL_URL
                }${`/contrib/update/voucher`}`,
                {
                    dao_uuid: uuid,
                    voucher_uuid,
                    tx_hash,
                    membership_token_id,
                    claimed_indexes,
                    is_contribution: 0,
                },
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            if (res.data.success) {
                dispatch(getContributionAsContributorApproved())
            }
        } catch (err) {
            console.error("err", err)
            return false
        }
    }
}

export const setClaimLoading = (claimLoading) => {
    return async (dispatch) => {
        dispatch(contributorAction.setClaimLoading({ claimLoading }))
    }
}

export const removeClaimedContributionVoucher = (
    claimedContributions,
    isReject
) => {
    return async (dispatch, getState) => {
        const approvedContributions =
            getState().contributor.contributionForContributorApproved

        const remainingContributionsInVoucher = claimedContributions.filter(
            (ele) => !ele.isChecked
        )

        const voucher = approvedContributions?.[0]
        const tempApprovedContributions = approvedContributions?.map((ele) => ({
            ...ele,
        }))
        if (remainingContributionsInVoucher.length && !isReject) {
            for (const key in voucher) {
                if (voucher?.[key]?.contributions?.length) {
                    tempApprovedContributions[0][key].contributions =
                        remainingContributionsInVoucher
                }
            }
            dispatch(
                contributorAction.set_contributor_contribution_approved({
                    approved: tempApprovedContributions,
                })
            )
        } else {
            dispatch(
                contributorAction.set_contributor_contribution_approved({
                    approved: approvedContributions?.slice(1) || [],
                })
            )
        }
    }
}

export const removeClaimedCustomBadge = () => {
    return async (dispatch, getState) => {
        const approvedContributions =
            getState().contributor.contributionForContributorApproved

        dispatch(
            contributorAction.set_contributor_contribution_approved({
                approved: approvedContributions?.slice(1),
            })
        )
    }
}

export const getContributorStats = () => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const uuid = getState().dao.currentDao?.uuid
        const address = getState().auth.address
        try {
            const res = await apiClient.get(
                `${
                    process.env.REACT_APP_DAO_TOOL_URL
                }${`/contrib/stats`}?dao_uuid=${uuid}&addr=${address}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            if (res.data.success) {
                dispatch(
                    contributorAction.setContributorStats({
                        stats: res.data.data,
                    })
                )
            } else {
                return false
            }
        } catch (error) {
            console.error(error)
        }
    }
}

const wait = function (ms = 1000) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms)
    })
}
export const getPastContributionsPolled = () => {
    return async (dispatch, getState) => {
        const pastContributionsCount =
            getState().contributor.contributionForContributorPast.length

        let pastContributions = await dispatch(getPastContributions())
        while (pastContributions.length <= pastContributionsCount) {
            await wait(2000)
            pastContributions = await dispatch(getPastContributions())
        }
        dispatch(setPastContributionsSyncing(false))
    }
}

export const setPastContributionsSyncing = (status) => {
    return async (dispatch) => {
        dispatch(
            contributorAction.setPastContributionsSyncing({
                pastContributionsSyncing: status,
            })
        )
    }
}
