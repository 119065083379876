import { Biconomy } from "@biconomy/mexa"
import axios from "axios"
import Pocp, { PocpGetters } from "pocp-service-sdk"

import { web3 } from "../constant/web3"
import apiClient from "../utils/api_client"
import { chainType } from "./chainType"
import { getSelectedChainId } from "./wagmiHelpers"
const currentNetwork = getSelectedChainId()

let pocpInstance = null

export const initPOCP = async (dao_uuid, provider, signer, chainId) => {
    console.log("Bivconoomy Initialisation", signer)
    pocpInstance = new Pocp(
        signer,
        signer.provider.provider,
        chainType(chainId) === "Testnet" ? 80001 : 137,
        chainType(chainId) === "Testnet"
            ? web3.rep3Mumbai
            : dao_uuid === "981349a995c140d8b7fb5c110b0d133b"
            ? web3.rep3V1Matic
            : web3.rep3V2Matic,
        {
            biconomyInstance: Biconomy,
            apiKey:
                chainType(chainId) === "Testnet"
                    ? "h3GRiJo5V.ea5e72c1-a3dd-44cf-824e-1bd77a681ff7"
                    : dao_uuid === "981349a995c140d8b7fb5c110b0d133b"
                    ? "a1SusDqqY.24edf34d-6125-4026-af88-b156a96b7f85"
                    : "gD5tL5Hyt.caf51015-4e19-4873-9540-65443a4519e9",
            relayURL:
                chainType(chainId) === "Testnet"
                    ? "https://polygon-mumbai.g.alchemy.com/v2/8zhyGM-aq1wJ4TFspyVp-dOAQ27TWozK"
                    : "https://polygon-mainnet.g.alchemy.com/v2/gBoo6ihGnSUa3ObT49K36yHG6BdtyuVo",
        }
    )

    await pocpInstance.createInstance()
}

export const getInitializedStatus = () => {
    return pocpInstance?.packageInitialised
}

export const deployDaoContract = async (
    daoName,
    daoSymbol,
    approverAddress,
    hashCallbackFn,
    confirmCallbackFn
) => {
    console.log("Pocp instance", pocpInstance.packageInitialised)
    pocpInstance.packageInitialised &&
        (await pocpInstance.daoDeploy(
            daoName,
            daoSymbol,
            approverAddress,
            hashCallbackFn,
            confirmCallbackFn
        ))
}

export const claimVoucher = async (
    contractAddress,
    voucher,
    claimerAddressIndex,
    dao_uuid,
    hashCallbackFn,
    callbackFn
) => {
    console.log("Pocp instance", pocpInstance.packageInitialised)
    pocpInstance.packageInitialised &&
        (await pocpInstance.claimMembershipNft(
            contractAddress,
            voucher,
            claimerAddressIndex,
            dao_uuid === "981349a995c140d8b7fb5c110b0d133b"
                ? "signTypedDatav1.0"
                : "signTypedDatav2.0",
            1e7,
            1e7,
            async (x) => {
                await hashCallbackFn(x)
            },
            callbackFn
        ))
}

export const upgradeMembershipNft = async (
    contractAddress,
    tokenId,
    level,
    category,
    metaDataHash,
    transactionHashCallback,
    callbackFunction
) => {
    console.log("Pocp instance", pocpInstance.packageInitialised)
    pocpInstance.packageInitialised &&
        (await pocpInstance.upgradeMembershipNft(
            contractAddress,
            tokenId,
            level,
            category,
            metaDataHash,
            1e7,
            1e7,
            async (x) => {
                await transactionHashCallback(x)
            },
            callbackFunction
        ))
}

export const claimContributionBadge = async (
    contractAddress,
    voucher,
    memberTokenId,
    approveIndex,
    hashCallbackFn,
    callbackFn
) => {
    console.log("Pocp instance", pocpInstance.packageInitialised)
    if (pocpInstance.packageInitialised) {
        try {
            return await pocpInstance.claimContributionBadges(
                contractAddress,
                voucher,
                memberTokenId,
                approveIndex,
                1e7,
                1e7,
                hashCallbackFn,
                callbackFn
            )
        } catch (error) {
            console.error("error", error)
        }
    }
}

export const createMembershipVoucher = async (
    contractAddress,
    level,
    category,
    end,
    addresses,
    metadataHash,
    dao_uuid
) => {
    try {
        const res = await pocpInstance.createMembershipVoucher(
            contractAddress,
            level,
            category,
            end,
            addresses,
            metadataHash,
            dao_uuid === "981349a995c140d8b7fb5c110b0d133b"
                ? "signTypedDatav1.0"
                : "signTypedDatav2.0"
        )
        if (res?.signature) {
            return res
        } else {
            return false
        }
    } catch (error) {
        console.log("error", error)
        throw error
    }
}

export const createContributionVoucher = async (
    contractAddress,
    arrayOfMemberTokenId,
    arrayofBadgeType,
    arrayOfTokenUri,
    arrayOfNounce,
    arrayOfData
) => {
    try {
        return await pocpInstance.createBadgeVoucher(
            contractAddress,
            arrayOfMemberTokenId,
            arrayofBadgeType,
            arrayOfTokenUri,
            arrayOfNounce,
            arrayOfData
        )
    } catch (error) {
        console.error("error", error)
    }
}

// getter functions

export const getAllMembershipBadges = async (
    claimer,
    contractAddress,
    uuid
) => {
    const membershipNftQuery = `
query($contractAddress: String ,$claimer: String ) {
    membershipNFTs(where:{contractAddress:$contractAddress,claimer:$claimer},orderBy:time,orderDirection:desc){
    id
    time
    claimer
    level
    category
    tokenID
    contractAddress{
      id
    }
    metadataUri
  }
}
`
    const pocpGetter = new PocpGetters(
        chainType(currentNetwork?.chainId) === "Testnet"
            ? "https://api.thegraph.com/subgraphs/name/eth-jashan/rep3-mumbai"
            : uuid === "981349a995c140d8b7fb5c110b0d133b"
            ? "https://api.thegraph.com/subgraphs/name/eth-jashan/pocpv15-matic"
            : "https://api.thegraph.com/subgraphs/name/eth-jashan/rep3-matic"
    )
    const membershipNftVariable = { contractAddress, claimer }
    return await pocpGetter.getForCustomQuery(
        membershipNftQuery,
        membershipNftVariable
    )
}

export const getMembershipBadgeFromTxHash = (txHash, uuid) => {
    const pocpGetter = new PocpGetters(
        chainType(currentNetwork?.chainId) === "Testnet"
            ? "https://api.thegraph.com/subgraphs/name/eth-jashan/rep3-mumbai"
            : uuid === "981349a995c140d8b7fb5c110b0d133b"
            ? "https://api.thegraph.com/subgraphs/name/eth-jashan/pocpv15-matic"
            : "https://api.thegraph.com/subgraphs/name/eth-jashan/rep3-matic"
    )
    return pocpGetter.getMembershipNftsForHash(txHash)
}

export const getInfoHash = async (txHash, uuid) => {
    const pocpGetter = new PocpGetters(
        chainType(currentNetwork?.chainId) === "Testnet"
            ? "https://api.thegraph.com/subgraphs/name/eth-jashan/rep3-mumbai"
            : uuid === "981349a995c140d8b7fb5c110b0d133b"
            ? "https://api.thegraph.com/subgraphs/name/eth-jashan/pocpv15-matic"
            : "https://api.thegraph.com/subgraphs/name/eth-jashan/rep3-matic"
    )

    const res = await pocpGetter.getdaoInfoForHash(txHash)
    return res
}

export const getArrayOfMemberToken = async (
    arrayOfAddress,
    contractAddress
) => {
    // const tokenId = []
    const result = Promise.all(
        arrayOfAddress.map(async (x) => {
            const memberships = await getAllMembershipBadges(
                x,
                contractAddress,
                false
            )
            return parseInt(memberships.data.membershipNFTs[0].tokenID)
        })
    )
    return result
}

export const getArrayOfNounce = async (arrayOfMemberToken, dao_uuid, jwt) => {
    const result = Promise.all(
        arrayOfMemberToken.map(async (x) => {
            const res = await apiClient.get(
                `${
                    process.env.REACT_APP_DAO_TOOL_URL
                }${`/membership/get_next_nonce`}?token_id=${x}&dao_uuid=${dao_uuid}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            if (res.data.success) {
                return res.data.data
            }
        })
    )
    return result
}

export const createContributionMetadataUri = async (
    dao_logo_url,
    dao_name,
    contri_title,
    contri_time_spent,
    contri_date,
    contri_type,
    feedback,
    contri_attribute,
    dao_type = "default"
) => {
    try {
        const res = await axios.post(
            `https://test-staging.api.drepute.xyz/arweave_server/contribution-badge`,
            {
                dao_logo_url,
                dao_name,
                contri_title,
                contri_time_spent,
                contri_date,
                contri_type,
                feedback,
                contri_attribute,
                dao_type,
            }
        )
        if (res.data.success) {
            return res.data.data
        }
    } catch (error) {
        console.error("error ", error)
    }
}

export const createDynamicMembershipBadge = async (
    contractAddress,
    claimer,
    membershipInfo,
    isMainnet
) => {
    try {
        const res = await axios.post(
            `https://test-staging.api.drepute.xyz/arweave_server/interactive-html`,
            {
                contractAddress,
                claimer,
                isMainnet,
                membershipInfo,
            }
        )
        if (res.data.success) {
            return res.data.data
        }
    } catch (error) {
        console.error("error ", error)
    }
}

export const getBadgeOnMetadata = async (metadatUri, uuid) => {
    try {
        const associationBadgeQuery = `
query($metadatUri: String ) {
  associationBadges(where:{metadatUri:$metadatUri}){
    id
    metadatUri
    claimer
    type
    txHash
    membershipId
    contractAddress{
      id
    }
    tokenID
  }
}
`

        const associationBadgeVariable = { metadatUri }
        const pocpGetter = new PocpGetters(
            chainType(currentNetwork?.chainId) === "Testnet"
                ? "https://api.thegraph.com/subgraphs/name/eth-jashan/rep3-mumbai"
                : uuid === "981349a995c140d8b7fb5c110b0d133b"
                ? "https://api.thegraph.com/subgraphs/name/eth-jashan/pocpv15-matic"
                : "https://api.thegraph.com/subgraphs/name/eth-jashan/rep3-matic"
        )
        const customResult = await pocpGetter.getForCustomQuery(
            associationBadgeQuery,
            associationBadgeVariable
        )
        return customResult
    } catch (error) {}
}

export const getBadgeOnContractAddress = async (contractAddress, uuid) => {
    try {
        const associationBadgeQuery = `
query($contractAddress: String ) {
  associationBadges(where:{contractAddress:$contractAddress}){
    id
    metadatUri
    claimer
    type
    txHash
    membershipId
    contractAddress{
      id
    }
    tokenID
  }
}
`

        const associationBadgeVariable = { contractAddress }
        const pocpGetter = new PocpGetters(
            chainType(currentNetwork?.chainId) === "Testnet"
                ? "https://api.thegraph.com/subgraphs/name/eth-jashan/rep3-mumbai"
                : uuid === "981349a995c140d8b7fb5c110b0d133b"
                ? "https://api.thegraph.com/subgraphs/name/eth-jashan/pocpv15-matic"
                : "https://api.thegraph.com/subgraphs/name/eth-jashan/rep3-matic"
        )
        const customResult = await pocpGetter.getForCustomQuery(
            associationBadgeQuery,
            associationBadgeVariable
        )
        return customResult
    } catch (error) {
        console.error("err", error)
    }
}

export const updateApprovers = async (
    approverAddresses,
    contractAddress,
    gas,
    gasLimit,
    transactionHashCallback,
    callbackFunction,
    uuid
) => {
    const subgraphUrl =
        chainType(currentNetwork?.chainId) === "Testnet"
            ? "https://api.thegraph.com/subgraphs/name/eth-jashan/rep3-mumbai"
            : uuid === "981349a995c140d8b7fb5c110b0d133b"
            ? "https://api.thegraph.com/subgraphs/name/eth-jashan/pocpv15-matic"
            : "https://api.thegraph.com/subgraphs/name/eth-jashan/rep3-matic"

    return await pocpInstance.updateApprovers(
        approverAddresses,
        contractAddress,
        subgraphUrl,
        gas,
        gasLimit,
        transactionHashCallback,
        callbackFunction
    )
}

export const getApproverForDao = async (contractAddress, uuid) => {
    try {
        const getApproversOfDao = `
query($contractAddress: String) {
  daos(where:{id: $contractAddress}){
    id
    approver
  }
}`

        const approverForDaoVariable = { contractAddress }
        const pocpGetter = new PocpGetters(
            chainType(currentNetwork?.chainId) === "Testnet"
                ? "https://api.thegraph.com/subgraphs/name/eth-jashan/rep3-mumbai"
                : uuid === "981349a995c140d8b7fb5c110b0d133b"
                ? "https://api.thegraph.com/subgraphs/name/eth-jashan/pocpv15-matic"
                : "https://api.thegraph.com/subgraphs/name/eth-jashan/rep3-matic"
        )
        const customResult = await pocpGetter.getForCustomQuery(
            getApproversOfDao,
            approverForDaoVariable
        )
        return customResult
    } catch (error) {
        console.error("err", error)
    }
}
