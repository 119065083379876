import "./style.scss"

import { message } from "antd"
import React, { useCallback, useEffect, useState } from "react"
import { FaDiscord } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router"

import chevron_right from "../../assets/Icons/chevron_right.svg"
import plus_colored from "../../assets/Icons/plus_colored.svg"
import rightArrow from "../../assets/Icons/right_arrow_white.svg"
// import { links } from "../../constant/links"
import {
    joinContributor,
    setAdminStatus,
    setContributorDiscordAuth,
    setContributorDiscordSetup,
    setContriInfo,
    signout,
} from "../../store/actions/auth-action"
import { checkDaoDiscord } from "../../store/actions/dao-action"
import InputText from "../InputComponent/Input"
import NextButton from "../NextButton"

const ContributorSignup = ({
    discordUserId,
    onboardingStep,
    discordAccessCode,
}) => {
    const { id } = useParams()
    const [name, setName] = useState("")
    const dispatch = useDispatch()
    const [OnboardingStep, setOnboardingStep] = useState(onboardingStep || 0)
    const unclaimedMembershipVouchersForAddress = useSelector(
        (x) => x.membership.unclaimedMembershipVouchersForAddress
    )
    const inviteCode = useSelector((x) => x.contributor.invite_code)

    const [selectedDao, setSelectedDao] = useState(null)

    // const [role, setRole] = useState()
    const role = undefined

    const navigate = useNavigate()
    const isDiscordActive = useSelector((x) => x.dao.isDiscordActive)
    const contributorDiscordAuth = useSelector(
        (x) => x.auth.contributorDiscordAuth
    )

    const fetchRoles = useCallback(async () => {
        if (!id) {
            dispatch(signout())
        }
    }, [dispatch])

    useEffect(() => {
        fetchRoles()
    }, [fetchRoles])

    const onSubmit = async () => {
        dispatch(setContriInfo(name, role))
        try {
            const res = await dispatch(
                joinContributor(
                    inviteCode ? id : selectedDao?.uuid,
                    discordUserId,
                    name
                )
            )
            if (res) {
                navigate(`/dashboard`)
                message.success("You successfully joined as contributor")
                dispatch(setAdminStatus(true))
            }
        } catch (error) {
            message.error("Error on Joining")
        }
    }

    const onNextButtonCallback = () => {
        console.log("on necxt button", isDiscordActive)
        if (isDiscordActive) {
            setOnboardingStep(2)
        } else {
            onSubmit()
        }
    }

    const selectVoucher = (dao) => {
        setSelectedDao(dao)
        setOnboardingStep(1)
    }

    const goToDaoOnboarding = () => {
        navigate("/onboard/dao")
    }

    useEffect(async () => {
        if (OnboardingStep === 1) {
            dispatch(checkDaoDiscord(id))
        } else if (OnboardingStep === 2 && contributorDiscordAuth?.status) {
            try {
                const res = await dispatch(
                    joinContributor(
                        contributorDiscordAuth.dao_uuid,
                        discordUserId,
                        contributorDiscordAuth.name,
                        discordAccessCode,
                        `${window.location.origin}/discord/fallback`
                    )
                )
                if (res) {
                    dispatch(setContributorDiscordAuth(false, null, null))
                    dispatch(setContributorDiscordSetup(false))
                    navigate(`/dashboard`)
                    message.success("You successfully joined as contributor")
                    dispatch(setAdminStatus(true))
                }
            } catch (error) {
                message.error("Error on Joining")
            }
        }
    }, [onboardingStep])

    const onDiscordAuth = () => {
        dispatch(setContributorDiscordSetup(true))
        dispatch(
            setContributorDiscordAuth(
                true,
                name,
                inviteCode ? id : selectedDao?.uuid
            )
        )
        // window.location.replace(`${links.discord_oauth}`)
        window.location.replace(
            `https://discord.com/api/oauth2/authorize?client_id=${
                process.env.NODE_ENV === "production"
                    ? "976409482533961769"
                    : "976409482533961769"
            }&redirect_uri=${encodeURIComponent(
                `${window.location.origin}/discord/fallback`
            )}&response_type=code&scope=identify%20guilds%20guilds.members.read`
        )
    }

    return (
        <div className="contributor-signup-container">
            {OnboardingStep === 0 ? (
                <div className="layout">
                    <div>
                        <div className="heading">
                            gm gm, invites are waiting for you
                        </div>
                        <div className="dao-vouchers-wrapper">
                            {unclaimedMembershipVouchersForAddress.map(
                                (dao) => (
                                    <div
                                        className="dao-voucher-row"
                                        key={dao?.uuid}
                                        onClick={() => selectVoucher(dao)}
                                    >
                                        <div className="dao-details">
                                            <img
                                                src={dao?.logo_url}
                                                alt=""
                                                className="dao-image"
                                            />
                                            <div className="dao-name">
                                                {dao?.name}
                                            </div>
                                        </div>
                                        <div>
                                            <img src={chevron_right} alt="" />
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                        <div
                            className="new-community"
                            onClick={goToDaoOnboarding}
                        >
                            <img src={plus_colored} alt="" />
                            Setup new community
                        </div>
                    </div>
                </div>
            ) : OnboardingStep === 1 ? (
                <div className="layout">
                    <div style={{ flexDirection: "column", display: "flex" }}>
                        <div className="heading">Almost done</div>
                        <div className="greyHeading">
                            Please tell us a bit
                            <br />
                            about yourself
                        </div>

                        <div>
                            <div style={{ marginTop: "40px" }}>
                                {/* <Typography.Text className="helperText}>What should we call your DAO</Typography.Text> */}
                                <div>
                                    <InputText
                                        width={"60%"}
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        placeholder="What should we call you"
                                        className={
                                            name === "" ? "input" : "inputText"
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nextBtn">
                        <NextButton
                            text={isDiscordActive ? "Next" : "Review"}
                            nextButtonCallback={onNextButtonCallback}
                            isDisabled={name === ""}
                            isContributor={true}
                        />
                    </div>
                </div>
            ) : (
                <div className="layout">
                    <div style={{ flexDirection: "column", display: "flex" }}>
                        <div className="heading">Connect discord</div>
                        <div className="greyHeading">
                            we use this info to automate
                            <br />
                            membership creation
                        </div>

                        <div>
                            <div style={{ marginTop: "40px" }}>
                                {/* <Typography.Text className="helperText}>What should we call your DAO</Typography.Text> */}
                                <button
                                    className="discord-oauth-btn"
                                    onClick={onDiscordAuth}
                                >
                                    <div>
                                        <FaDiscord />
                                        Connect Discord
                                    </div>
                                    <div>
                                        <img
                                            src={rightArrow}
                                            alt="right"
                                            className="icon"
                                        />
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ContributorSignup
