import "./style.scss"

import { message } from "antd"
import React, { useEffect, useState } from "react"
import { AiFillCaretDown } from "react-icons/all"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"

import cross from "../../../../assets/Icons/cross.svg"
import plus_black from "../../../../assets/Icons/plus_black.svg"
// import right_arrow_white from "../../../../assets/Icons/right_arrow_white.svg"
// import tick from "../../../../assets/Icons/tick.svg"
// import upload_file_colored from "../../../../assets/Icons/upload_file_colored.svg"
import { createCustomBadgeVoucher } from "../../../../store/actions/custom-badge-action"
import { getAllDaoMembers } from "../../../../store/actions/membership-action"

export default function AddAddress({
    selectedMembershipBadge,
    closeModal,
    selectedCustomBadgeForMint,
}) {
    // const [isBulkMinting, setIsBulkMinting] = useState(false)
    // const [addresses, setAddresses] = useState([""])
    // const [bulkMintingStep, setBulkMintingStep] = useState(0)
    // const [isCsvUploaded, setIsCsvUploaded] = useState(false)
    // const [bulkAddresses, setBulkAddresses] = useState([])
    // const [addressValidation, setAddressValidation] = useState([false])
    const currentDao = useSelector((x) => x.dao.currentDao)
    const [selectedMembers, setSelectedMembers] = useState([])
    const [showAdditionalSelect, setShowAdditionalSelect] = useState(true)
    const dispatch = useDispatch()

    const addAddress = () => {
        // setAddresses((addresses) => [...addresses, ""])
        // setAddressValidation((validation) => [...validation, false])
        setShowAdditionalSelect(true)
    }

    // const updateAddress = (value, index) => {
    //     const copyOfAddresses = [...addresses]
    //     copyOfAddresses[index] = value
    //     setAddresses(copyOfAddresses)
    // }

    // const updateStatus = (status, index) => {
    //     const copyOfAddresses = [...addressValidation]
    //     copyOfAddresses[index] = status
    //     setAddressValidation(copyOfAddresses)
    // }

    const deleteAddress = (deletingIndex) => {
        // const copyOfAddressesAfterDelete = [...addresses].filter(
        //     (_, index) => index !== deletingIndex
        // )
        // const copyOfAddressesValidationAfterDelete = [
        //     ...addressValidation,
        // ].filter((_, index) => index !== deletingIndex)
        // setAddresses(copyOfAddressesAfterDelete)
        // setAddressValidation(copyOfAddressesValidationAfterDelete)
        const tempSelectedMembers = selectedMembers.filter(
            (_, index) => index !== deletingIndex
        )
        setSelectedMembers([...tempSelectedMembers])
    }

    // const onFileChange = (e) => {
    //     const fileReader = new FileReader()

    //     const files = e.target.files || e.dataTransfer.files
    //     if (!files.length) return

    //     fileReader.readAsText(files[0])

    //     fileReader.onload = (event) => {
    //         const arr = event.target?.result?.split("\r\n")
    //         setBulkAddresses(arr)
    //         setIsCsvUploaded(true)
    //     }
    // }

    // const increaseStep = async () => {
    //     if (bulkMintingStep >= 1) {
    //         setBulkMintingStep(1)
    //         await mintVouchers()
    //     } else {
    //         setBulkMintingStep((bulkMintingStep) => bulkMintingStep + 1)
    //     }
    // }

    const mintVouchers = async () => {
        console.log(
            "selected addresses",
            selectedMembers,
            selectedCustomBadgeForMint
        )
        try {
            await dispatch(
                createCustomBadgeVoucher(
                    selectedMembershipBadge,
                    selectedMembers,
                    // selectedCustomBadgeForMint.token_type
                    3
                )
            )
        } catch (err) {
            message.error("error on signing")
        }
    }

    // const checkIsDisabled = () => {
    //     let isDisabled = false
    //     // if (isBulkMinting) {
    //     //     bulkAddresses.forEach((address) => {
    //     //         if (!address) {
    //     //             isDisabled = true
    //     //         }
    //     //     })
    //     // } else {
    //     addresses.forEach((address) => {
    //         if (!address) {
    //             isDisabled = true
    //         }
    //     })
    //     // }
    //     return isDisabled
    // }

    useEffect(() => {
        dispatch(getAllDaoMembers())
    }, [])

    const allDaoMembers = useSelector((x) => x.membership.allDaoMembers)

    const getAvailableOptions = () => {
        // console.log("temp1", allDaoMembers)
        const temp = allDaoMembers.dao_members.filter((member) => {
            const index = selectedMembers.findIndex(
                (selectedMember) =>
                    selectedMember.value === member.public_address
            )
            console.log("temp2", index)
            if (index === -1) {
                return true
            } else {
                return false
            }
        })
        console.log("remaining members", temp)
        return temp.map((member) => ({
            value: member.public_address,
            label: member.name,
        }))
    }
    const availableOptions = getAvailableOptions()

    const onOptionSelect = (newOption, index) => {
        console.log("oin onchange", isNaN(index), index, newOption)
        setShowAdditionalSelect(false)
        const tempSelectedMembers = [...selectedMembers]
        if (isNaN(index)) {
            tempSelectedMembers.push(newOption)
        } else {
            tempSelectedMembers[index] = newOption
        }
        console.log("temp slected", tempSelectedMembers)
        setSelectedMembers([...tempSelectedMembers])
    }

    const CustomDropDownIndicatior = () => (
        <AiFillCaretDown
            style={{ alignSelf: "center" }}
            color="black"
            size={"1rem"}
        />
    )

    return (
        <div className="add-address-screen-container">
            <div className="close-minting-modal" onClick={closeModal}>
                <img src={cross} alt="" />
            </div>
            <div className="mint-membership-badge-header">
                <div className="mint-membership-badge-left">
                    <div>Minting</div>
                    <div>{selectedCustomBadgeForMint?.type}</div>
                    <div className="mint-membership-badge-name">
                        {selectedMembershipBadge.name}
                    </div>
                </div>
                <div className="mint-membership-badge-right">
                    {currentDao?.uuid === "93ba937e02ea4fdb9633c2cb27345200" ||
                    currentDao?.uuid === "981349a995c140d8b7fb5c110b0d133b" ? (
                        <video autoPlay loop muted>
                            <source src={selectedMembershipBadge?.image_url} />
                        </video>
                    ) : selectedMembershipBadge?.is_video ? (
                        <video autoPlay loop muted className="claimedBadgeImg">
                            <source src={selectedMembershipBadge?.image_url} />
                        </video>
                    ) : (
                        <img
                            className="claimedBadgeImg"
                            src={selectedMembershipBadge?.image_url}
                        />
                    )}
                    {/* {currentDao?.uuid !== "93ba937e02ea4fdb9633c2cb27345200" ||
                    currentDao?.uuid !== "981349a995c140d8b7fb5c110b0d133b" ? (
                        <img src={selectedMembershipBadge.image_url} alt="" />
                    ) : (
                        <video
                            autoPlay
                            loop
                            // className={styles.badgeImage}
                            muted
                        >
                            <source src={selectedMembershipBadge?.image_url} />
                        </video>
                    )} */}
                </div>
            </div>
            {/* {isBulkMinting ? (
                <div className="bulk-minting-wrapper">
                    <div className="bulk-minting-step-row">
                        <div className="bold">
                            Step {bulkMintingStep + 1} of 2 •
                        </div>
                        {bulkMintingStep === 0
                            ? "Upload Addresses"
                            : "Review Address"}
                    </div>
                    <div className="bulk-minting-uploader-wrapper">
                        {bulkMintingStep === 0 ? (
                            <>
                                <label htmlFor="upload-csv-file-input">
                                    <div className="csv-uploading-wrapper">
                                        <div className="csv-upload-icon">
                                            <img
                                                src={
                                                    isCsvUploaded
                                                        ? tick
                                                        : upload_file_colored
                                                }
                                                alt=""
                                            />
                                        </div>
                                        <div className="csv-heading">
                                            {isCsvUploaded
                                                ? "CSV Uploaded"
                                                : "Upload CSV"}
                                        </div>
                                        <div className="csv-description">
                                            All the addresses in the CSV will
                                            get badge minted on their wallet
                                        </div>
                                    </div>
                                </label>
                                <input
                                    type="file"
                                    accept=".csv"
                                    id="upload-csv-file-input"
                                    className="upload-csv-file-input-hidden"
                                    onChange={(e) => onFileChange(e)}
                                />
                            </>
                        ) : (
                            <>
                                <div className="bulk-total-addresses">
                                    {bulkAddresses?.length} Addresses
                                </div>
                                {bulkAddresses?.map((address) => (
                                    <div
                                        key={address}
                                        className="bulk-address-row"
                                    >
                                        {address}
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                    <div className="bulk-minting-btn">
                        <button
                            onClick={increaseStep}
                            disabled={checkIsDisabled()}
                        >
                            {bulkMintingStep === 0
                                ? "Review Addresses"
                                : "Mint Badges"}
                            <img src={right_arrow_white} alt="" />
                        </button>
                    </div>
                </div>
            ) : ( */}
            <>
                <div className="mint-membership-badge-addresses-wrapper">
                    {selectedMembers.map((member, index) => (
                        <div
                            key={member.value}
                            className="selected-address-row"
                        >
                            <Select
                                className="basic-address-select"
                                classNamePrefix="select"
                                closeMenuOnSelect
                                // isDisabled={token_available?.length === 1}
                                components={{
                                    DropdownIndicator: CustomDropDownIndicatior,
                                }}
                                onChange={(x) => {
                                    onOptionSelect(x, index)
                                }}
                                // styles={colourStyles}
                                isSearchable={true}
                                name="color"
                                options={availableOptions}
                                defaultValue={member}
                                menuPosition="fixed"
                            />
                            <div
                                className="address-delete"
                                onClick={() => deleteAddress(index)}
                            >
                                <img src={cross} alt="" />
                            </div>
                        </div>
                    ))}
                    {!showAdditionalSelect ? (
                        <></>
                    ) : (
                        <div className="selected-address-row">
                            <Select
                                className="basic-address-select"
                                classNamePrefix="select"
                                closeMenuOnSelect
                                // isDisabled={token_available?.length === 1}
                                components={{
                                    DropdownIndicator: CustomDropDownIndicatior,
                                }}
                                onChange={(x) => {
                                    console.log("onchange", x)
                                    onOptionSelect(x)
                                }}
                                // styles={colourStyles}
                                isSearchable={true}
                                name="color"
                                options={availableOptions}
                                menuPosition="fixed"
                            />
                            <div
                                className="address-delete"
                                onClick={() => setShowAdditionalSelect(false)}
                            >
                                <img src={cross} alt="" />
                            </div>
                        </div>
                    )}
                </div>
                <div className="add-address" onClick={addAddress}>
                    <img src={plus_black} alt="" />
                    Add another Address
                </div>
                <div className="minting-buttons-wrapper">
                    <button
                        onClick={mintVouchers}
                        disabled={selectedMembers.length < 1}
                    >
                        Mint Badges • {selectedMembers?.length}
                    </button>
                    {/* <div
                            onClick={() => setIsBulkMinting(true)}
                            className="bulk-minting-text"
                        >
                            Bulk Minting
                        </div> */}
                </div>
            </>
            {/* )} */}
        </div>
    )
}
