import { createSlice } from "@reduxjs/toolkit"

const customBadgeSlice = createSlice({
    name: "customBadge",
    initialState: {
        showCustomBadgeCreateModal: false,
        showCustomBadgeMintModal: false,
        customBadges: [],
        selectedCustomBadgeForMint: null,
        selectedMemberCustom: [],
    },
    reducers: {
        setShowCustomBadgeCreateModal(state, action) {
            state.showCustomBadgeCreateModal =
                action.payload.showCustomBadgeCreateModal
        },
        setShowCustomBadgeMintModal(state, action) {
            state.showCustomBadgeMintModal =
                action.payload.showCustomBadgeMintModal
        },
        setCustomBadges(state, action) {
            state.customBadges = action.payload.customBadges
        },
        setSelectedCustomBadgeForMint(state, action) {
            state.selectedCustomBadgeForMint =
                action.payload.selectedCustomBadgeForMint
        },
        setSelectedMemberCustomBadge(state, action) {
            state.selectedMemberCustom = action.payload.customBadges
        },
    },
})

export const customBadgeAction = customBadgeSlice.actions

export default customBadgeSlice
