import "./style.scss"

import React from "react"
import { useDispatch, useSelector } from "react-redux"

import check from "../../../../assets/Icons/check.svg"
import upload_file_colored from "../../../../assets/Icons/upload_file_colored.svg"
import { links } from "../../../../constant/links"
import {
    discordMembershipSetup,
    discordOauth,
} from "../../../../store/actions/auth-action"
import {
    removeBotOnEdit,
    removeDiscordBot,
} from "../../../../store/actions/membership-action"
import { getApproverForDao } from "../../../../utils/POCPServiceSdk"
import NextButton from "../../../NextButton"

export default function CreationStep2({
    increaseStep,
    membershipBadges,
    setMembershipBadges,
    membershipLoading,
    totalSteps,
    customBadgeName,
    isEditing,
    type = "custom-badge",
    decreaseStep,
    roleLoading,
}) {
    const checkIsDisabled = () => {
        let isDisabled = false
        membershipBadges.forEach((badge) => {
            if (!badge.name || !badge.image) {
                isDisabled = true
            }
        })
        return isDisabled
    }
    const onFileChange = (e, badgeIndex) => {
        const files = e.target.files || e.dataTransfer.files
        if (!files.length) return
        const copyOfBadges = membershipBadges.map((ele) => ({ ...ele }))
        copyOfBadges[badgeIndex].image = URL.createObjectURL(files[0])
        copyOfBadges[badgeIndex].is_video = false
        if (files[0].type === "video/mp4") {
            copyOfBadges[badgeIndex].is_video = true
        }
        copyOfBadges[badgeIndex].file = files[0]
        setMembershipBadges(copyOfBadges)
    }
    const rolesMapping = useSelector((x) => x.membership.discordMembershipRole)

    const getStepInfo = () => {
        switch (type) {
            case "custom-badge":
                return {
                    title: `${customBadgeName} ${
                        isEditing ? "update" : "creation"
                    }`,
                    heading: "Upload Badge designs",
                }
            case "auto-claim":
                return {
                    title: "Enable Autoclaim",
                    heading: "Select role mapping",
                }
            default:
                return {
                    title: "Enable Autoclaim",
                    heading: "Select role mapping",
                }
        }
    }

    const dispatch = useDispatch()
    const contractAddress = useSelector((x) => x.dao.daoProxyAddress)
    const getContentRightCustomBadge = () => (
        <div className="membership-images-wrapper">
            {membershipBadges.map((badge, index) => (
                <div className="badge-row" key={index}>
                    <div>
                        {badge.image && (
                            <img
                                src={check}
                                className="check-mark"
                                alt="tick"
                            />
                        )}
                        {badge.name}
                        {badge.image && (
                            <span className="badge-reupload-wrapper">
                                {" "}
                                &bull;{" "}
                                <span className="reupload-btn">
                                    <label
                                        htmlFor={`upload-file-input-${index}`}
                                    >
                                        Reupload
                                    </label>
                                </span>
                            </span>
                        )}
                    </div>
                    <div className="badge-image-wrapper">
                        {badge.image ? (
                            badge.is_video ? (
                                <video autoPlay muted loop>
                                    <source src={badge.image} />
                                </video>
                            ) : (
                                <img src={badge.image} alt="" />
                            )
                        ) : (
                            <>
                                <label htmlFor={`upload-file-input-${index}`}>
                                    <img
                                        src={upload_file_colored}
                                        alt="upload"
                                        className="upload-btn"
                                    />
                                </label>
                            </>
                        )}
                        <input
                            type="file"
                            accept="image/png, image/gif, image/jpeg, video/mp4, video/"
                            id={`upload-file-input-${index}`}
                            className="upload-file-input-hidden"
                            onChange={(e) => onFileChange(e, index)}
                        />
                    </div>
                </div>
            ))}
        </div>
    )
    const serverDetail = useSelector((x) => x.auth.selected_server)
    const currentDao = useSelector((x) => x.dao.currentDao)

    const currentServer = serverDetail || currentDao
    const getContentRightAutoClaim = () => (
        <div className="auto-claim-content">
            <div className="auto-claim-wrapper">
                <div className="server-info">
                    <div>
                        <div
                            style={{
                                height: 48,
                                width: 48,
                                background: "gray",
                                borderRadius: 48,
                            }}
                        >
                            {currentServer?.icon && (
                                <img
                                    src={currentServer?.icon}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: 48,
                                    }}
                                />
                            )}
                        </div>
                        <div className="server-name">
                            {currentServer.name?.slice(0, 6)}
                            {currentServer.name.length > 6 && "..."} .{" "}
                        </div>
                        <div className="server-admin">Owner</div>
                    </div>
                    <div
                        onClick={async () => {
                            const approverDao = await getApproverForDao(
                                contractAddress
                            )
                            console.log(
                                "approversssss",
                                approverDao.data.daos[0].approver,
                                approverDao.data.daos[0].approver.includes(
                                    "0xc8B4a82A1cc76C62BeFB883906ec12E2F1b4feB6".toLowerCase()
                                )
                            )
                            if (
                                approverDao.data.daos[0].approver.includes(
                                    "0xc8B4a82A1cc76C62BeFB883906ec12E2F1b4feB6".toLowerCase()
                                )
                            ) {
                                dispatch(discordOauth(true))
                                dispatch(
                                    discordMembershipSetup(
                                        true,
                                        currentDao?.uuid
                                    )
                                )
                                window.location.replace(links.discord_oauth)
                                dispatch(removeBotOnEdit())
                            } else {
                                dispatch(removeDiscordBot(currentServer?.id))
                            }
                            decreaseStep()
                        }}
                        className="change-server-btn"
                    >
                        <div>Change</div>
                    </div>
                </div>
                {rolesMapping.map((x, i) => (
                    <div key={i} className="single-membership">
                        <div className="image">
                            {!x?.is_video ? (
                                <img src={x?.image_url} />
                            ) : (
                                <video>
                                    <source src={x?.image_url} />
                                </video>
                            )}
                        </div>
                        <div className="discord-role">
                            {x.discord_role_name || "Not selected"}
                        </div>
                        <div className="membership-name"> . {x?.name}</div>
                    </div>
                ))}
            </div>
            <div className="msg-box">
                <div>
                    Autoclaim creates a new admin that will approve badges to
                    people with these discord roles, you can remove autoclaim at
                    anytime.
                </div>
            </div>
        </div>
    )

    const getRightContent = () => {
        switch (type) {
            case "custom-badge":
                return getContentRightCustomBadge()
            case "auto-claim":
                return getContentRightAutoClaim()
            default:
                return getContentRightCustomBadge()
        }
    }

    return (
        <div className="membership-creation-step2-container">
            <div className="membership-heading">{getStepInfo().title}</div>
            <div className="membership-content">
                <div className="membership-left">
                    <div className="left-bold">{getStepInfo().heading}</div>
                    <div className="left-greyed">Step 3 of {totalSteps}</div>
                </div>
                <div className="membership-right">
                    {getRightContent()}
                    <div className="next-btn-wrapper">
                        <NextButton
                            text={
                                type === "custom-badge"
                                    ? isEditing
                                        ? "Update badges"
                                        : "Create badges"
                                    : "Sign and Setup Autoclaim"
                            }
                            isDisabled={
                                type === "custom-badge"
                                    ? checkIsDisabled()
                                    : false
                            }
                            nextButtonCallback={increaseStep}
                            isLoading={membershipLoading || roleLoading}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
