import "./style.scss"

import React from "react"
import { useDispatch } from "react-redux"

import CrossSvg from "../../../assets/Icons/cross.svg"
import { assets } from "../../../constant/assets"
import {
    discordDisconnectModalShow,
    getAllMembershipBadgesList,
    pauseDiscordBot,
    removeBotOnEdit,
} from "../../../store/actions/membership-action"
const DiscordDisconnect = () => {
    const dispatch = useDispatch()

    return (
        <div
            onClick={() => dispatch(discordDisconnectModalShow(false))}
            className="discord-disconnect-backdrop"
        >
            <div className="modal">
                <img
                    onClick={() => dispatch(discordDisconnectModalShow(false))}
                    className="cross"
                    src={CrossSvg}
                />
                <div className="modal-title">Disconnect Autoclaim</div>
                <div className="children-div">
                    <div className="temp-disable-div">
                        <img src={assets.icons.backHand} />
                        <div>
                            <div className="title">Temporary disable</div>
                            <div className="description">
                                Bot approval account will be removed but
                                <br /> you can turn it back on whenever you feel
                                like.
                            </div>
                            <div
                                onClick={async () => {
                                    dispatch(pauseDiscordBot())
                                    await dispatch(getAllMembershipBadgesList())
                                    dispatch(discordDisconnectModalShow(false))
                                }}
                                className="btn-div"
                            >
                                <div>Temporary disable autoclaim</div>
                            </div>
                        </div>
                    </div>
                    <div className="per-disable-div">
                        <img src={assets.icons.deleteForever} />
                        <div>
                            <div className="title">Permanent removal</div>
                            <div className="description">
                                Remove bot from your server and from rep3, you
                                <br />
                                can always setup autoclaim from scratch.
                            </div>
                            <div
                                onClick={async () => {
                                    dispatch(removeBotOnEdit())
                                    // await dispatch(
                                    //     getAllDaowithAddress(chain.id)
                                    // )
                                    await dispatch(getAllMembershipBadgesList())
                                    dispatch(discordDisconnectModalShow(false))
                                }}
                                className="btn-div"
                            >
                                <div>Permanently remove autoclaim</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DiscordDisconnect
