import { message } from "antd"

import routes from "../../constant/routes"
import apiClient from "../../utils/api_client"
import { setChainInfoAction } from "../../utils/wagmiHelpers"
import { authActions } from "../reducers/auth-slice"
import { contributorAction } from "../reducers/contributor-slice"

export const authWithSign = (address, signer, chainId) => {
    return async (dispatch) => {
        try {
            const responseNonce = await apiClient.get(
                `${process.env.REACT_APP_DAO_TOOL_URL}${routes.auth.getNonce}?addr=${address}`
            )
            const signature = await signer.signMessage(
                `Signing in to rep3.gg with nonce ${responseNonce.data.data.nonce}`
            )
            try {
                const data = { addr: address, sig: signature }
                const responseSignature = await apiClient.post(
                    `${process.env.REACT_APP_DAO_TOOL_URL}${routes.auth.getSignature}`,
                    data
                )
                if (responseSignature.data.success) {
                    dispatch(
                        authActions.set_signing({
                            jwt: responseSignature.data.data.token,
                        })
                    )
                    setChainInfoAction(chainId)
                    localStorage.setItem(
                        address,
                        JSON.stringify({
                            jwt: responseSignature.data.data.token,
                            time: new Date(),
                        })
                    )
                    return 1
                }
            } catch (error) {
                return 0
            }
        } catch (error) {
            return 0
        }
    }
}

export const setAddress = (address) => {
    return (dispatch) => {
        dispatch(authActions.set_address({ address }))
    }
}

export const setLoggedIn = (status) => {
    return (dispatch) => {
        dispatch(authActions.set_loggedIn({ status }))
    }
}

export const setContriInfo = (name, role) => {
    return (dispatch) => {
        dispatch(authActions.set_contri_setup({ name, role }))
    }
}

export const setAdminStatus = (status) => {
    return (dispatch) => {
        dispatch(authActions.set_admin({ status }))
    }
}

export const signout = () => {
    return (dispatch) => {
        dispatch(authActions.reset_auth())
    }
}

export const discordOauth = (status) => {
    return (dispatch) => {
        dispatch(authActions.set_discord_oauth({ status }))
    }
}

export const discordMembershipSetup = (status, dao_uuid) => {
    console.log("firedd", status, dao_uuid)
    return (dispatch) => {
        dispatch(authActions.set_discord_membership_setup({ status, dao_uuid }))
    }
}

export const discordMembershipEditSetup = (status, edit, dao_uuid) => {
    console.log("firedd", status, edit, dao_uuid)
    return (dispatch) => {
        dispatch(authActions.set_discord_membership_setup({ status, dao_uuid }))
        dispatch(authActions.set_discord_membership_setup_edit({ edit }))
    }
}

export const discordBotSetup = (status) => {
    return (dispatch) => {
        dispatch(authActions.set_discord_bot_setup({ status }))
    }
}

export const joinContributor = (
    id,
    isDiscordActive,
    name,
    discordAccessCode,
    redirect_uri
) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const address = getState().auth.address
        // const role = getState().auth.role

        const data = {
            addr: address,
            name,
            // community_role: "CONTRIBUTOR",
            // discord_user_id: "discordUserId",
        }
        if (isDiscordActive) {
            data.discord_code = discordAccessCode
            data.redirect_uri = redirect_uri
        }

        try {
            const res = await apiClient.post(
                `${process.env.REACT_APP_DAO_TOOL_URL}${routes.dao.joinContributor}/${id}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            if (res.data.success) {
                dispatch(contributorAction.set_discord({ status: false }))
                return 1
            } else {
                message.error("Something went wrong please try again")
                return 0
            }
        } catch (error) {
            if (error?.response?.status === 409) {
                message.error("This discord is already connected")
            } else {
                message.error("Something went wrong please try again")
            }
            return 0
        }
    }
}

export const setContributorDiscordSetup = (contributorDiscordSetup) => {
    return async (dispatch) => {
        dispatch(
            authActions.setContributorDiscordSetup({
                contributorDiscordSetup,
            })
        )
    }
}

export const setContributorDiscordAuth = (status, name, dao_uuid) => {
    return async (dispatch) => {
        dispatch(
            authActions.setContributorDiscordAuth({
                status,
                name,
                dao_uuid,
            })
        )
    }
}
export const setDiscordServer = (server) => {
    return (dispatch) => {
        dispatch(authActions.set_discord_server({ server }))
    }
}

export const setSelectedDiscordServer = (server) => {
    return (dispatch) => {
        dispatch(authActions.set_selected_discord_server({ server }))
    }
}
