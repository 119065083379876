import "./style.scss"

import React from "react"

import BadgeItem from "../../../BadgeItem"
import FormElementSelection from "../../../Form/FieldTypeDisplay"
import NextButton from "../../../NextButton"

export default function ContributionCreationStep2({
    increaseStep,
    schemaTemplate,
}) {
    // const updateSchema = (badgeIndex, newValue) => {
    //     const copyOfBadges = [...schemaTemplate]
    //     copyOfBadges[badgeIndex].name = newValue
    //     setSchemaTemplate(copyOfBadges)
    // }

    // const addSchema = () => {
    //     setSchemaTemplate((membershipBadges) => [
    //         ...membershipBadges,
    //         {
    //             fieldName: "",
    //             fieldType: "",
    //             options: [],
    //         },
    //     ])
    // }

    const checkIsDisabled = () => {
        let isDisabled = false
        schemaTemplate.forEach((x) => {
            if (!x.fieldName) {
                isDisabled = true
            }
        })
        return isDisabled
    }

    const renderContributionBadge = () => <BadgeItem setupDisplay={true} />

    // const renderDesignChangeRequest = () => (
    //     <div className="design-change-div">
    //         <img src={assets.icons.errorIcon} />
    //         <div>Please contact us to change the design for the badge</div>
    //     </div>
    // )

    return (
        <div className="contribution-step2">
            <div className="contribution-heading">Contribution badge setup</div>
            <div className="contribution-content">
                <div className="contribution-left">
                    <div className="left-bold">Review Badge</div>
                    <div className="left-greyed">Step 2 of 2</div>
                    {renderContributionBadge()}
                </div>
                <div className="contribution-right">
                    <div className="schema-overview-div">
                        {schemaTemplate.map((x, i) => (
                            <FormElementSelection item={x} key={i} />
                        ))}
                    </div>
                    <div className="next-btn-wrapper-contri2">
                        <NextButton
                            text="Finish Setup"
                            isContributor={true}
                            isDisabled={checkIsDisabled()}
                            nextButtonCallback={increaseStep}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
