import "./style.scss"

import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
    actionOnBadgesModal,
    actionOnGenerateSchemaModal,
    successConfirmationModal,
} from "../../store/actions/contibutor-action"
import {
    fetchCustomBadges,
    setShowCustomBadgeCreateModal,
    setShowCustomBadgeMintModal,
} from "../../store/actions/custom-badge-action"
import {
    getAllDaoMembers,
    setSelectedNav,
    setShowMembershipChangeModal,
    setShowMembershipCreateModal,
    setShowMembershipMintingModal,
} from "../../store/actions/membership-action"
import GenericMintModal from "../BadgesScreenGeneric/components/MintingModal"
import GenericModal from "../BadgesScreenGeneric/components/Modal"
import DiscordDisconnect from "../Modal/DiscordDisconnect"
import BadgeRequestModal from "../SecondaryBadges/component/BadgeRequestModal"
import ConfirmationBadgesModal from "../SecondaryBadges/component/ConfirmationModal"
import ContributionSchemaModal from "../SecondaryBadges/ContributionBadge/ContributionSchemeModal"
import CommunityScreen from "./components/CommunityScreen"
import HomeScreen from "./components/HomeScreen"
import MembershipChangeModal from "./components/MembershipChangeModal"
import MembershipOverviewModal from "./components/MembershipOverviewModal"
import MintingModal from "./components/MintingModal"
// import EmptyScreen from "./components/EmptyScreen"
import Modal from "./components/Modal"

export default function BadgesScreen() {
    // const [addBtnHover, setAddBtnHover] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [isEditingCustomBadge, setIsEditingCustomBadge] = useState(false)
    const selectedNav = useSelector((x) => x.membership.selectedNav)
    const dispatch = useDispatch()

    const [showMembershipOverviewModal, setShowMembershipOverviewModal] =
        useState(false)
    // const currentDao = useSelector((x) => x.dao.currentDao)
    const contributorSchema = useSelector(
        (x) => x.contributor.contributorSchema
    )
    const contributorRequestModal = useSelector(
        (x) => x.contributor.contributionBadgeModal
    )

    const showMembershipChangeModal = useSelector(
        (x) => x.membership.showMembershipChangeModal
    )

    const showContributorSchemaModal = useSelector(
        (x) => x.contributor.schemaModal
    )

    const showContributorSuccessModal = useSelector(
        (x) => x.contributor.successModal
    )

    const showMembershipCreateModal = useSelector(
        (x) => x.membership.showMembershipCreateModal
    )

    const showMembershipMintingModal = useSelector(
        (x) => x.membership.showMembershipMintingModal
    )

    const membershipBadges = useSelector((x) => x.membership.membershipBadges)

    const closeModal = () => {
        dispatch(setShowMembershipCreateModal(false))
        setIsEditing(false)
    }

    const closeMintingModal = () => {
        dispatch(setShowMembershipMintingModal(false))
    }

    const closeMembershipOverviewModal = () => {
        setShowMembershipOverviewModal(false)
    }

    const allMintModal = useSelector((x) => x.contributor.allMintModal)
    const editMembership = () => {
        setShowMembershipOverviewModal(false)
        setIsEditing(true)
        dispatch(setShowMembershipCreateModal(true))
    }

    const closeMembershipChangeModal = () => {
        dispatch(setShowMembershipChangeModal(false))
    }

    const showCustomBadgeCreateModal = useSelector(
        (x) => x.customBadge.showCustomBadgeCreateModal
    )

    const closeCustomBadgeCreateModal = () => {
        dispatch(setShowCustomBadgeCreateModal(false))
        setIsEditingCustomBadge(false)
    }

    const showCustomBadgeMintModal = useSelector(
        (x) => x.customBadge.showCustomBadgeMintModal
    )

    const closeCustomBadgeMintModal = () => {
        dispatch(setShowCustomBadgeMintModal(false))
    }

    const selectedCustomBadgeForMint = useSelector(
        (x) => x.customBadge.selectedCustomBadgeForMint
    )

    const schemaTemplate = useSelector((x) => x.contributor.contributorSchema)

    const showDiscordSetupModal = useSelector(
        (x) => x.membership.showDiscordSetupModal
    )
    const showDiscordEnablePopup = useSelector(
        (x) => x.membership.showDiscordEnablePopup
    )
    const onDiscordBotSetup = useSelector((x) => x.auth.discordBotSetup)
    useEffect(() => {
        dispatch(fetchCustomBadges())
    }, [])

    const editTrack = useSelector((x) => x.membership.setEditDiscordSetupModal)

    console.log(
        "selected custom badge for mint in index",
        selectedCustomBadgeForMint
    )
    const discordDisconnectModal = useSelector(
        (x) => x.auth.discordDisconnectModal
    )

    return (
        <div className="badges-screen-container">
            <div className="badges-screen-header">
                <div className="header-row">
                    <div className="row-links">
                        <div
                            className={`nav-link ${
                                selectedNav === "badges" && "active-nav-link"
                            }`}
                            onClick={() => dispatch(setSelectedNav("badges"))}
                        >
                            Badges
                        </div>
                        <div
                            className={`nav-link ${
                                selectedNav === "community" && "active-nav-link"
                            }`}
                            onClick={async () => {
                                dispatch(getAllDaoMembers())
                                dispatch(setSelectedNav("community"))
                            }}
                        >
                            Community
                        </div>
                    </div>

                    {/* <div
                    universal payment is here
                        onMouseEnter={() => setAddBtnHover(true)}
                        onMouseLeave={() => setAddBtnHover(false)}
                        className={`add-btn ${addBtnHover ? "" : ""}`}
                    >
                        <img
                            src={addBtnHover ? plus_black : plus_gray}
                            alt="plus"
                        />
                    </div> */}
                </div>
            </div>
            {selectedNav === "badges" ? (
                <HomeScreen
                    membershipBadges={membershipBadges}
                    setShowMembershipOverviewModal={
                        setShowMembershipOverviewModal
                    }
                    setIsEditingCustomBadge={setIsEditingCustomBadge}
                />
            ) : (
                <CommunityScreen />
            )}
            {showMembershipCreateModal && (
                <Modal
                    closeModal={closeModal}
                    membershipBadges={membershipBadges}
                    // setMembershipBadges={setMembershipBadges}
                    isEditing={isEditing}
                />
            )}
            {showMembershipMintingModal && (
                <MintingModal
                    closeMintingModal={closeMintingModal}
                    membershipBadges={membershipBadges}
                />
            )}
            {showMembershipOverviewModal && (
                <MembershipOverviewModal
                    closeMembershipOverviewModal={closeMembershipOverviewModal}
                    membershipBadges={membershipBadges}
                    editMembership={editMembership}
                />
            )}

            {showMembershipChangeModal && (
                <MembershipChangeModal
                    closeMembershipChangeModal={closeMembershipChangeModal}
                    membershipBadges={membershipBadges}
                />
            )}
            {showContributorSchemaModal && (
                <ContributionSchemaModal
                    closeModal={() =>
                        dispatch(actionOnGenerateSchemaModal(false))
                    }
                />
            )}
            {showContributorSuccessModal && (
                <ConfirmationBadgesModal
                    badgeSchema={contributorSchema}
                    type={"Contribution"}
                    closeModal={() => dispatch(successConfirmationModal(false))}
                />
            )}
            {showDiscordEnablePopup && (
                <ConfirmationBadgesModal
                    badgeSchema={contributorSchema}
                    type={"Membership"}
                    closeModal={() => dispatch(successConfirmationModal(false))}
                />
            )}
            {contributorRequestModal && (
                <BadgeRequestModal
                    badgeSchema={schemaTemplate}
                    type="Contribution"
                />
            )}
            {allMintModal && (
                <ContributionSchemaModal
                    overviewModal={true}
                    closeModal={() => dispatch(actionOnBadgesModal(false))}
                />
            )}
            {showCustomBadgeCreateModal && (
                <GenericModal
                    closeModal={closeCustomBadgeCreateModal}
                    membershipBadges={selectedCustomBadgeForMint?.badge_types}
                    isEditing={isEditingCustomBadge}
                    startingCreationStep={1}
                    totalSteps={3}
                    selectedCustomBadge={selectedCustomBadgeForMint}
                />
            )}
            {showDiscordSetupModal && (
                <GenericModal
                    type="auto-claim"
                    closeModal={closeCustomBadgeCreateModal}
                    membershipBadges={selectedCustomBadgeForMint?.badge_types}
                    isEditing={isEditingCustomBadge}
                    startingCreationStep={
                        onDiscordBotSetup || editTrack ? 2 : 1
                    }
                    totalSteps={3}
                    selectedCustomBadge={selectedCustomBadgeForMint}
                />
            )}

            {showCustomBadgeMintModal && (
                <GenericMintModal
                    closeMintingModal={closeCustomBadgeMintModal}
                    selectedCustomBadgeForMint={selectedCustomBadgeForMint}
                    membershipBadges={selectedCustomBadgeForMint?.badge_types}
                />
            )}
            {discordDisconnectModal && <DiscordDisconnect />}
        </div>
    )
}
