import "./style.scss"

import SafeServiceClient from "@gnosis.pm/safe-service-client"
import { Typography } from "antd"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useAccount, useEnsName, useNetwork } from "wagmi"

import arrow_drop_down_orange from "../../../../assets/Icons/arrow_drop_down_orange.svg"
import arrow_up_orange from "../../../../assets/Icons/arrow_up_orange.svg"
import CheckSvg from "../../../../assets/Icons/check.svg"
import check_green from "../../../../assets/Icons/check_green.svg"
import ContributionBadgeBg from "../../../../assets/Icons/ContributionBadgeBg.png"
import etherscanIcon from "../../../../assets/Icons/etherscanIcon.svg"
import openseaIcon from "../../../../assets/Icons/openseaIcon.svg"
import twitterIcon from "../../../../assets/Icons/twitter-icon.svg"
import waiting_orange from "../../../../assets/Icons/waiting_orange.svg"
import { assets } from "../../../../constant/assets"
import { getDataFromApi } from "../../../../store/actions/custom-badge-action"
import { chainType } from "../../../../utils/chainType"
import { convertToHttps } from "../../../../utils/conversion"
import { getSafeServiceUrl } from "../../../../utils/multiGnosisUrl"
import { getBadgeOnMetadata } from "../../../../utils/POCPServiceSdk"

export default function ContributionContributorSideCard() {
    const currentDao = useSelector((x) => x.dao.currentDao)
    const { address } = useAccount()

    const contributorSelectionContribution = useSelector(
        (x) => x.contributor.contributorSelectionContribution
    )
    console.log("current contri", contributorSelectionContribution)
    const [showMore, setShowMore] = useState(false)
    const [imageUri, setImageUri] = useState("")

    let totalAmountInUsd = 0
    contributorSelectionContribution?.tokens?.forEach((token) => {
        totalAmountInUsd = totalAmountInUsd + token?.usd_amount * token?.amount
    })
    const { chain } = useNetwork()

    const serviceClient = new SafeServiceClient(getSafeServiceUrl(chain?.id))

    const [signersInfo, setSignersInfo] = useState(null)
    const [badgeInfo, setBadgeInfo] = useState(null)
    const safeInfo = useSelector((x) => x.dao.safeInfo)
    const dispatch = useDispatch()

    const getPayoutInfo = async () => {
        if (contributorSelectionContribution?.gnosis_reference_id) {
            const tx = await serviceClient.getTransaction(
                contributorSelectionContribution?.gnosis_reference_id
            )
            setSignersInfo({
                ...tx,
            })
        }
    }

    const getBadgeInfo = async () => {
        if (contributorSelectionContribution?.badge_status === "CLAIMED") {
            const res = await getBadgeOnMetadata(
                `http://arweave.net/${contributorSelectionContribution?.metadata_hash}`,
                currentDao?.uuid
            )

            setBadgeInfo(res.data.associationBadges[0])
        }
    }

    const [isToggleOpen, setIsToggleOpen] = useState(false)

    const toggle = () => {
        setIsToggleOpen((isToggleOpen) => !isToggleOpen)
    }
    const currentUser = useSelector((x) => x.dao.username)
    const { data: ensName } = useEnsName({
        address,
    })

    const getSignerName = (address) => {
        const details = currentDao?.approvers?.filter(
            (ele) => ele.addr === address
        )
        return details?.[0]?.name
    }

    const openEtherscan = () => {
        window.open(
            `https://${
                chainType(chain?.id) === "Testnet" ? "mumbai." : ""
            }polygonscan.com/token/${badgeInfo?.contractAddress?.id}?a=${
                badgeInfo?.tokenID
            }`,
            "_blank"
        )
    }

    const openOpensea = () => {
        window.open(
            `https://${
                chainType(chain?.id) === "Testnet" ? "testnets." : ""
            }opensea.io/assets/${
                chainType(chain?.id) === "Testnet" ? "mumbai" : "matic"
            }/${badgeInfo?.contractAddress?.id}/${badgeInfo?.tokenID}`,
            "_blank"
        )
    }

    useEffect(() => {
        getPayoutInfo()
    }, [contributorSelectionContribution])
    useEffect(() => {
        if (contributorSelectionContribution?.gnosis_reference_id) {
            getPayoutInfo()
        } else {
            setSignersInfo(null)
        }
        if (contributorSelectionContribution?.badge_status === "CLAIMED") {
            getBadgeInfo()
        } else {
            setBadgeInfo(null)
        }
    }, [contributorSelectionContribution])

    useEffect(async () => {
        if (
            contributorSelectionContribution?.metadata_hash &&
            !contributorSelectionContribution?.badge_type
        ) {
            const res = await dispatch(
                getDataFromApi(
                    `http://arweave.net/${contributorSelectionContribution?.metadata_hash}`
                )
            )
            console.log("askxznclkxcz", res?.data)
            if (res?.data) {
                setImageUri(convertToHttps(res?.data?.data?.image))
            }
        }
    }, [contributorSelectionContribution, contributorSelectionContribution])

    // if(contributorBadge){

    console.log(
        "contributor selection contribution",
        contributorSelectionContribution
    )

    const contributionCategory =
        contributorSelectionContribution?.details?.find(
            (x) => x.fieldName === "Contribution Category"
        )?.value
    return (
        <div className="contributor-contribution-side-card-container">
            {!contributorSelectionContribution?.badge_type && (
                <div className="contri-title">
                    {
                        contributorSelectionContribution?.details?.find(
                            (x) => x.fieldName === "Contribution Title"
                        )?.value
                    }
                </div>
            )}
            {contributorSelectionContribution?.badge_type && (
                <div style={{ marginBottom: 0 }} className="contri-title">
                    {contributorSelectionContribution?.badge_collection?.type}
                </div>
            )}
            {contributorSelectionContribution?.badge_type && (
                <div style={{ opacity: 0.5 }} className="contri-title">
                    {contributorSelectionContribution?.badge_type?.name}
                </div>
            )}
            {!contributorSelectionContribution?.badge_type && (
                <div className="contri-badge-wrapper">
                    {contributorSelectionContribution?.contributionType ===
                    "pending" ? (
                        <div className="contri-badge">
                            <img
                                src={ContributionBadgeBg}
                                alt=""
                                className="contri-badge-bg"
                            />
                            <div className="contri-badge-dao">
                                <img src={currentDao?.logo_url} alt="" />
                                {currentDao?.name}
                            </div>
                            <div className="contri-badge-contribution-info">
                                <div className="contri-badge-title">
                                    {
                                        contributorSelectionContribution?.details?.find(
                                            (x) =>
                                                x.fieldName ===
                                                "Contribution Title"
                                        )?.value
                                    }
                                </div>
                                <div className="contri-badge-bottom-row">
                                    <div>
                                        {contributionCategory
                                            ? `${contributionCategory} • `
                                            : ""}
                                        {
                                            contributorSelectionContribution?.details?.find(
                                                (x) =>
                                                    x.fieldName ===
                                                    "Time Spent in Hours"
                                            )?.value
                                        }
                                        hrs
                                    </div>
                                    <div>
                                        {dayjs(
                                            contributorSelectionContribution?.created_at
                                        ).format("DD MMM' YY")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <img
                            src={imageUri}
                            alt=""
                            className="contri-badge-image"
                        />
                    )}
                    {contributorSelectionContribution?.feedback && (
                        <div className="contri-feedback">
                            {contributorSelectionContribution?.feedback}
                        </div>
                    )}
                    {contributorSelectionContribution?.badge_status ===
                        "CLAIMED" && (
                        <>
                            <div className="lineBreak"></div>
                            <div className="badge-footer">
                                <div className="footer-icons">
                                    <div
                                        className="badge-footer-icon"
                                        onClick={openOpensea}
                                    >
                                        <img src={openseaIcon} alt="" />
                                    </div>
                                    <div
                                        className="badge-footer-icon"
                                        onClick={openEtherscan}
                                    >
                                        <img src={etherscanIcon} alt="" />
                                    </div>
                                </div>
                                <div>
                                    <button>
                                        Share <img src={twitterIcon} alt="" />
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
            {contributorSelectionContribution?.tokens?.length && (
                <div className="contri-payout-info">
                    <div>
                        <span className="highlighted">
                            {totalAmountInUsd.toFixed(2)}$
                        </span>{" "}
                        Total Payout
                    </div>
                    {contributorSelectionContribution?.tokens
                        ?.slice(0, 2)
                        .map((token, index) => (
                            <div className="token-payout-row" key={index}>
                                <div className="highlighted">
                                    {token?.amount} {token?.details?.symbol}
                                </div>
                                <div>
                                    {(
                                        token?.usd_amount * token?.amount
                                    ).toFixed(2)}
                                    $
                                </div>
                            </div>
                        ))}
                    {contributorSelectionContribution?.tokens?.length > 2 && (
                        <div>
                            {showMore ? (
                                <>
                                    {contributorSelectionContribution?.tokens
                                        ?.slice(2)
                                        .map((token, index) => (
                                            <div
                                                className="token-payout-row"
                                                key={index}
                                            >
                                                <div className="highlighted">
                                                    {token?.amount}{" "}
                                                    {token?.details?.symbol}
                                                </div>
                                                <div>
                                                    {(
                                                        token?.usd_amount *
                                                        token?.amount
                                                    ).toFixed(2)}
                                                    $
                                                </div>
                                            </div>
                                        ))}
                                    <div
                                        onClick={() => setShowMore(false)}
                                        className="show-more-or-less"
                                    >
                                        Show less
                                    </div>
                                </>
                            ) : (
                                <div
                                    onClick={() => setShowMore(true)}
                                    className="show-more-or-less"
                                >
                                    Show More
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}

            {!contributorSelectionContribution?.badge_type && (
                <div className="contri-info">
                    <div className="contributor-info">
                        {currentUser} •{" "}
                        {ensName ||
                            `${address?.slice(0, 5)}...${address?.slice(-3)}`}
                    </div>
                    <div className="contri-type">
                        {contributionCategory
                            ? `${contributionCategory} • `
                            : ""}
                        {
                            contributorSelectionContribution?.details?.find(
                                (x) => x.fieldName === "Time Spent in Hours"
                            )?.value
                        }
                        hrs
                    </div>

                    <Typography.Paragraph
                        className={"contri-description"}
                        ellipsis={{
                            rows: 2,
                            expandable: true,
                            symbol: (
                                <div className={"contri-description-more"}>
                                    read more
                                </div>
                            ),
                        }}
                    >
                        {
                            contributorSelectionContribution?.details?.find(
                                (x) => x.fieldName === "Additional Notes"
                            )?.value
                        }
                    </Typography.Paragraph>
                </div>
            )}
            {contributorSelectionContribution?.badge_type &&
                (!contributorSelectionContribution?.badge_type?.is_video ? (
                    <img
                        style={{ width: "100%", height: "320px" }}
                        src={
                            contributorSelectionContribution?.badge_type?.image
                        }
                    />
                ) : (
                    <video
                        style={{ width: "100%", height: "320px" }}
                        autoPlay
                        loop
                        muted
                    >
                        <source
                            src={
                                contributorSelectionContribution?.badge_type
                                    ?.image
                            }
                        />
                    </video>
                ))}
            {signersInfo && (
                <div className="badge-sign-collapsable">
                    {signersInfo && signersInfo?.isExecuted ? (
                        <div
                            className={`closed-div green-color`}
                            onClick={toggle}
                        >
                            <div className="title">
                                <img src={check_green} alt="" />
                                Signed
                            </div>
                            <img src={assets.icons.downWhite} />
                        </div>
                    ) : (
                        <>
                            <div
                                className={`closed-div orange-color`}
                                onClick={toggle}
                            >
                                <div className="title">
                                    <img src={waiting_orange} alt="" />
                                    {/* Waiting for signing */}
                                    {!contributorSelectionContribution?.tokens
                                        ?.length &&
                                    !contributorSelectionContribution?.voucher_id
                                        ? "waiting for approval"
                                        : signersInfo?.confirmations?.length >=
                                          safeInfo?.threshold
                                        ? "waiting for execution"
                                        : `waiting for signing • ${signersInfo?.confirmations?.length}/${safeInfo?.threshold}`}
                                </div>
                                <img
                                    src={
                                        isToggleOpen
                                            ? arrow_up_orange
                                            : arrow_drop_down_orange
                                    }
                                />
                            </div>
                            <div
                                className={`${
                                    isToggleOpen ? "signers-info-shown " : ""
                                } signers-info`}
                            >
                                {signersInfo?.confirmations?.length >=
                                safeInfo?.threshold ? (
                                    <>
                                        <div>Signing Done</div>
                                        <div className="signer-time">
                                            {dayjs(
                                                signersInfo?.modified
                                            ).fromNow()}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {signersInfo?.confirmations?.map(
                                            (signer, index) => (
                                                <div
                                                    className="signer-row"
                                                    key={index}
                                                >
                                                    <div>
                                                        {getSignerName(address)}{" "}
                                                        •{" "}
                                                        {`${address?.slice(
                                                            0,
                                                            5
                                                        )}...${address?.slice(
                                                            -3
                                                        )}`}
                                                    </div>
                                                    <img
                                                        src={CheckSvg}
                                                        alt=""
                                                    />
                                                </div>
                                            )
                                        )}
                                    </>
                                )}
                                <div>|</div>
                                <div>Request Approved</div>
                                <div className="signer-time">
                                    {dayjs(
                                        signersInfo?.submissionDate
                                    ).fromNow()}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    )
}
// }
