import "./style.scss"

import React from "react"
import { useDispatch, useSelector } from "react-redux"

import arrow_forward from "../../../../assets/Icons/arrow_forward.svg"
import {
    discordBotSetup,
    setSelectedDiscordServer,
} from "../../../../store/actions/auth-action"
import IconBox from "../../../IconBox"
import NextButton from "../../../NextButton"

export default function CreationStep0({
    increaseStep,
    totalSteps,
    customBadgeName,
    setCustomBadgeName,
    localBadgeIcon,
    setLocalBadgeIcon,
    type = "custom-badge",
}) {
    const checkIsDisabled = () => {
        let isDisabled = false
        if (!customBadgeName) {
            isDisabled = true
        }
        return isDisabled
    }

    const dispatch = useDispatch()
    // const onDiscordBotSetup = useSelector((x) => x.auth.discordBotSetup)

    // if (onDiscordBotSetup) {
    //     dispatch(discordBotSetup(false))
    // }

    const getStepInfo = () => {
        switch (type) {
            case "custom-badge":
                return {
                    title: "Create new badge type",
                    heading: "Badge type name",
                }
            case "auto-claim":
                return {
                    title: "Enable Autoclaim",
                    heading: "Select Discord Server",
                }
            default:
                return {
                    title: "Enable Autoclaim",
                    heading: "Select Discord Server",
                }
        }
    }

    const getContentRightCustomBadge = () => (
        <div
            // key={index}
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
            }}
        >
            <IconBox
                getSelected={(i) => setLocalBadgeIcon(i)}
                localBadgeIcon={localBadgeIcon}
            />
            <input
                type="text"
                value={customBadgeName}
                onChange={(e) => setCustomBadgeName(e.target.value)}
                placeholder="Badge type name"
            />
        </div>
    )

    const discordGuildOauth = (item) => {
        dispatch(discordBotSetup(true))
        dispatch(setSelectedDiscordServer(item))
        window.location.replace(
            `https://discord.com/api/oauth2/authorize?client_id=${
                process.env.NODE_ENV === "production"
                    ? "976409482533961769"
                    : "976409482533961769"
            }&redirect_uri=${encodeURIComponent(
                `${window.location.origin}/discord/fallback`
            )}&permissions=19456&scope=bot applications.commands&guild_id=${
                item.id
            }&disable_guild_select=true&response_type=code`
        )
    }

    const servers = useSelector((x) => x.auth.discord_server)

    const getContentRightAutoClaim = () => (
        <div className="auto-claim-content">
            {servers.map((x, i) => (
                <div
                    style={{
                        borderBottom: servers.length - 1 === i && 0,
                    }}
                    className="server-item"
                    key={i}
                >
                    <div className="server-info">
                        <div
                            style={{
                                height: 48,
                                width: 48,
                                background: "gray",
                                borderRadius: 48,
                            }}
                        >
                            {x.icon && (
                                <img
                                    src={x?.icon}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "48px",
                                    }}
                                />
                            )}
                        </div>
                        <div>
                            <div className="server-name">
                                {x.name?.slice(0, 14)}
                                {x.name.length > 15 && "..."}
                            </div>
                            <div className="server-admin">Owner</div>
                        </div>
                    </div>
                    <div
                        onClick={() => discordGuildOauth(x)}
                        className="forward-btn"
                    >
                        <img src={arrow_forward} />
                    </div>
                </div>
            ))}
        </div>
    )

    const getRightContent = () => {
        switch (type) {
            case "custom-badge":
                return getContentRightCustomBadge()
            case "auto-claim":
                return getContentRightAutoClaim()
        }
    }

    return (
        <div className="membership-step0">
            <div className="membership-heading">{getStepInfo().title}</div>
            <div className="membership-content">
                <div className="membership-left">
                    <div className="left-bold">{getStepInfo().heading}</div>
                    <div className="left-greyed">Step 1 of {totalSteps}</div>
                </div>
                <div className="membership-right">
                    {getRightContent()}
                    {type !== "auto-claim" && (
                        <div className="next-btn-wrapper">
                            <NextButton
                                text="Badge names"
                                isDisabled={checkIsDisabled()}
                                nextButtonCallback={increaseStep}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
