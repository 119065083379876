import routes from "../../constant/routes"
import apiClient from "../../utils/api_client"
import {
    createContributionVoucher,
    getArrayOfMemberToken,
    getArrayOfNounce,
} from "../../utils/POCPServiceSdk"
import { customBadgeAction } from "../reducers/custom-badge-slice"
import { toastAction } from "../reducers/toast-slice"

const showDefaultToastError = () => {
    return (dispatch) => {
        dispatch(
            toastAction.setToastInfo({
                toastInfo: {
                    content: "Something went wrong please try again",
                    toastType: "error",
                },
            })
        )
        dispatch(toastAction.setShowToast({ status: true }))
    }
}

// ------------------------ Custom Badge Actions ------------------------

export const setShowCustomBadgeCreateModal = (showCustomBadgeCreateModal) => {
    return (dispatch) => {
        dispatch(
            customBadgeAction.setShowCustomBadgeCreateModal({
                showCustomBadgeCreateModal,
            })
        )
    }
}

export const setShowCustomBadgeMintModal = (showCustomBadgeMintModal) => {
    return (dispatch) => {
        dispatch(
            customBadgeAction.setShowCustomBadgeMintModal({
                showCustomBadgeMintModal,
            })
        )
    }
}

export const createCustomBadge = (
    formData,
    memberships,
    isEditing,
    badgeName,
    badgeIconIndex,
    customBadgeUuid = null
) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const uuid = getState().dao.currentDao?.uuid

        try {
            const response = await apiClient.post(
                // `${process.env.REACT_APP_ARWEAVE_SERVER}${routes.arweave.membership}`,
                `https://test-staging.api.drepute.xyz/arweave_server/custom-badge`,
                formData
            )
            if (response?.data?.success) {
                const arweaveHashArray = response.data?.data
                let arweaveArrayIndex = 0

                const membershipsUpdated = memberships.map((membership) => {
                    const image = membership?.file
                        ? arweaveHashArray[arweaveArrayIndex].media
                        : membership?.image

                    const metadata_hash = membership?.file
                        ? arweaveHashArray[arweaveArrayIndex].metadata
                        : membership?.metadata_hash

                    if (membership?.file) {
                        arweaveArrayIndex = arweaveArrayIndex + 1
                    }

                    const returnObj = {
                        image,
                        metadata_hash,
                        is_video: membership?.is_video,
                        name: membership.name,
                    }

                    if (isEditing && membership.uuid) {
                        returnObj.uuid = membership.uuid
                    }

                    return returnObj
                })

                let res
                if (isEditing) {
                    const oldMemberships = membershipsUpdated.filter(
                        (ele) => ele.uuid
                    )
                    const newMemberships = membershipsUpdated.filter(
                        (ele) => !ele.uuid
                    )
                    res = await apiClient.put(
                        `${process.env.REACT_APP_DAO_TOOL_URL}${routes.customBadge.updateBadge}`,
                        {
                            dao_uuid: uuid,
                            type: badgeName,
                            icon_type: badgeIconIndex,
                            badge_types: oldMemberships,
                            collection_uuid: customBadgeUuid,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${jwt}`,
                            },
                        }
                    )
                    if (newMemberships?.length) {
                        res = await apiClient.post(
                            `${process.env.REACT_APP_DAO_TOOL_URL}${routes.customBadge.createBadge}`,
                            {
                                dao_uuid: uuid,
                                type: badgeName,
                                icon_type: badgeIconIndex,
                                badge_types: newMemberships,
                                collection_uuid: customBadgeUuid,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${jwt}`,
                                },
                            }
                        )
                    }
                } else {
                    res = await apiClient.post(
                        `${process.env.REACT_APP_DAO_TOOL_URL}${routes.customBadge.createBadge}`,
                        {
                            dao_uuid: uuid,
                            type: badgeName,
                            icon_type: badgeIconIndex,
                            badge_types: membershipsUpdated,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${jwt}`,
                            },
                        }
                    )
                }
                if (res.data.success) {
                    dispatch(
                        toastAction.setToastInfo({
                            toastInfo: {
                                content: isEditing
                                    ? "Updated successfully"
                                    : "Created successfully",
                                toastType: "success",
                            },
                        })
                    )
                    dispatch(toastAction.setShowToast({ status: true }))
                    dispatch(fetchCustomBadges())
                    dispatch(setShowCustomBadgeCreateModal(false))
                }
            }
        } catch (err) {
            console.error(err)
            dispatch(showDefaultToastError())
        }
    }
}

export const fetchCustomBadges = () => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const uuid = getState().dao.currentDao?.uuid

        console.log("calling fetch custom badge")

        try {
            const res = await apiClient.get(
                `${process.env.REACT_APP_DAO_TOOL_URL}${routes.customBadge.getCustomBadges}?dao_uuid=${uuid}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            if (res.data?.success) {
                if (res.data?.data?.data) {
                    dispatch(
                        customBadgeAction.setCustomBadges({
                            customBadges: res.data.data.data,
                        })
                    )
                    return 1
                }
            }
            return 0
        } catch (error) {
            console.error("err", error)
            return 0
        }
    }
}

export const setSelectedCustomBadgeForMint = (selectedCustomBadgeForMint) => {
    return async (dispatch) => {
        dispatch(
            customBadgeAction.setSelectedCustomBadgeForMint({
                selectedCustomBadgeForMint,
            })
        )
    }
}

export const createCustomBadgeVoucher = (
    selectedCustomBadgeLevel,
    addresses,
    token_type
) => {
    return async (dispatch, getState) => {
        const jwt = getState().auth.jwt
        const uuid = getState().dao.currentDao?.uuid
        const proxyContract = getState().dao.daoProxyAddress

        console.log(
            "selected custom badge level",
            selectedCustomBadgeLevel,
            addresses,
            token_type
        )

        const arrayOfToken = await getArrayOfMemberToken(
            addresses.map((x) => x.value),
            proxyContract
        )

        const arrayOfNounce = await getArrayOfNounce(arrayOfToken, uuid, jwt)

        try {
            const signedVoucher = await createContributionVoucher(
                proxyContract,
                arrayOfToken,
                Array(addresses.length).fill(token_type),
                new Array(addresses.length).fill(
                    selectedCustomBadgeLevel.metadata_hash
                ),
                arrayOfNounce,
                new Array(addresses.length).fill(0)
            )

            await apiClient.post(
                `${process.env.REACT_APP_DAO_TOOL_URL}${routes.customBadge.createBadgeVoucher}`,
                {
                    dao_uuid: uuid,
                    signed_voucher: signedVoucher,
                    metadata_hash: selectedCustomBadgeLevel.metadata_hash,
                },
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )

            dispatch(setShowCustomBadgeMintModal(false))
        } catch (error) {
            console.error("error", error)
        }
    }
}

export const resetEverythingRelatedToCustomBadgeLocally = () => {
    return async (dispatch) => {
        dispatch(customBadgeAction.resetEverything({}))
    }
}

export const getDataFromApi = (apiEndPoint) => {
    return async (dispatch, getState) => {
        console.log("current sending request", apiEndPoint)
        const jwt = getState().auth.jwt

        const res = await apiClient.post(
            `${process.env.REACT_APP_DAO_TOOL_URL}${routes.pocp.collectibleInfo}`,
            {
                endpoint: apiEndPoint,
            },
            {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            }
        )
        console.log("current data in action is", res)
        return res
    }
}
