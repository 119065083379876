import "./style.scss"

import { message } from "antd"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import autoAwesome from "../../assets/Icons/auto_awesome.svg"
import cancelPresentation from "../../assets/Icons/cancel_presentation.png"
import openLink from "../../assets/Icons/open_link.png"
import { assets } from "../../constant/assets"
import {
    discordMembershipSetup,
    discordOauth,
} from "../../store/actions/auth-action"
import {
    discordDisconnectModalShow,
    getDiscordRoleOnGuild,
    setDiscordAutoClaimSetupEditModal,
} from "../../store/actions/membership-action"

export default function AutoClaimBanner({ isEnabled, setOpen }) {
    const currentDao = useSelector((x) => x.dao.currentDao)
    const [collapsableOpen, setCollapsableOpen] = useState(false)
    const dispatch = useDispatch()

    const onDiscordAuth = () => {
        dispatch(discordOauth(true))
        dispatch(discordMembershipSetup(true, currentDao?.uuid))
        window.location.replace(
            `https://discord.com/api/oauth2/authorize?client_id=${
                process.env.NODE_ENV === "production"
                    ? "976409482533961769"
                    : "976409482533961769"
            }&redirect_uri=${encodeURIComponent(
                `${window.location.origin}/discord/fallback`
            )}&response_type=code&scope=identify%20guilds%20guilds.members.read`
        )
    }

    const renderAcitvateBanner = () => (
        <div className="auto-claim-banner-activate-container">
            <div className="auto-claim-msg">
                <img
                    src={autoAwesome}
                    style={{ height: 24, width: 24, marginRight: "1rem" }}
                />
                <div>Auto claim</div>
                <div className="lighter-text">
                    will allow people with a certain discord role to
                    automatically claim a badge.
                </div>
            </div>
            <div onClick={() => onDiscordAuth()} className="enable-btn">
                <div className="btn-title">Enable Autoclaim</div>
            </div>
        </div>
    )
    const serverDetail = useSelector((x) => x.auth.selected_server)

    const membershipBadges = useSelector((x) => x.membership.membershipBadges)
    const renderAutoClaimInfo = () => (
        <div className="auto-claim-info-activated-container">
            <div className="left-content">
                <div className="server-info">
                    {currentDao?.discord?.guild_icon_url ||
                    serverDetail?.icon ? (
                        <img
                            src={
                                currentDao?.discord?.guild_icon_url ||
                                serverDetail?.icon
                            }
                            className="image"
                        />
                    ) : (
                        <div className="image"></div>
                    )}
                    <div className="server-name">
                        {currentDao?.discord?.guild_name || serverDetail?.name}
                    </div>
                </div>
                <div className="open-discord">
                    <img src={openLink} className="icon" />
                    <div className="link">Open Discord Server</div>
                </div>
                <div
                    onClick={() => {
                        dispatch(discordDisconnectModalShow(true))
                        setCollapsableOpen(false)
                    }}
                    className="disconnect-discord"
                >
                    <img src={cancelPresentation} className="icon" />
                    <div className="link">Disconnect Autoclaim</div>
                </div>
            </div>

            <div className="right-content">
                {membershipBadges.map(
                    (x) =>
                        x?.discord_role_name && (
                            <div key={x} className="single-item">
                                <div className="info-div">
                                    <img src={x?.image_url} className="image" />
                                    <div className="membership-name">
                                        {x?.name}
                                    </div>
                                    <div className="discord-role">
                                        {x.discord_role_name}
                                    </div>
                                </div>
                            </div>
                        )
                )}
                <div
                    onClick={async () => {
                        await dispatch(
                            getDiscordRoleOnGuild(currentDao?.discord?.guild_id)
                        )
                        dispatch(setDiscordAutoClaimSetupEditModal(true, true))
                    }}
                    className="edit-btn"
                >
                    Edit Setup
                </div>
            </div>
        </div>
    )
    const discordSetup = useSelector((x) => x.membership.discordSetup)
    async function copyTextToClipboard() {
        if ("clipboard" in navigator) {
            message.success("invite link copied successfully!")
            return await navigator.clipboard.writeText(
                encodeURI(
                    `${
                        window.location.origin
                    }/contributor/invite/${currentDao?.name.toLowerCase()}/${
                        currentDao?.uuid
                    }`
                )
            )
        } else {
            return document.execCommand(
                "copy",
                true,
                encodeURI(
                    `${
                        window.location.origin
                    }/contributor/invite/${currentDao?.name.toLowerCase()}/${
                        currentDao?.uuid
                    }`
                )
            )
        }
    }
    const renderActivatedBanner = () => (
        <div
            style={{ background: collapsableOpen && "#333333" }}
            className="activated-banner-wrrapper"
        >
            <div
                style={{ background: collapsableOpen && "#333333" }}
                className="auto-claim-banner-activated-container"
            >
                <div
                    onClick={() => {
                        setOpen(!collapsableOpen)
                        setCollapsableOpen(!collapsableOpen)
                    }}
                    className="auto-claim-msg-toggle"
                >
                    <img
                        src={autoAwesome}
                        style={{ height: 24, width: 24, marginRight: "1rem" }}
                    />
                    <div className="enabled-title">Auto claim Enabled</div>
                    <div className="toggle-btn">
                        <img
                            src={
                                collapsableOpen
                                    ? assets.icons.arrowUp
                                    : assets.icons.arrowDown
                            }
                        />
                    </div>
                </div>
                <div className="info-div">
                    <div className="lighter-text-numbers">
                        enabled on {discordSetup} badges •
                    </div>
                    <div
                        onClick={() => copyTextToClipboard()}
                        className="invite-link"
                    >
                        Copy Invite Link
                    </div>
                </div>
            </div>
            {collapsableOpen && renderAutoClaimInfo()}
        </div>
    )

    return (
        <div className="auto-claim-banner-container">
            {!isEnabled ? renderAcitvateBanner() : renderActivatedBanner()}
        </div>
    )
}
