import "./index.css"
import "./assets/fonts/PPTelegraf-Medium.otf"
import "@rainbow-me/rainbowkit/dist/index.css"

import {
    connectorsForWallets,
    RainbowKitProvider,
} from "@rainbow-me/rainbowkit"
import {
    braveWallet,
    coinbaseWallet,
    injectedWallet,
    metaMaskWallet,
    rainbowWallet,
} from "@rainbow-me/rainbowkit/wallets"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { PersistGate } from "redux-persist/integration/react"
import { configureChains, createClient, WagmiConfig } from "wagmi"
import { jsonRpcProvider } from "wagmi/providers/jsonRpc"

import App from "./App"
import store, { persistor } from "./store/index"

const zkSyncChain = {
    id: 280,
    name: "zkSync alpha testnet",
    network: "ZkSync",
    iconUrl: "https://example.com/icon.svg",
    iconBackground: "#fff",
    nativeCurrency: {
        decimals: 18,
        name: "Ethereum",
        symbol: "ETH",
    },
    rpcUrls: {
        default: "https://zksync2-testnet.zksync.dev",
    },
    blockExplorers: {
        default: { name: "SnowTrace", url: "https://snowtrace.io" },
        etherscan: { name: "SnowTrace", url: "https://snowtrace.io" },
    },
    testnet: true,
}

Sentry.init({
    dsn:
        process.env.NODE_ENV === "production"
            ? "https://b2ab3c3f69b549acba65626193c3ad7b@o4503902930272256.ingest.sentry.io/4503902950129664"
            : "https://c89ed0fa080a4c9f950cdab8dd811126@o1430459.ingest.sentry.io/4503891718242304",
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    tracesSampleRate: 1.0,
    normalizeDepth: 10,
    environment: process.env.NODE_ENV,
})

const { chains, provider, webSocketProvider } = configureChains(
    [zkSyncChain],
    [jsonRpcProvider({ rpc: (chain) => ({ http: chain.rpcUrls.default }) })]
)

const connectors = connectorsForWallets([
    {
        groupName: "Popular",
        wallets: [
            injectedWallet({ chains }),
            metaMaskWallet({ chains }),
            rainbowWallet({ chains }),
            coinbaseWallet({ chains }),
            braveWallet({ chains }),
        ],
    },
])

const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
    webSocketProvider,
})

// if (process.env.NODE_ENV === "production") {
//     console.log = () => {}
//     // console.error = () => {}
//     console.debug = () => {}
// }

ReactDOM.render(
    <React.StrictMode>
        <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider modalSize="compact" chains={chains}>
                <BrowserRouter>
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <App />
                        </PersistGate>
                    </Provider>
                </BrowserRouter>
            </RainbowKitProvider>
        </WagmiConfig>
    </React.StrictMode>,
    document.getElementById("root")
)
