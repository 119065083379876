import React from "react"
import { useSelector } from "react-redux"
import { useNetwork } from "wagmi"

import WalletPicker from "../../components/WalletPicker"
import { chainType } from "../../utils/chainType"
import styles from "./style.module.css"

export default function Header({
    signer,
    contributorWallet,
    onWalletCenterOpen,
    walletCenter,
    showWalletPicker = true,
}) {
    const jwt = useSelector((x) => x.auth.jwt)
    const role = useSelector((x) => x.dao.role)
    const { chain } = useNetwork()
    const daoName = useSelector((x) => x.contributor.daoName)

    return (
        <div
            align="middle"
            justify="center"
            style={{
                width: "100%",
            }}
        >
            <div className={styles.headerCnt}>
                {contributorWallet ? (
                    <div className={styles.header}>
                        <div className={styles.headerName}>Rep3</div>
                        {chainType(chain?.id) === "Testnet" &&
                            role !== "ADMIN" && (
                                <div>
                                    <div
                                        style={{
                                            color: "#FFB22E",
                                            marginLeft: "6px",
                                        }}
                                        className={styles.headerName}
                                    >
                                        Testnet
                                    </div>
                                </div>
                            )}
                    </div>
                ) : (
                    <div className={styles.headerName}>
                        rep3 {daoName && <span> | {daoName}</span>}
                    </div>
                )}
                {jwt && showWalletPicker && (
                    <div onClick={() => onWalletCenterOpen(!walletCenter)}>
                        <WalletPicker signer={signer} />
                    </div>
                )}
            </div>
        </div>
    )
}
