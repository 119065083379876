import { web3 } from "../constant/web3"

let managerContract = null
let daoContract = null

export const setManagerContract = (x) => {
    console.log("managerContract is", x)
    managerContract = x
}

export const setDaoContract = async (x, address) => {
    console.log("dao contract is", x)
    daoContract = x
    const txHandle = await daoContract.isApprover(address)

    console.log("p is ", txHandle)
}

const delay = (ms) => new Promise((resolve, _reject) => setTimeout(resolve, ms))

export const deployDaoContract = async (
    daoName,
    daoSymbol,
    approverAddress,
    hashCallbackFn,
    confirmCallbackFn
) => {
    console.log("managerContract", managerContract)
    managerContract.once("ProxyDeployed", (data) => {
        console.log("data is", data)
    })
    const txHandle = await managerContract.deployREP3TokenProxy(
        daoName,
        daoSymbol,
        approverAddress,
        web3.zkSync.upgradableBeacon,
        "0x0000000000000000000000000000000000000000"
    )
    console.log("txHandle is", txHandle)
    hashCallbackFn(txHandle.hash)
    const x = await txHandle.wait()
    console.log("x is", x)
    await delay(3000)
    confirmCallbackFn()
}

const generateData = (levels, categories) => {
    if (levels.length !== categories.length) {
        return []
    }
    const levelCategoryArray = []
    for (let i = 0; i < levels.length; i++) {
        const levelCategory = (levels[i] << 8) | categories[i]
        levelCategoryArray.push(levelCategory)
    }
    return levelCategoryArray
}

const createVoucher = (levels, categories, end, to, tokenUris) => {
    const data = generateData(levels, categories)
    const voucher = { data, end, to, tokenUris }
    return voucher
}

export const createMembershipVoucher = async (
    contractAddress,
    level,
    category,
    end,
    addresses,
    metadataHash,
    chainId,
    signer
) => {
    try {
        const domain = {
            name: "REP3Signer",
            version: "0.0.1",
            verifyingContract: contractAddress, //contract address
            salt: "0x" + chainId.toString(16).padStart(64, "0"), //For mainnet replace 80001 with 137
        }
        // types is the types
        const typesV2 = {
            NFTVoucher: [
                { name: "data", type: "uint256[]" },
                { name: "end", type: "uint8[]" },
                { name: "to", type: "address[]" },
                { name: "tokenUris", type: "string" },
            ],
        }

        const voucher = createVoucher(
            level,
            category,
            end,
            addresses,
            metadataHash
        )
        const signature = await signer._signTypedData(domain, typesV2, voucher)
        return {
            ...voucher,
            signature,
        }
    } catch (error) {
        console.log("error", error)
        throw error
    }
}

export const claimVoucher = async (
    contractAddress,
    voucher,
    claimerAddressIndex,
    dao_uuid,
    hashCallbackFn,
    callbackFn
) => {
    const txHandle = await daoContract.claimMembership(
        voucher,
        claimerAddressIndex
    )
    console.log("txHandle is", txHandle)
    hashCallbackFn(txHandle.hash)
    const x = await txHandle.wait()
    console.log("x is", x)
    callbackFn(x)
}

export const upgradeMembershipNft = async (
    tokenId,
    level,
    category,
    metaDataHash,
    transactionHashCallback,
    callbackFunction
) => {
    const data = generateData([level], [category])[0]
    const txHandle = await daoContract.updateMembership(
        tokenId,
        data,
        metaDataHash
    )
    console.log("txHandle is", txHandle)
    transactionHashCallback(txHandle.hash)
    const x = await txHandle.wait()
    console.log("x is", x)
    callbackFunction(x)
}

export const updateApprovers = async (
    approverAddresses,
    transactionHashCallback,
    callbackFunction,
    currentApprovers
) => {
    try {
        console.log("current approvers are", currentApprovers)
        const approverAddressesInLowercase = approverAddresses.map((ele) =>
            ele.toLowerCase()
        )

        const removedApprovers = currentApprovers.filter(
            (ele) => !approverAddressesInLowercase.includes(ele.toLowerCase())
        )
        const newlyAddedApprovers = approverAddressesInLowercase.filter(
            (ele) => !currentApprovers.includes(ele.toLowerCase())
        )
        console.log(
            "removed approvers and added approvers",
            removedApprovers,
            newlyAddedApprovers
        )

        const txHandle = await daoContract.changeApprover(
            newlyAddedApprovers,
            removedApprovers
        )
        console.log("txHandle is", txHandle)
        transactionHashCallback(txHandle.hash)
        const x = await txHandle.wait()
        console.log("x is", x)
        callbackFunction(x)
    } catch (error) {
        console.log("Catched Error", error)
    }
}
