import "./App.scss"

import * as dayjs from "dayjs"
import * as relativeTimePlugin from "dayjs/plugin/relativeTime"
import * as utc from "dayjs/plugin/utc"
import React, { useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { Route, Routes } from "react-router-dom"
import { useSigner } from "wagmi"

import ErrorBoundary from "./components/ErrorBoundary"
import AddBotFallback from "./pages/AddBotFallback"
import AuthWallet from "./pages/AuthWallet"
import ContributorOnboarding from "./pages/ContributorOnboarding"
import ContributorSignupFallback from "./pages/ContributorSignupFallback"
import Onboarding from "./pages/DaoOnboarding"
import Dashboard from "./pages/Dashboard/index"
import DiscordFallback from "./pages/DiscordFallback"
import MetamaskError from "./pages/MetamaskError"
import { signout } from "./store/actions/auth-action"

function App() {
    dayjs.extend(relativeTimePlugin)
    dayjs.extend(utc)
    const dispatch = useDispatch()

    const [redirected, setRedirected] = useState(false)

    const { data: signer } = useSigner()
    const listenersSet = useRef(null)

    if (signer?.provider?.provider && !listenersSet.current) {
        signer.provider?.provider?.on("accountsChanged", () => {
            dispatch(signout())
            if (window.location.pathname.includes("/contributor/invite")) {
                window.location.reload()
            } else {
                window.location.replace(window.location.origin)
            }
        })
        signer.provider?.provider?.on("chainChanged", () => {
            dispatch(signout())
            if (window.location.pathname.includes("/contributor/invite")) {
                window.location.reload()
            } else {
                window.location.replace(window.location.origin)
            }
        })
        signer.provider?.provider?.on("disconnect", () => {
            dispatch(signout())
            if (window.location.pathname.includes("/contributor/invite")) {
                window.location.reload()
            } else {
                window.location.replace(window.location.origin)
            }
        })
        listenersSet.current = true
    }

    if (window.location.hostname === "pony.rep3.gg" && !redirected) {
        if (window.location.pathname) {
            window.open(
                `https://app.rep3.gg${window.location.pathname}`,
                "_self"
            )
            setRedirected(true)
        }
    }

    return (
        <ErrorBoundary>
            <div className="App">
                <div className="App-header">
                    <Routes>
                        <Route path="/" element={<AuthWallet />} />
                        <Route
                            path="/discord/fallback"
                            element={<DiscordFallback />}
                        />
                        <Route path="/onboard/dao" element={<Onboarding />} />
                        <Route
                            path="onboard/contributor/:id"
                            element={<ContributorOnboarding />}
                        />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route
                            path="contributor/invite/:name/:id"
                            element={<ContributorSignupFallback />}
                        />
                        <Route
                            path="/discord/add-bot-fallback"
                            element={<AddBotFallback />}
                        />
                        <Route
                            path="/metamask-error"
                            element={<MetamaskError />}
                        />
                    </Routes>
                </div>
            </div>
        </ErrorBoundary>
    )
}

export default App
