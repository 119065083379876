import "./style.scss"

import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Select from "react-select"

import { assets } from "../../../../constant/assets"

export default function MembershipRoleSelection({
    item,
    isLast,
    roles,
    updateWithRole,
    membership,
}) {
    // styles for react-select
    const [selectedRole, setSelectedRole] = useState({
        label: item?.discord_role_name,
        value: item?.discord_role_id,
    })

    const editTrack = useSelector((x) => x.membership.setEditDiscordSetupModal)

    const colourStyles = {
        control: (styles, { data }) => {
            console.log("data", data)
            return {
                ...styles,
                backgroundColor: selectedRole ? "#E0DCFE" : "white",
                width: "100%",
                height: 48,
                margin: 0,
                padding: 0,
                paddingTop: 0,
                border: selectedRole ? "0px" : "1px solid #6852FF",
            }
        },
        option: (styles) => {
            return {
                ...styles,
                fontFamily: "TelegrafMedium",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "1rem",
                lineHeight: "1rem",
            }
        },
        indicatorSeparator: (styles) => ({
            ...styles,
            height: 0,
            width: 0,
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            color: "black",
            marginRight: "1rem",
            marginBottom: "20px",
        }),

        input: (styles, { isFocused }) => {
            return {
                ...styles,
                fontFamily: "TelegrafMedium",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "1rem",
                lineHeight: "1.5rem",
                height: "100%",
                outline: isFocused ? "none" : "none",
                border: 0,
            }
        },
        placeholder: (styles) => ({
            ...styles,
            fontFamily: "TelegrafMedium",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "1rem",
            lineHeight: "1.5rem",
            position: "absolute",
            top: 12,
            bottom: 12,
            left: 16,
            // right: 16,
        }),
        singleValue: (styles) => ({
            ...styles,
            fontFamily: "TelegrafMedium",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "1rem",
            lineHeight: "1.5rem",
            width: "100%",
            position: "absolute",
            top: 12,
            bottom: 12,
            left: 16,
        }),
    }

    const [toggle, setToggle] = useState(
        editTrack ? item?.discord_role_name : false
    )

    function getDifference(array1, array2) {
        console.log(
            "difference",
            array1,
            array2,
            array1.filter((object1) => {
                return !array2.some((object2) => {
                    return object1.value === object2.value
                })
            })
        )
        return array1.filter((object1) => {
            return !array2.some((object2) => {
                return object1.value === object2.value
            })
        })
    }

    const [roleOption, setRoleOption] = useState([])

    const buildOption = () => {
        const selectedOption = []
        membership.forEach((memberships) => {
            selectedOption.push({
                label: memberships.discord_role_name,
                value: memberships.discord_role_id,
            })
        })
        console.log("role option", selectedOption, roles)
        setRoleOption(getDifference(roles, selectedOption))
    }
    console.log("role option", roleOption)
    useEffect(() => {
        console.log("build option fired", membership, roleOption)
        buildOption()
    }, [membership, updateWithRole])

    const renderSelect = () => (
        <div style={{ padding: toggle && "0px 0px 1.5rem 1.25rem" }}>
            <Select
                className="basic-single"
                classNamePrefix="select"
                closeMenuOnSelect
                onChange={(x) => {
                    updateWithRole(x.label, x.value)
                    setSelectedRole({ label: x.label, value: x.value })
                }}
                styles={colourStyles}
                isSearchable={false}
                name="color"
                options={roleOption}
                placeholder="Select Discord Role"
                value={selectedRole}
                menuPosition="fixed"
            />
        </div>
    )

    return (
        <div
            style={{
                borderBottom: isLast && 0,
            }}
            className="membership-roles-selection-container"
        >
            <div className="item-wrapper">
                <div className="membership-info">
                    {toggle ? (
                        <img
                            onClick={() => {
                                updateWithRole(null, null)
                                setToggle(!toggle)
                            }}
                            src={assets.icons.checkBox}
                        />
                    ) : (
                        <div
                            onClick={() => {
                                updateWithRole(null, null)
                                setToggle(!toggle)
                            }}
                            className={"unselected-toggle"}
                        />
                    )}
                    <div className="membership-name">{item?.name}</div>
                </div>
                {item?.is_video ? (
                    <video autoPlay loop muted>
                        <source src={item?.image_url} />
                    </video>
                ) : (
                    <img src={item?.image_url} />
                )}
            </div>
            {toggle && renderSelect()}
        </div>
    )
}
