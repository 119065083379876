import React, { useState } from "react"

import add_white from "../../../assets/Icons/add_white.svg"
import feedback_grey from "../../../assets/Icons/feedback_grey.svg"
import minus_white from "../../../assets/Icons/minus_white.svg"
import payment_grey from "../../../assets/Icons/payment_grey.svg"

const CustomBadgeDropDown = ({ i, x, selectedMemberCustom }) => {
    const [showCustomBadge, setCustomBadge] = useState(false)
    const typeSpecificCustombadge = selectedMemberCustom.map(
        (item) => item.badge_collection.id === x.id
    )

    return (
        <div
            key={i}
            className={`contribution-wrapper ${
                showCustomBadge && "contribution-shown"
            }`}
        >
            <div className="contribution-overview">
                <div className="overview-left">
                    <div className="total-contri">
                        {typeSpecificCustombadge?.length || 0} {x.type}
                    </div>
                </div>
                <div
                    className="overview-right"
                    onClick={() => setCustomBadge(!showCustomBadge)}
                >
                    <img
                        src={showCustomBadge ? minus_white : add_white}
                        alt=""
                    />
                </div>
            </div>
            <div className="contributions-detailed">
                {selectedMemberCustom?.slice(0, 3)?.map((contri, index) => (
                    <div className="contribution-row" key={index}>
                        <div className="contri-row-left">
                            <div className="contri-title">
                                {
                                    contri?.details?.find(
                                        (x) =>
                                            x.fieldName === "Contribution Title"
                                    )?.value
                                }{" "}
                            </div>
                            <div className="contri-time">
                                {contri.badge_type.name}
                            </div>
                        </div>
                        <div className="contri-row-right">
                            {contri?.feedback && (
                                <img src={feedback_grey} alt="" />
                            )}
                            {contri?.tokens?.length ? (
                                <img src={payment_grey} alt="" />
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CustomBadgeDropDown
