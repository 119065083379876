import React, { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router"

import {
    discordBotSetup,
    discordMembershipSetup,
    discordOauth,
    setDiscordServer,
    signout,
} from "../store/actions/auth-action"
import { getDiscordSevers } from "../store/actions/contibutor-action"
import {
    getDiscordRoleOnGuild,
    setDiscordAutoClaimSetupModal,
    setDiscordRole,
    setDiscordServers,
} from "../store/actions/membership-action"

const DiscordFallback = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const search = useLocation().search
    const code = new URLSearchParams(search).get("code")
    // const discordFallback = useSelector((x) => x.auth.discordEnable)
    const onDiscordMembershipSetup = useSelector(
        (x) => x.auth.discordMembershipSetup
    )
    const current_uuid = useSelector((x) => x.auth.dao_uuid)
    const onDiscordBotSetup = useSelector((x) => x.auth.discordBotSetup)
    const onContributorDiscordSetup = useSelector(
        (x) => x.auth.contributorDiscordSetup
    )
    // const lastSelection = useSelector((x) => x.auth.lastSelection)
    const fallbackCheck = useCallback(async () => {
        try {
            console.log("last selection", current_uuid)

            if (onDiscordMembershipSetup) {
                const servers = await dispatch(
                    getDiscordSevers(
                        code,
                        `${window.location.origin}/discord/fallback`,
                        current_uuid
                    )
                )
                if (servers.length) {
                    dispatch(setDiscordServer(servers))
                    dispatch(setDiscordAutoClaimSetupModal(true))
                    dispatch(discordOauth(false))
                    dispatch(discordMembershipSetup(false, false))
                    dispatch(discordBotSetup(false))
                    dispatch(setDiscordServers(servers))
                    navigate(`/dashboard`)
                }
            } else if (onDiscordBotSetup) {
                const guildId = new URLSearchParams(search).get("guild_id")
                dispatch(getDiscordRoleOnGuild(guildId))
                dispatch(setDiscordRole(guildId))
                dispatch(setDiscordAutoClaimSetupModal(true))
                navigate(`/dashboard`)
            } else if (onContributorDiscordSetup) {
                navigate(`/onboard/contributor/${null}`, {
                    state: {
                        discordUserId: "userId",
                        onboardingStep: 2,
                        discordAccessCode: code,
                    },
                })
            } else {
                dispatch(signout())
                navigate(`/`)
            }
        } catch (error) {}
    }, [code, dispatch, navigate])

    useEffect(() => {
        fallbackCheck()
    }, [fallbackCheck])

    return <div />
}

export default DiscordFallback
