/* eslint-disable unused-imports/no-unused-vars */
import "./style.scss"

import pluralize from "pluralize"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// import contributionIconWhite from "../../../../assets/Icons/contributionIconWhite.svg"
import edit_active from "../../../../assets/Icons/edit_active.svg"
import membershipIconWhite from "../../../../assets/Icons/membershipIconWhite.svg"
// import participationIconWhite from "../../../../assets/Icons/participationIconWhite.svg"
// import { assets } from "../../../../constant/assets"
// import {
//     actionOnContributionRequestModal,
//     actionOnGenerateSchemaModal,
// } from "../../../../store/actions/contibutor-action"
// import {
//     setSelectedCustomBadgeForMint,
//     setShowCustomBadgeCreateModal,
//     setShowCustomBadgeMintModal,
// } from "../../../../store/actions/custom-badge-action"
import { setContractAddress } from "../../../../store/actions/dao-action"
import {
    setShowMembershipCreateModal,
    setShowMembershipMintingModal,
} from "../../../../store/actions/membership-action"
// import iconType from "../../../../utils/customBadgeIconTypes"
// import { getBadgeOnContractAddress } from "../../../../utils/POCPServiceSdk"
import AutoClaimBanner from "../../../AutoclaimBanner"

export default function HomeScreen({
    membershipBadges,
    setShowMembershipOverviewModal,
    setIsEditingCustomBadge,
}) {
    // const [claimedBadges, setClaimedBadges] = useState([])
    const currentDao = useSelector((x) => x.dao.currentDao)
    const dispatch = useDispatch()
    const showModal = () => {
        dispatch(setShowMembershipCreateModal(true))
    }
    // const proxyContract = useSelector((x) => x.dao.daoProxyAddress)

    const showMintingModal = async () => {
        await dispatch(setContractAddress(currentDao?.proxy_txn_hash))
        dispatch(setShowMembershipMintingModal(true))
    }
    // const contributionSchema = useSelector(
    //     (x) => x.contributor.contributorSchema
    // )
    // const allContributionCount = useSelector(
    //     (x) => x.contributor.approvedRequest
    // )

    // useEffect(async () => {
    //     const badges = await getBadgeOnContractAddress(
    //         proxyContract,
    //         currentDao?.uuid
    //     )
    //     setClaimedBadges(badges?.data?.associationBadges)
    // }, [currentDao?.uuid])

    // const displaySchemas = (schema) => {
    //     const contribFeild = []
    //     schema?.forEach((x, i) => {
    //         if (i < 3) {
    //             contribFeild.push(x.fieldName)
    //         }
    //     })
    //     contribFeild.toString()
    //     return contribFeild.toString().replaceAll(",", " / ")
    // }
    // const [discordEdit, setDiscordEdit] = useState(false)
    const discordSetup = useSelector((x) => x.membership.discordSetup)
    // const customBadges = useSelector((x) => x.customBadge.customBadges)
    // const showCustomBadgeCreateModal = () => {
    //     dispatch(setShowCustomBadgeCreateModal(true))
    // }

    // const showCustomBadgeMintModal = (badge) => {
    //     dispatch(setSelectedCustomBadgeForMint(badge))
    //     dispatch(setShowCustomBadgeMintModal(true))
    // }

    // const editCustomBadge = (badge) => {
    //     dispatch(setSelectedCustomBadgeForMint(badge))
    //     setIsEditingCustomBadge(true)
    //     dispatch(setShowCustomBadgeCreateModal(true))
    // }
    const [collapsableOpen, setCollapsableOpen] = useState(false)
    console.log("current", currentDao)
    return (
        <div className="badges-home-screen-container">
            <div className="membership-badge-wrapper">
                {membershipBadges?.length ? (
                    <div
                        style={{
                            background: collapsableOpen ? "#333333" : "#27281d",
                            borderRadius: "1rem",
                        }}
                    >
                        <div className="membership-badge">
                            <div className="membership-badge-left">
                                <div className="membership-badge-icon">
                                    <img src={membershipIconWhite} alt="" />
                                </div>
                                <div>
                                    <div className="membership-badge-heading">
                                        Membership Badge
                                    </div>
                                    {membershipBadges
                                        .slice(0, 2)
                                        .map((badge, index) => (
                                            <div
                                                key={index}
                                                className="badge-and-holder-row"
                                            >
                                                <div className="badge-name">
                                                    {badge.name}
                                                </div>
                                                <div className="badge-holders">
                                                    {badge.members_count}{" "}
                                                    {pluralize(
                                                        "holder",
                                                        badge.members_count
                                                    )}{" "}
                                                    |{" "}
                                                    {badge.unclaimed_members_count ||
                                                        0}{" "}
                                                    unclaimed
                                                </div>
                                            </div>
                                        ))}
                                    {membershipBadges?.length > 2 && (
                                        <div>
                                            {membershipBadges?.length - 2} more
                                        </div>
                                    )}
                                    <div className="membership-badge-buttons">
                                        <button onClick={showMintingModal}>
                                            Mint Badges
                                        </button>
                                        <div
                                            onClick={() =>
                                                setShowMembershipOverviewModal(
                                                    true
                                                )
                                            }
                                        >
                                            <img src={edit_active} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="membership-badge-right">
                                {currentDao?.uuid ===
                                    "93ba937e02ea4fdb9633c2cb27345200" ||
                                currentDao?.uuid ===
                                    "981349a995c140d8b7fb5c110b0d133b" ? (
                                    <video autoPlay loop muted>
                                        <source
                                            src={
                                                membershipBadges?.[0]?.image_url
                                            }
                                        />
                                    </video>
                                ) : membershipBadges?.[0]?.is_video ? (
                                    <video
                                        autoPlay
                                        loop
                                        muted
                                        className="claimedBadgeImg"
                                    >
                                        <source
                                            src={
                                                membershipBadges?.[0]?.image_url
                                            }
                                        />
                                    </video>
                                ) : (
                                    <div className="stack-card">
                                        {membershipBadges.length > 2 &&
                                            (!membershipBadges?.[2]
                                                ?.is_video ? (
                                                <img
                                                    className="claimedBadgeImg3"
                                                    src={
                                                        membershipBadges?.[2]
                                                            ?.image_url
                                                    }
                                                />
                                            ) : (
                                                <video
                                                    autoPlay
                                                    loop
                                                    muted
                                                    className="claimedBadgeVd3"
                                                >
                                                    <source
                                                        src={
                                                            membershipBadges?.[2]
                                                                ?.image_url
                                                        }
                                                    />
                                                </video>
                                            ))}
                                        {membershipBadges.length > 1 &&
                                            (!membershipBadges?.[1]
                                                ?.is_video ? (
                                                <img
                                                    className="claimedBadgeImg2"
                                                    src={
                                                        membershipBadges?.[1]
                                                            ?.image_url
                                                    }
                                                />
                                            ) : (
                                                <video
                                                    autoPlay
                                                    loop
                                                    muted
                                                    className="claimedBadgeVd2"
                                                >
                                                    <source
                                                        src={
                                                            membershipBadges?.[1]
                                                                ?.image_url
                                                        }
                                                    />
                                                </video>
                                            ))}
                                        {!membershipBadges[0]?.is_video ? (
                                            <img
                                                className="claimedBadgeImg"
                                                src={
                                                    membershipBadges?.[0]
                                                        ?.image_url
                                                }
                                            />
                                        ) : (
                                            <video
                                                autoPlay
                                                loop
                                                muted
                                                className="claimedBadgeVd"
                                            >
                                                <source
                                                    src={
                                                        membershipBadges?.[0]
                                                            ?.image_url
                                                    }
                                                />
                                            </video>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <AutoClaimBanner
                            setOpen={(x) => setCollapsableOpen(x)}
                            isEnabled={discordSetup}
                        />
                    </div>
                ) : (
                    <div className="membership-empty">
                        <div className="membership-badge-icon">
                            <img src={membershipIconWhite} alt="" />
                        </div>
                        <div className="membership-badge-content">
                            <div>Setup</div>
                            <div>Membership Badge</div>
                            <button onClick={showModal}>Setup Badges</button>
                        </div>
                    </div>
                )}
            </div>
            {/* <div className="rest-badges">
                <div
                    style={{
                        background: contributionSchema && "#1e1f1e",
                        border: contributionSchema && 0,
                    }}
                    className="contribution-div"
                >
                    <div className="contribution-div-row">
                        <div className="badge-row-left">
                            <img src={contributionIconWhite} alt="" />
                            <div className="contribution-title-div">
                                <span>Contribution Badge </span>
                                {contributionSchema?.length > 0 && (
                                    <div className="contribution-badge-stats">
                                        {allContributionCount.length} Approved |{" "}
                                        {claimedBadges.length} Claimed
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="badge-row-right">
                            {membershipBadges?.length ? (
                                <button
                                    onClick={() => {
                                        if (
                                            contributionSchema?.length === 0 ||
                                            !contributionSchema
                                        ) {
                                            dispatch(
                                                actionOnGenerateSchemaModal(
                                                    true
                                                )
                                            )
                                        } else {
                                            dispatch(
                                                actionOnContributionRequestModal(
                                                    true
                                                )
                                            )
                                        }
                                    }}
                                    style={{
                                        background:
                                            contributionSchema?.length > 0 &&
                                            "#6852FF",
                                    }}
                                    className="btn-steps"
                                >
                                    <div>
                                        {contributionSchema?.length > 0
                                            ? "Mint Badges"
                                            : "Enable Badges"}
                                    </div>
                                    <img
                                        src={assets.icons.chevronRightWhite}
                                        alt=""
                                    />
                                </button>
                            ) : (
                                <span>Setup membership badge to enable it</span>
                            )}
                        </div>
                    </div>
                    {contributionSchema?.length > 0 && (
                        <div className="contribution-bottom-div">
                            <div className="psuedo-space" />
                            <div className="contribution-schema-div">
                                <div className="schema-title">
                                    {displaySchemas(contributionSchema)}{" "}
                                    {contributionSchema?.length > 3 &&
                                        `and ${contributionSchema?.length - 3}
                                    more`}
                                </div>
                                <div
                                    onClick={() =>
                                        dispatch(
                                            actionOnGenerateSchemaModal(true)
                                        )
                                    }
                                    className="schema-edit"
                                >
                                    Edit Schema
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {customBadges?.map((badge, index) => (
                    <div
                        className="rest-badge-row custom-badge-row"
                        key={index}
                    >
                        <div className="badge-row-left custom-badge-img">
                            <img src={iconType[badge.icon_type]} alt="" />
                            <div className="custom-badge-title-div">
                                <span>{badge?.type} </span>
                                <div
                                    className="custom-badge-edit-schema"
                                    onClick={() => editCustomBadge(badge)}
                                >
                                    Edit Schema
                                </div>
                            </div>
                        </div>
                        <div className="badge-row-right">
                            <button
                                className="btn-steps"
                                onClick={() => showCustomBadgeMintModal(badge)}
                            >
                                <div>Mint Badges</div>
                                <img
                                    src={assets.icons.chevronRightWhite}
                                    alt=""
                                />
                            </button>
                        </div>
                    </div>
                ))}
                <div className="rest-badge-row">
                    <div className="badge-row-left">
                        <img src={participationIconWhite} alt="" />
                        <span>Create new badge type </span>
                    </div>
                    <div className="badge-row-right">
                        {membershipBadges?.length ? (
                            <button
                                className="btn-steps"
                                onClick={showCustomBadgeCreateModal}
                            >
                                <div>Create Badges</div>
                                <img
                                    src={assets.icons.chevronRightWhite}
                                    alt=""
                                />
                            </button>
                        ) : (
                            <span>Setup membership badge to enable it</span>
                        )}
                    </div>
                </div>
            </div> */}
        </div>
    )
}
