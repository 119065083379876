import "./style.scss"

import { LoadingOutlined } from "@ant-design/icons"
import { message, Spin } from "antd"
import pluralize from "pluralize"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { v4 as uuidv4 } from "uuid"
import { useNetwork } from "wagmi"

import CheckSvg from "../../assets/Icons/check.svg"
import copySvg from "../../assets/Icons/copy_black.svg"
import CrossSvg from "../../assets/Icons/cross.svg"
import PlusSvg from "../../assets/Icons/plusBlack.svg"
import { assets } from "../../constant/assets"
import {
    fetchApprovers,
    getAllDaowithAddress,
    // toggleBot,
    updateApproversForDao,
    updateDaoInfo,
    updateUserInfo,
} from "../../store/actions/dao-action"
// import { chainType } from "../../utils/chainType"
import InputText from "../Input"

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 24,
        }}
        spin
    />
)

export default function SettingsScreen({ closeSettings }) {
    const currentDao = useSelector((x) => x.dao.currentDao)
    // const currentChainId = getSelectedChainId().chainId
    const address = useSelector((x) => x.auth.address)
    const [showDaoNameEdit, setShowDaoNameEdit] = useState(false)
    const [showUserNameEdit, setShowUserNameEdit] = useState(false)
    const [daoName, setDaoName] = useState("")
    const [userName, setUserName] = useState("")
    const [activeSetting, setActiveSetting] = useState("dao")
    const [updatingDaoName, setUpdatingDaoName] = useState(false)
    const [updatingUserName, setUpdatingUserName] = useState(false)
    // const [botStatus, setBotStatus] = useState(currentDao?.discord_bot_active)
    // const [botStatusLoading, setBotStatusLoading] = useState(false)
    const settingsRef = useRef(null)
    const daoNameInputRef = useRef(null)
    const userNameInputRef = useRef(null)
    const { chain } = useNetwork()
    const [showApproverLoading, setShowApproverLoading] = useState(false)

    const dispatch = useDispatch()
    const [showApproverEditModal, setShowApproverEditModal] = useState(false)
    const [approvers, setApprovers] = useState([])
    const [editingApprovers, setEditingApprovers] = useState([])
    const [disableBtn, setDisableBtn] = useState(false)

    // const currentUser = currentDao?.signers?.filter(
    //     (x) => x.public_address === address
    // )

    const currentUser = useSelector((x) => x.dao.username)

    // useEffect(() => {
    //     setBotStatus(currentDao?.discord_bot_active)
    // }, [currentDao?.discord_bot_active])

    const handleScroll = () => {
        if (settingsRef.current.scrollTop > 200) {
            setActiveSetting(() => "discord")
        } else {
            setActiveSetting(() => "dao")
        }
    }

    const copyText = async (textToCopy) => {
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(textToCopy)
            message.success("Copied Successfully")
        } else {
            return document.execCommand("copy", true, textToCopy)
        }
    }
    const openTreasuryOnEtherscan = () => {
        // const link = currentDao?.safe_public_address
        //     ? `https://${
        //           chainType(chain?.id) === "Testnet" ? "rinkeby." : ""
        //       }etherscan.io/address/${currentDao?.safe_public_address}`
        //     : `https://${
        //           chainType(chain?.id) === "Testnet" ? "mumbai." : ""
        //       }polygonscan.com/address/${proxyContract}`

        const link = `https://zksync2-testnet.zkscan.io/address/${proxyContract}`
        window.open(link)
    }

    // const onChange = async (checked) => {
    //     setBotStatus(checked)
    //     setBotStatusLoading(true)
    //     const success = await dispatch(toggleBot())
    //     if (!success) {
    //         setBotStatus(!checked)
    //         message.error("Something went wrong please try again later")
    //     }
    //     setBotStatusLoading(false)
    // }

    // const discordCommands = [
    //     {
    //         name: "/add-contribution",
    //         description: "Add a contribution request",
    //     },
    //     {
    //         name: "/register",
    //         description: "Register a new DAO",
    //     },
    //     {
    //         name: "/verify",
    //         description: "Verify your discord ID and map it to DAO",
    //     },
    // ]
    const proxyContract = useSelector((x) => x.dao.daoProxyAddress)
    const updateDaoName = async () => {
        setUpdatingDaoName(true)
        await dispatch(
            updateDaoInfo({
                dao_uuid: currentDao?.uuid,
                dao_name: daoName,
            })
        )
        setShowDaoNameEdit(false)
        setUpdatingDaoName(false)
    }

    const updateUserName = async () => {
        setUpdatingUserName(true)
        // TODO: dispatch API call to update DAO Name
        await dispatch(updateUserInfo(userName, currentDao?.uuid))
        // const provider = new ethers.providers.Web3Provider(window.ethereum)
        // const signer = await provider.getSigner()
        // const chainId = await signer.getChainId()
        const chainId = chain?.id

        dispatch(getAllDaowithAddress(chainId))
        setShowUserNameEdit(false)
        setUpdatingUserName(false)
    }

    // const openDiscordBot = () => {
    //     localStorage.setItem("discord_bot_dao_uuid", currentDao.uuid)
    //     window.open(links.discord_add_bot, "_self")
    // }

    // const copyCommandsList = () => {
    //     const commands = discordCommands.map((command) => {
    //         return command.name + "\n" + command.description
    //     })
    //     copyText(commands.join("\n\n"))
    // }

    const handleDaoNameEdit = () => {
        setDaoName(currentDao?.name)
        setShowDaoNameEdit(true)
    }

    const handleUserNameEdit = () => {
        setUserName(currentUser)
        setShowUserNameEdit(true)
    }

    useEffect(() => {
        if (showDaoNameEdit) {
            daoNameInputRef.current.focus()
        }
    }, [showDaoNameEdit])

    useEffect(() => {
        if (showUserNameEdit) {
            userNameInputRef.current.focus()
        }
    }, [showUserNameEdit])

    const editApproverList = () => {
        setEditingApprovers(
            approvers.map((ele) => ({ ...ele, isDisabled: true }))
        )
        setShowApproverEditModal(true)
    }

    const closeEditApproverModal = () => {
        setShowApproverEditModal(false)
    }

    const updateOwner = (e, id, key) => {
        const updatedOwners = editingApprovers.map((owner) => {
            if (owner.id === id) {
                const clonedOwner = { ...owner }
                clonedOwner[key] = e.target.value
                return clonedOwner
            }
            return owner
        })
        setEditingApprovers(updatedOwners)
    }

    const deleteOwner = (id) => {
        const ownersAfterDeleting = editingApprovers.filter(
            (owner) => owner.id !== id
        )
        setEditingApprovers(ownersAfterDeleting)
    }

    const addOwner = () => {
        const newOwner = {
            id: uuidv4(),
            name: "",
            address: "",
        }
        setEditingApprovers([...editingApprovers, newOwner])
    }

    const areValidOwners = () => {
        let isValid = true
        if (!editingApprovers.length) {
            return false
        }
        editingApprovers.forEach((owner) => {
            if (!owner.name) {
                isValid = false
                return
            }
            if (!owner.address) {
                isValid = false
            }
        })
        return isValid
    }

    const onSuccessCallback = () => {
        setShowApproverLoading(false)
        message.success("Approvers updated Successfully")
        setShowApproverEditModal(false)
        setApprovers([...editingApprovers])
        setDisableBtn(false)
    }

    const updateApprovers = async () => {
        if (!areValidOwners()) return
        setDisableBtn(true)
        setShowApproverLoading(true)
        console.log("Edit payload", editingApprovers, approvers)
        await dispatch(
            updateApproversForDao(
                editingApprovers,
                approvers,
                onSuccessCallback
            )
        )
    }

    const fetchAndSetApprovers = async () => {
        const approvers = await dispatch(fetchApprovers())
        console.log("calcula", approvers)
        const approversMapped = approvers?.data?.map((ele) => ({
            name: ele.name,
            address: ele.public_address,
            id: uuidv4(),
        }))
        setApprovers(approversMapped)
    }

    useEffect(async () => {
        await fetchAndSetApprovers()
    }, [])

    const isSame = () => {
        if (editingApprovers.length !== approvers.length) {
            return false
        }
        let isSame = true
        editingApprovers.forEach((editedApprover) => {
            const sameApprover = approvers?.find(
                (approver) => approver?.address === editedApprover?.address
            )
            if (!sameApprover || sameApprover?.name !== editedApprover?.name) {
                isSame = false
                return isSame
            }
        })
        return isSame
    }

    return (
        <div className="settings-screen-container">
            <div
                className="settings-temp"
                ref={settingsRef}
                onScroll={handleScroll}
            >
                <div className="settings-main-wrapper">
                    <div className="settings-sticky-header">
                        <div className="title">
                            <img
                                src={assets.icons.backArrowBlack}
                                alt=""
                                onClick={closeSettings}
                            />
                            Settings
                        </div>
                        <div className="navbar">
                            <div
                                className={`nav-item ${
                                    activeSetting === "dao" && "active-nav-item"
                                }`}
                            >
                                Basic Details
                            </div>
                            {/* <div
                                className={`nav-item ${
                                    activeSetting === "discord" &&
                                    "active-nav-item"
                                }`}
                            >
                                Community Approvers
                            </div> */}
                        </div>
                    </div>
                    <div className="settings-item">
                        <div className="safe-details">
                            <div className="safe-name-and-avatar-wrapper">
                                <div className="safe-avatar">
                                    {currentDao?.logo_url ? (
                                        <img
                                            src={currentDao?.logo_url}
                                            alt="logo"
                                        />
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                                <div className="safe-name-and-address">
                                    <div className="safe-name">
                                        {currentDao?.name}
                                    </div>
                                    <div className="safe-address">
                                        eth:
                                        {currentDao?.safe_public_address
                                            ? currentDao?.safe_public_address?.slice(
                                                  0,
                                                  5
                                              )
                                            : proxyContract?.slice(0, 5)}
                                        ...
                                        {currentDao?.safe_public_address
                                            ? currentDao?.safe_public_address?.slice(
                                                  -4
                                              )
                                            : proxyContract?.slice(-4)}
                                    </div>
                                    <div className="safe-links">
                                        <div
                                            className="link"
                                            onClick={() =>
                                                copyText(
                                                    currentDao?.safe_public_address
                                                        ? currentDao?.safe_public_address
                                                        : proxyContract
                                                )
                                            }
                                        >
                                            Copy address
                                        </div>
                                        <div className="separator">•</div>
                                        <div
                                            className="link"
                                            onClick={openTreasuryOnEtherscan}
                                        >
                                            View on zksyncscan
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="setting-row border-bottom">
                            <div className="row-heading">
                                What should we call your DAO
                            </div>
                            {showDaoNameEdit ? (
                                <div className="edit-input">
                                    <input
                                        type="text"
                                        value={daoName}
                                        onChange={(e) =>
                                            setDaoName(e.target.value)
                                        }
                                        ref={daoNameInputRef}
                                    />
                                    <div
                                        className="confirm-btn"
                                        onClick={updateDaoName}
                                    >
                                        {updatingDaoName ? (
                                            <Spin indicator={antIcon} />
                                        ) : (
                                            <img src={CheckSvg} alt="tick" />
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="row-content">
                                    <div>{currentDao?.name}</div>
                                    <div
                                        className="action-button"
                                        onClick={handleDaoNameEdit}
                                    >
                                        Edit
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="setting-row border-bottom">
                            <div className="row-heading">Your Name</div>
                            {showUserNameEdit ? (
                                <div className="edit-input">
                                    <input
                                        type="text"
                                        value={userName}
                                        onChange={(e) =>
                                            setUserName(e.target.value)
                                        }
                                        ref={userNameInputRef}
                                    />
                                    <div
                                        className="confirm-btn"
                                        onClick={updateUserName}
                                    >
                                        {updatingUserName ? (
                                            <Spin indicator={antIcon} />
                                        ) : (
                                            <img src={CheckSvg} alt="tick" />
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="row-content">
                                    <div>{currentUser}</div>
                                    <div
                                        className="action-button"
                                        onClick={handleUserNameEdit}
                                    >
                                        Edit
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="setting-row">
                            <div className="row-heading">Your Address</div>
                            <div className="row-content">
                                <div>{address}</div>
                                <div
                                    className="action-button"
                                    onClick={() => copyText(address)}
                                >
                                    Copy
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="settings-item approvers-list-setting">
                        <div className="approvers-list-heading">
                            Approvers list
                        </div>
                        <div className="approvers-list-heading-row">
                            <div className="approvers-count">
                                {approvers?.length}{" "}
                                {pluralize("Approver", approvers?.length)}
                            </div>
                            <div
                                className="approvers-list-edit"
                                onClick={editApproverList}
                            >
                                Edit list
                            </div>
                        </div>
                        <div className="approvers-list-wrapper">
                            {approvers.map((approver) => (
                                <div
                                    className="approver-row"
                                    key={approver?.address}
                                >
                                    <div className="approver-row-left">
                                        {approver.name} &bull;{" "}
                                        {`${approver.address.slice(
                                            0,
                                            6
                                        )}...${approver.address.slice(-4)}`}
                                    </div>
                                    <div className="approver-row-right">
                                        <img src={copySvg} alt="" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {showApproverEditModal && (
                        <div
                            className="approver-edit-modal-backdrop"
                            onClick={closeEditApproverModal}
                        >
                            <div
                                className="approver-edit-modal"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <img
                                    src={assets.icons.crossBlack}
                                    alt=""
                                    onClick={closeEditApproverModal}
                                    className="approver-edit-close"
                                />
                                <div className="edit-approver-heading">
                                    Edit approver list
                                </div>
                                <div className="edit-approvers-wrapper">
                                    {editingApprovers.map((owner) => (
                                        <div
                                            className="ownerRow"
                                            key={owner.id}
                                        >
                                            <div
                                                style={{
                                                    width: "31%",
                                                    border: 0,
                                                }}
                                            >
                                                <InputText
                                                    type="text"
                                                    placeholder={"Person Name"}
                                                    width={"100%"}
                                                    value={owner?.name}
                                                    onChange={(e) =>
                                                        updateOwner(
                                                            e,
                                                            owner.id,
                                                            "name"
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    width: "60%",
                                                    border: 0,
                                                }}
                                            >
                                                <InputText
                                                    width={"100%"}
                                                    type="text"
                                                    placeholder={
                                                        "Wallet Address"
                                                    }
                                                    value={owner?.address}
                                                    disabled={owner.isDisabled}
                                                    style={{
                                                        background:
                                                            owner.address === ""
                                                                ? "white"
                                                                : "#E1DCFF",
                                                        opacity:
                                                            owner?.isDisabled
                                                                ? "0.5"
                                                                : "1",
                                                    }}
                                                    onChange={(e) =>
                                                        updateOwner(
                                                            e,
                                                            owner.id,
                                                            "address"
                                                        )
                                                    }
                                                    className="addressInput"
                                                />
                                            </div>
                                            <div
                                                onClick={() =>
                                                    deleteOwner(owner.id)
                                                }
                                            >
                                                <img
                                                    src={CrossSvg}
                                                    alt="delete"
                                                />
                                            </div>
                                        </div>
                                    ))}{" "}
                                    <div
                                        onClick={addOwner}
                                        className="addOwner"
                                    >
                                        Add People{" "}
                                        <img src={PlusSvg} alt="add" />
                                    </div>
                                </div>
                                <div className="sign-and-update-approvers">
                                    <button
                                        disabled={
                                            !areValidOwners() ||
                                            disableBtn ||
                                            isSame()
                                        }
                                        onClick={updateApprovers}
                                    >
                                        Sign and Update List
                                        {showApproverLoading ? (
                                            <Spin indicator={antIcon} />
                                        ) : (
                                            <img
                                                src={
                                                    assets.icons
                                                        .chevronRightWhite
                                                }
                                            />
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
